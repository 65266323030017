import { faMessages, faPaperPlaneTop, faWindowMaximize, faWindowMinimize, faX } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayjs from 'dayjs'
import debounce from 'lodash/debounce'
import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'
import React, { useCallback, useEffect, useReducer, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { ChatMessage } from '@vetahealth/tuna-can-api'

import { Button, Form, Input, Popconfirm } from 'antd'

import { Websocket } from '../../lib/api'
import { useLoading } from '../../lib/hooks/useLoading'
import { useChatStore } from '../../stores/chat'
import { usePatientStore } from '../../stores/patient'
import { FormKeys } from '../Forms'
import { Spinner } from '../Spinner'
import { Viewers } from '../Viewers'
import { Message } from './Message'
import { Section } from './styles'

const ChatWindow = styled.div<{ $isMinimized: boolean }>`
  position: fixed;
  bottom: 0;
  right: 20px;
  min-width: 450px;
  width: 30%;
  height: 80%;
  background-color: ${({ theme }) => theme.quinaryBackground};
  z-index: 10;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  overflow: hidden;
  box-shadow:
    0 1px 10px rgba(0, 0, 0, 0.12),
    0 1px 10px rgba(0, 0, 0, 0.24);
  transform: translateY(${({ $isMinimized }) => ($isMinimized ? 'calc(100% - 45px)' : '0')});
  display: flex;
  flex-direction: column;
  transition: 0.15s ease-in-out;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  cursor: pointer;
  height: 45px;
  border-bottom: 1px solid ${({ theme }) => theme.secondaryBackground};
  &:hover {
    background-color: ${({ theme }) => theme.chatHover};
  }
`

const HeaderWrap = styled.div`
  display: flex;
  align-items: center;
`

const PatientName = styled.div`
  font-weight: 600;
  margin-right: 8px;
`

const FetchDataPlaceholder = styled.div`
  position: absolute;
  padding-top: 100px;
`

const ScrollPlaceholder = styled.div<{ $hasNewMessages?: boolean }>`
  ${({ $hasNewMessages }) =>
    $hasNewMessages &&
    `
      &:last-of-type:not(:only-of-type) {
      padding-bottom: 48px;
    }
  }
`}
`

const Conversation = styled.div<{ $hasNewMessages?: boolean }>`
  position: relative;
  flex: 1;
  overflow-y: scroll;
  ${({ $hasNewMessages }) => $hasNewMessages && 'margin-bottom: -36px;'}
`

const MarkAsRead = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 12px;
  background: transparent;
  z-index: 2;
`

const Disclaimer = styled.div`
  position: absolute;
  bottom: 50px;
  left: 50%;
  min-width: 60%;
  max-width: 80%;
  transform: translateX(-50%);
  padding: 8px 20px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.secondaryBackground};
`

const Send = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px;
  border-top: 1px solid ${({ theme }) => theme.secondaryBackground};
`

type AddMessageFormValues = {
  message: string
}

type ChatState = {
  hasMoreData: boolean
  isFirstElementVisible: boolean
  isPopConfirmOpen: boolean
}

const initialState: ChatState = {
  hasMoreData: true,
  isFirstElementVisible: false,
  isPopConfirmOpen: false,
}

export function Chat(): JSX.Element | null {
  const [patient] = usePatientStore((state) => [state.patient])
  const [
    chatMessages,
    currentlyViewedChats,
    isChatMinimized,
    getChatMessages,
    addChatMessage,
    markChatMessagesAsRead,
    setIsChatOpen,
    setIsChatMinimized,
  ] = useChatStore((state) => [
    state.chatMessages,
    state.currentlyViewedChats,
    state.isChatMinimized,
    state.getChatMessages,
    state.addChatMessage,
    state.markChatMessagesAsRead,
    state.setIsChatOpen,
    state.setIsChatMinimized,
  ])
  const { t } = useTranslation()
  const fetchMoreDataRef = useRef<HTMLDivElement>(null)
  const scrollRef = useRef<HTMLDivElement>(null)
  const prevMessages = useRef<ChatMessage[]>([])
  const [form] = Form.useForm()
  const [{ hasMoreData, isFirstElementVisible, isPopConfirmOpen }, set] = useReducer(
    (state: ChatState, update: Partial<ChatState>) => ({ ...state, ...update }),
    initialState,
  )
  const [isLoading, withLoading] = useLoading()

  const patientName = `${patient?.firstName} ${patient?.lastName}`
  const hasNewMessages = Object.values(chatMessages).some(
    (message) => !message.readAt && message.senderId === message.userId,
  )
  const ascendingChatMessages = orderBy(Object.values(chatMessages), ['createdAt'], ['asc'])

  const handleSendChatMessage = useCallback(
    debounce(async (formValues: AddMessageFormValues): Promise<void> => {
      if (!patient) return
      const formMessage = formValues.message.trim()
      if (!formMessage) return
      await addChatMessage(patient.id, formMessage)
      form.resetFields()
    }, 300),
    [],
  )

  async function handleMarkAsRead(): Promise<void> {
    if (patient?.id && !isEmpty(chatMessages)) {
      const unreadMessageIds: number[] = []
      Object.values(chatMessages).forEach((message) => {
        if (message.senderId === patient.id && !message.readAt) unreadMessageIds.push(message.id)
      })
      if (unreadMessageIds.length) await markChatMessagesAsRead(patient.id, unreadMessageIds)
    }
  }

  async function handleSubmit(formValues: AddMessageFormValues): Promise<void> {
    await handleMarkAsRead()
    await handleSendChatMessage(formValues)
  }

  async function fetchChatMessages(offset?: number): Promise<void> {
    if (patient?.id) {
      const messages = await withLoading(getChatMessages(patient.id, offset))
      if (isEmpty(messages)) set({ hasMoreData: false })
    }
  }

  function handleOpenPopConfirmChange(isOpen: boolean): void {
    if (!isOpen) {
      set({ isPopConfirmOpen: false })
      return
    }
    if (form.isFieldTouched(FormKeys.MESSAGE)) {
      set({ isPopConfirmOpen: true })
    } else {
      setIsChatOpen(false)
    }
  }

  function handleBeforeUnload(event: BeforeUnloadEvent): void {
    if (form.getFieldValue(FormKeys.MESSAGE)) {
      event.preventDefault()
      event.returnValue = 'changed'
    }
  }

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0]
      set({ isFirstElementVisible: entry.isIntersecting })
    })

    fetchChatMessages()

    window.addEventListener('beforeunload', handleBeforeUnload)
    if (fetchMoreDataRef?.current) observer.observe(fetchMoreDataRef.current)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
      if (fetchMoreDataRef?.current) observer.unobserve(fetchMoreDataRef.current)
    }
  }, [])

  useEffect(() => {
    if (patient?.id) Websocket.sendPatientChatOpen(patient?.id)
    return () => Websocket.sendPatientChatClose()
  }, [patient])

  useEffect(() => {
    if (prevMessages.current.length) {
      if (prevMessages.current.length < ascendingChatMessages.length) {
        const prevLastMessage = prevMessages.current.pop()
        const ascendingLastMessage = ascendingChatMessages.pop()
        const isNewMessage = dayjs(ascendingLastMessage?.createdAt).isAfter(dayjs(prevLastMessage?.createdAt))
        const isReadByPatient = ascendingLastMessage?.readBy === ascendingLastMessage?.senderId

        if (isNewMessage || isReadByPatient) {
          scrollRef.current?.scrollIntoView({ block: 'end', behavior: 'smooth' })
        }
      }
    } else {
      scrollRef.current?.scrollIntoView({ block: 'end' })
    }
    prevMessages.current = ascendingChatMessages
  }, [ascendingChatMessages])

  useEffect(() => {
    if (isFirstElementVisible && hasMoreData) fetchChatMessages(Object.values(chatMessages).length)
  }, [isFirstElementVisible, hasMoreData, chatMessages])

  return (
    <ChatWindow $isMinimized={isChatMinimized}>
      <Header onClick={() => setIsChatMinimized(!isChatMinimized)}>
        <HeaderWrap>
          <PatientName>
            <FontAwesomeIcon icon={faMessages} style={{ marginRight: '8px' }} />
            {patientName}
          </PatientName>
          <Viewers patientId={patient?.id} viewers={currentlyViewedChats} type="chat" />
        </HeaderWrap>
        <div>
          <Button shape="circle" type="text">
            <FontAwesomeIcon icon={isChatMinimized ? faWindowMaximize : faWindowMinimize} />
          </Button>
          <Popconfirm
            title={t('chat.confirmDiscard')}
            cancelButtonProps={{ type: 'text' }}
            cancelText={t('actions.cancel')}
            okText={t('actions.discard')}
            onConfirm={() => setIsChatOpen(false)}
            onCancel={(e) => e?.stopPropagation()}
            onOpenChange={handleOpenPopConfirmChange}
            open={isPopConfirmOpen}
          >
            <Button shape="circle" type="text" onClick={(e) => e.stopPropagation()}>
              <FontAwesomeIcon icon={faX} />
            </Button>
          </Popconfirm>
        </div>
      </Header>
      <Conversation $hasNewMessages={hasNewMessages}>
        <FetchDataPlaceholder ref={fetchMoreDataRef} />
        {Object.entries(groupBy(ascendingChatMessages, (item) => dayjs(item.createdAt).startOf('day').valueOf())).map(
          ([day, messages]) => (
            <div key={day}>
              <Section day={Number(day)} />
              {messages.map((message) => (
                <Message key={message.id} message={message} patient={patient} />
              ))}
            </div>
          ),
        )}
        {isEmpty(chatMessages) && isLoading && <Spinner />}
        {isEmpty(chatMessages) && !isLoading && (
          <Disclaimer>{t('chat.startConversation', { patient: patientName })}</Disclaimer>
        )}
        <ScrollPlaceholder ref={scrollRef} $hasNewMessages={hasNewMessages} />
      </Conversation>
      {hasNewMessages && (
        <MarkAsRead>
          <Button size="small" type="primary" onClick={handleMarkAsRead}>
            {t('actions.markAsReviewed')}
          </Button>
        </MarkAsRead>
      )}
      <Form onFinish={handleSubmit} validateTrigger="onSubmit" form={form}>
        <Send>
          <Form.Item name={FormKeys.MESSAGE} noStyle>
            <Input.TextArea placeholder="Message" autoSize={{ minRows: 1, maxRows: 6 }} />
          </Form.Item>
          <Button shape="circle" style={{ marginLeft: '8px' }} type="primary" htmlType="submit">
            <FontAwesomeIcon icon={faPaperPlaneTop} />
          </Button>
        </Send>
      </Form>
    </ChatWindow>
  )
}
