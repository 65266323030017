import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MutedEnum } from '@vetahealth/tuna-can-api'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Button, Dropdown } from 'antd'

import { useLoading } from '../../../../lib/hooks/useLoading'
import { usePatientStore } from '../../../../stores/patient'
import { ResultsType, useResultsStore } from '../../../../stores/results'
import { Spinner } from '../../../Spinner'
import { Line } from '../../styles'
import { Actions, Add, ReviewBulk } from './Actions'
import { Cards } from './Cards'
import { Chart } from './Chart'
import { Summary } from './Summary'
import { Table } from './Table'
import { getResultsConfig } from './config'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

function ResultsDetails({
  type,
  patientId,
  isPausedUntilNextMeasurement,
}: { isPausedUntilNextMeasurement: boolean; patientId: string; type: ResultsType }): JSX.Element {
  const { t } = useTranslation()
  const [isLoading, withLoading] = useLoading()
  const [allResults, getAllResults, isChartVisible, toggleChartVisibility, alerts] = useResultsStore((state) => [
    state.allResults,
    state.getAllResults,
    state.isChartVisible,
    state.toggleChartVisibility,
    state.alerts,
  ])
  const config = getResultsConfig(type)
  const hasDropdownActions = !!config.chart // can be extended in the future

  useEffect(() => {
    if (!allResults[type]) {
      withLoading(getAllResults(patientId, type))
    }
  }, [type, alerts])

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <Actions>
        {config.actions?.reviewBulk && <ReviewBulk type={type} />}
        {config.actions?.add && <Add isPausedUntilNextMeasurement={isPausedUntilNextMeasurement} type={type} />}
        {config.actions?.render?.()}
        {hasDropdownActions && (
          <Dropdown
            menu={{
              items: [
                {
                  key: 'chart',
                  label: isChartVisible ? t('results.chart.hide') : t('results.chart.show'),
                  onClick: toggleChartVisibility,
                },
              ],
            }}
            trigger={['click']}
          >
            <Button shape="circle" type="text">
              <FontAwesomeIcon style={{ fontSize: 22 }} icon={faEllipsisVertical} />
            </Button>
          </Dropdown>
        )}
      </Actions>

      {config.chart && isChartVisible && <Chart type={type} />}
      {config.table && <Table type={type} />}
      {config.render?.()}
    </>
  )
}

export function Results(): JSX.Element | null {
  const [patient] = usePatientStore((state) => [state.patient])
  const [type, initialize] = useResultsStore((state) => [state.type, state.initialize])

  useEffect(() => {
    initialize()
  }, [])

  if (!patient) return <div />

  return (
    <div>
      <Cards />
      <Line />
      <Wrapper>
        {type === 'summary' ? (
          <Summary patient={patient} />
        ) : (
          <ResultsDetails
            patientId={patient.id}
            type={type}
            isPausedUntilNextMeasurement={patient.preferences.notifications.muted === MutedEnum.UntilNextMeasurement}
          />
        )}
      </Wrapper>
    </div>
  )
}
