import i18next from 'i18next'
import groupBy from 'lodash/groupBy'
import { immer } from 'zustand/middleware/immer'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

import { isDefined } from '@vetahealth/fishing-gear/lib/typeguards'
import { CardTypeEnum, SummaryIntervalEnum } from '@vetahealth/tuna-can-api'

import { message } from 'antd'

import { isTrackingType } from '../../components/Widgets/helpers'
import { API } from '../../lib/api'
import { usePatientStore } from '../patient'
import { usePatientsStore } from '../patients'
import { useUserStore } from '../user'
import { isAlertUnreviewed, isRiskScoreAlert, isTaskAlert, isTrackingAlert } from './helpers'
import { LastResults, ResultsState } from './types'

type InitialState = Pick<
  ResultsState,
  'summary' | 'lastResults' | 'allResults' | 'alerts' | 'type' | 'summaryInterval' | 'isChartVisible'
>

const initialState: InitialState = {
  summary: [],
  summaryInterval: SummaryIntervalEnum.Day,
  lastResults: {},
  allResults: {},
  alerts: {
    unreviewed: [],
  },
  type: 'summary',
  isChartVisible: true,
}

export const useResultsStore = createWithEqualityFn(
  immer<ResultsState>((set, get) => ({
    ...initialState,

    initialize: () => {
      const { userId, isChartVisible } = useUserStore.getState()

      if (userId) {
        set((prevState) => ({
          ...prevState,
          isChartVisible: isDefined(isChartVisible) ? isChartVisible : prevState.isChartVisible,
        }))
      }
    },

    setType: async (type) => set({ type }),

    getSummaryByInterval: async (id, summaryInterval) => {
      const prevSummaryInterval = get().summaryInterval

      set({ summaryInterval })

      const summary = await API.getPatientResultSummary({ id, summaryInterval })

      if (summary) {
        set({ summary })
        return true
      }

      set({ summaryInterval: prevSummaryInterval })
      return false
    },

    getLastResults: async (id) => {
      const response = await API.getPatientResults(id)

      if (response) {
        const lastResults: LastResults = {}

        response.forEach((result) => {
          lastResults[result.type] = result
        })

        set({ lastResults })
      }
    },

    getAllResults: async (id, type) => {
      if (type === CardTypeEnum.Task) {
        const response = await API.getPatientTasks(id)

        if (response) {
          set((state) => {
            state.allResults.task = response
          })
        }

        return
      }

      if (type === CardTypeEnum.RiskScore) {
        const taskId = get().lastResults.riskScore?.id

        if (!taskId) return

        const response = await API.getPatientRiskScoreTasks({ id, taskId })

        if (response) {
          set((state) => {
            state.allResults.riskScore = response
          })
        }

        return
      }

      const response = await API.getPatientResultDetails({ id, type })

      if (response) {
        set((state) => {
          state.allResults[type] = response
        })
      }
    },

    resetAllResults: () => set({ allResults: initialState.allResults }),

    addTrackingEvent: async (id, event) => {
      const { type, getLastResults, getAllResults, getAlerts } = get()

      if (!isTrackingType(type)) return false

      const success = await API.addTrackingEvent({ id, genericValueEvent: event })

      if (success) {
        message.success(i18next.t('message.valueAdded'))
        usePatientsStore.getState().expirePatients()
        await usePatientStore.getState().getPatient(id)
        await Promise.all([getLastResults(id), getAllResults(id, type), getAlerts(id)])
      }

      return !!success
    },

    deleteTrackingEvent: async (id, eventId) => {
      const { type, getLastResults, getAllResults, getAlerts } = get()

      if (!isTrackingType(type)) return

      const success = await API.deleteTrackingEvent({ id, eventId })

      if (success) {
        usePatientsStore.getState().expirePatients()
        await Promise.all([getLastResults(id), getAllResults(id, type), getAlerts(id)])
      }
    },

    getAlerts: async (id) => {
      const response = await API.getPatientAlerts(id)

      if (response) {
        set({
          alerts: {
            unreviewed: response.filter(isAlertUnreviewed),
            tracking: groupBy(response.filter(isTrackingAlert), 'trackingEventId'),
            riskScore: groupBy(response.filter(isRiskScoreAlert), 'taskResponseId'),
            task: groupBy(response.filter(isTaskAlert), 'taskResponseId'),
          },
        })
      }
    },

    reviewAlerts: async (id, review) => {
      const { getAlerts } = get()

      const success = await API.reviewAlerts(id, review)

      if (success) {
        getAlerts(id)
        usePatientsStore.getState().expirePatients()
        message.success(
          review.reviewType !== 'withdrawn'
            ? i18next.t('message.alertsReviewed', { count: review.ids.length })
            : i18next.t('message.alertsWithdrawn', { count: review.ids.length }),
        )
      }
    },

    toggleChartVisibility: () => {
      const { isChartVisible } = get()
      useUserStore.getState().updateChartVisibility(!isChartVisible)

      set({ isChartVisible: !isChartVisible })
    },

    reset: () =>
      set((prevState) => ({
        ...initialState,
        summaryInterval: prevState.summaryInterval,
        isChartVisible: prevState.isChartVisible,
      })),
  })),
  shallow,
)
