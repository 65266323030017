import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Button, Form, Input, Modal, Select, Typography, message } from 'antd'

import { useLoading } from '../../../lib/hooks/useLoading'
import { useSitesStore } from '../../../stores/sites'
import { FormKeys } from '../../Forms'

const Spacer = styled.div`
  margin-bottom: 20px;
`

const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

interface AddSiteFormValues {
  key: string
  shortName: string
  name: string
  from?: string
}

export function AddSite({
  open,
  onAdd,
  onCancel,
  managedSiteKeys,
}: {
  open: boolean
  onAdd: (siteKey: string) => void
  onCancel: () => void
  managedSiteKeys?: string[]
}): JSX.Element {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [createSite] = useSitesStore((state) => [state.createSite])
  const [isLoading, withLoading] = useLoading()

  const handleAdd = async (values: AddSiteFormValues): Promise<void> => {
    const messageKey = 'siteCreation'
    const success = await withLoading(createSite(values))
    if (success) {
      onAdd(values.key)
      message.success({ content: t('message.siteCreate'), key: messageKey })
    } else {
      message.destroy(messageKey)
    }
    onCancel()
  }

  return (
    <Modal open={open} title={t('widgets.siteManagement.addSite')} footer={null} onCancel={onCancel} destroyOnClose>
      <Typography.Text type="warning">{t('widgets.siteManagement.addWarning')}</Typography.Text>
      <Spacer />
      <Form form={form} layout="vertical" preserve={false} validateTrigger="onSubmit" onFinish={handleAdd}>
        <Form.Item
          name={FormKeys.KEY}
          label={t('form.key')}
          rules={[
            { required: true, whitespace: false, pattern: /^[A-Za-z0-9_-]+$/, message: t('validations.enterKey') },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={FormKeys.SHORT_NAME}
          label={t('form.shortName')}
          rules={[{ required: true, whitespace: true, message: t('validations.enterShorName') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={FormKeys.NAME}
          label={t('form.name')}
          rules={[{ required: true, whitespace: true, message: t('validations.enterName') }]}
        >
          <Input type="textarea" />
        </Form.Item>
        <Form.Item name={FormKeys.FROM} label={t('form.cloneFrom')}>
          <Select
            options={managedSiteKeys?.map((key) => ({ label: key, value: key }))}
            allowClear
            disabled={!managedSiteKeys?.length}
          />
        </Form.Item>
        <ModalButtonWrapper>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {t('actions.save')}
          </Button>
        </ModalButtonWrapper>
      </Form>
    </Modal>
  )
}
