import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Button, Modal, Typography, message } from 'antd'

import { useLoading } from '../../lib/hooks/useLoading'
import { useAuthStore } from '../../stores/auth'
import { usePatientsStore } from '../../stores/patients'
import { ModalProps } from './types'

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  gap: 12px;
`

const PatientSource = styled.div`
  margin-top: 16px;
`

const PatientData = styled.div`
  margin-top: 4px;
`

export function EpicImport({ isVisible, onCancel }: ModalProps): JSX.Element {
  const [patientId, epicPatient, handleEpicImportOrLink] = useAuthStore((state) => [
    state.epicImportData?.patientId,
    state.epicImportData?.patient,
    state.handleEpicImportOrLink,
  ])
  const [patients] = usePatientsStore((state) => [state.patients])
  const { t } = useTranslation()
  const [isLoading, withLoading] = useLoading()

  const isImport = epicPatient && !patientId
  const prosperPatient = isImport ? undefined : patients.find((patient) => patient.id === patientId)

  async function handleConfirm(): Promise<void> {
    const success = await withLoading(handleEpicImportOrLink())
    if (success) {
      void message.success(t('modals.epicImport.success'))
      onCancel()
    }
  }

  return (
    <Modal
      open={isVisible}
      onCancel={onCancel}
      maskClosable={false}
      title={isImport ? t('modals.epicImport.import') : t('modals.epicImport.link')}
      footer={null}
      destroyOnClose
      width={640}
    >
      <div>{isImport ? t('modals.epicImport.importQuestion') : t('modals.epicImport.linkQuestion')}</div>
      {epicPatient && (
        <>
          <PatientSource>
            {!isImport && <Typography.Text>{t('modals.epicImport.epic')}:</Typography.Text>}
          </PatientSource>
          <PatientData>
            <Typography.Text strong>
              {epicPatient.firstName} {epicPatient.lastName} - {t('table.dateOfBirth')}:{' '}
              {dayjs(epicPatient.dateOfBirth).format('L')}
            </Typography.Text>
          </PatientData>
        </>
      )}
      {prosperPatient && (
        <>
          <PatientSource>
            <Typography.Text>{t('modals.epicImport.prosper')}:</Typography.Text>
          </PatientSource>
          <PatientData>
            <Typography.Text strong>
              {prosperPatient.firstName} {prosperPatient.lastName} - {t('table.dateOfBirth')}:{' '}
              {dayjs(prosperPatient.dateOfBirth).format('L')}
            </Typography.Text>
          </PatientData>
        </>
      )}
      <ButtonWrapper>
        <Button onClick={onCancel} disabled={isLoading}>
          {t('modals.epicImport.buttons.cancel')}
        </Button>
        <Button type="primary" onClick={handleConfirm} disabled={isLoading} loading={isLoading}>
          {isImport ? t('modals.epicImport.buttons.import') : t('modals.epicImport.buttons.confirm')}
        </Button>
      </ButtonWrapper>
    </Modal>
  )
}
