import i18next from 'i18next'
import React from 'react'
import styled from 'styled-components'

import { Card as AntCard, Typography } from 'antd'

import { CardProps } from './types'

const Wrapper = styled(AntCard).attrs({ bordered: false })<{
  $sticky?: boolean
  $gridColumn?: string
}>`
  position: relative;
  grid-column: ${({ $gridColumn }) => $gridColumn || 'auto'};
  align-self: start;
  ${({ $sticky }) =>
    $sticky &&
    `
    position: sticky;
    top: 0;
    z-index: 2;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    .ant-card-body {
      padding: 16px 20px !important;
    }
  `}
`
export const Title = styled(Typography.Title).attrs({
  level: 3,
})`
  margin-top: 0;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

export function Card({ children, gridColumn: $gridColumn, action, title, isSticky: $sticky }: CardProps): JSX.Element {
  return (
    <Wrapper {...{ $sticky, $gridColumn }}>
      {title && (
        <Header>
          {title && <Title>{title}</Title>}
          {action}
        </Header>
      )}
      <div>{children}</div>
    </Wrapper>
  )
}

export const SubTitle = styled(Typography.Title).attrs({
  level: 4,
  style: {
    fontSize: 17,
    fontWeight: 600,
  },
})`
  margin-top: 0;
  &:not(:first-child) {
    margin-top: 26px;
  }
`

export const HeadingFive = styled(Typography.Title).attrs({
  level: 5,
  style: {
    fontSize: 15,
    fontWeight: 600,
  },
})`
  margin-top: 0;
`

export const SubmitWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const Section = styled.div`
  padding-bottom: 20px;
  &:last-child {
    padding-bottom: 0;
  }
`

export const Line = styled.hr<{ $noMargin?: boolean }>`
  border: 0;
  height: 0;
  width: 100%;
  ${({ $noMargin }) => !$noMargin && 'margin-bottom: 16px'};
  border-top: 1px solid ${({ theme }) => theme.secondaryBackground};
`

const NoDataWrap = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 500;
`

export const NoData = (): JSX.Element => <NoDataWrap>{i18next.t('empty.noData')}</NoDataWrap>
