import dayjs, { Dayjs } from 'dayjs'
import isEqual from 'lodash/isEqual'
import React, { useCallback, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { ToDo as IToDo } from '@vetahealth/tuna-can-api'

import { Button, Checkbox, Divider, Modal, TimePicker, message } from 'antd'

import { useLoading } from '../../../lib/hooks/useLoading'
import { usePatientStore } from '../../../stores/patient'
import { WizardSection } from '../../Wizard'
import { ToDo } from '../ToDo'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 30px;
`
const Footer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`
const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
`
const ScheduleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
const ToDoWrapper = styled.div`
  margin-bottom: 20px;
`

type ToDoWizardProps = {
  editToDo?: IToDo
  referenceDate?: string
  render: (openToDoWizard: () => void) => React.ReactNode
}

type ToDoWizardState = {
  toDo?: IToDo
  isVisible: boolean
}

const initialState: ToDoWizardState = {
  toDo: undefined,
  isVisible: false,
}

export function ToDoWizard({ editToDo, referenceDate, render }: ToDoWizardProps): JSX.Element {
  const { t } = useTranslation()
  const [isLoading, withLoading] = useLoading()
  const [updateToDo] = usePatientStore((state) => [state.updateToDo])
  const [{ isVisible, toDo }, set] = useReducer(
    (state: ToDoWizardState, update: Partial<ToDoWizardState>) => ({ ...state, ...update }),
    initialState,
  )

  function handleClose(): void {
    set({ isVisible: false, toDo: undefined })
  }

  function handleOpen(): void {
    set({
      isVisible: true,
      toDo: editToDo,
    })
  }

  function handleToDoUpdate(update: Partial<IToDo>): void {
    if (toDo) {
      set({ toDo: { ...toDo, ...update } })
    }
  }

  const handleSubmit = useCallback(async () => {
    if (toDo) {
      const success = await withLoading(updateToDo(toDo.id, toDo))

      if (success) {
        handleClose()
        message.success(t('message.taskUpdated'))
      }
    }
  }, [toDo])

  return (
    <>
      {render(handleOpen)}
      <Modal
        title={t('toDos.wizard.update')}
        open={isVisible}
        footer={null}
        style={{ maxWidth: '800px' }}
        width="66%"
        onCancel={handleClose}
      >
        {toDo && (
          <>
            <ToDoWrapper>
              <ToDo toDo={toDo} referenceDate={referenceDate} />
            </ToDoWrapper>
            <Wrapper>
              <WizardSection
                question={t('toDos.wizard.question.schedule')}
                explanations={[
                  { title: t('toDos.wizard.hourOfDay'), description: t('toDos.wizard.explanations.hourOfDay') },
                  {
                    title: t('toDos.wizard.isMuted'),
                    description: t('toDos.wizard.explanations.isMuted'),
                  },
                ]}
              >
                <ScheduleWrapper>
                  <TimePicker
                    inputReadOnly
                    format={t('dateFormats.timeOnlyHour')}
                    showNow={false}
                    allowClear={false}
                    value={dayjs(toDo.firstDueDate).tz(toDo.timeZone)}
                    onOk={(date: Dayjs) => handleToDoUpdate({ firstDueDate: date.tz(toDo.timeZone).toISOString() })}
                  />
                  <Checkbox
                    checked={!toDo.isMuted}
                    onChange={({ target }) => handleToDoUpdate({ isMuted: !target.checked })}
                  >
                    {t('toDos.wizard.isMuted')}
                  </Checkbox>
                </ScheduleWrapper>
              </WizardSection>
            </Wrapper>
          </>
        )}

        <Divider />

        <Footer>
          <ButtonWrapper>
            <Button onClick={handleClose}>{t('actions.cancel')}</Button>
            <Button
              disabled={isLoading || !toDo || isEqual(toDo, editToDo)}
              loading={isLoading}
              onClick={handleSubmit}
              type="primary"
            >
              {t('actions.save')}
            </Button>
          </ButtonWrapper>
        </Footer>
      </Modal>
    </>
  )
}
