import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { ChatMessage, PatientDetails, PatientOverviewItem } from '@vetahealth/tuna-can-api'

import { useChatStore } from '../../stores/chat'
import { useSitesStore } from '../../stores/sites'
import { useUserStore } from '../../stores/user'
import { PulsatingDot } from '../PulsatingDot'
import { getViewersTitle } from '../helpers'
import { getAvatarProps } from './helpers'

const Wrap = styled.section<{ $isNew: boolean }>`
  position: relative;
  padding: 12px 20px 12px 40px;
  &:last-of-type {
    border-bottom: none;
  }
  ${({ theme, $isNew }) =>
    $isNew &&
    `
      background-color: ${theme.badgeBackground};
      border-bottom: 1px solid ${theme.quinaryBackground};
  `}
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const NameWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Name = styled.div`
  position: relative;
  font-weight: 600;
`

const Avatar = styled.div<{ $color: string }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 14px;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  background-color: ${({ $color }) => $color};
`

const Time = styled.div`
  font-size: 12px;
  margin-left: 12px;
  opacity: 0.5;
`

const Content = styled.div<{ $isOverview?: boolean }>`
  overflow: hidden;
  ${({ $isOverview }) => $isOverview && 'white-space: nowrap;'}
`

const Text = styled.p<{ $isOverview?: boolean }>`
  margin: 0;
  padding: 0;
  ${({ $isOverview }) =>
    $isOverview &&
    `
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`

const Read = styled.span`
  font-size: 12px;
  opacity: 0.5;
`

const ViewersWrap = styled.div`
  display: flex;
  padding-top: 4px;
`

const ViewNames = styled.span`
  margin-left: 8px;
  font-size: 12px;
  line-height: 1.4;
  color: ${({ theme }) => theme.primary};
`

function Viewers({ patientId }: { patientId?: string }): JSX.Element | null {
  const [currentlyViewedChats] = useChatStore((state) => [state.currentlyViewedChats])
  const [users] = useSitesStore((state) => [state.users])
  const theme = useTheme()

  if (!patientId || !currentlyViewedChats[patientId]?.length) return null
  const title = getViewersTitle(currentlyViewedChats, patientId, users, 'notifications')

  return (
    <ViewersWrap>
      <PulsatingDot color={theme.primary} size={16} />
      <ViewNames>{title}</ViewNames>
    </ViewersWrap>
  )
}

export function Message({
  message,
  patient,
  isOverview,
}: {
  message: ChatMessage
  patient?: PatientDetails | PatientOverviewItem
  isOverview?: boolean
}): JSX.Element {
  const { content, senderId, userId: patientId, createdAt, readAt, readBy } = message
  const { t } = useTranslation()
  const [users] = useSitesStore((state) => [state.users])
  const [userId] = useUserStore((state) => [state.userId])
  const theme = useTheme()

  const isCareManager = patientId !== senderId
  const isMe = userId === senderId
  const patientName = `${patient?.firstName} ${patient?.lastName}`
  const { color, background, icon } = getAvatarProps({ isCareManager, isMe, theme })

  const senderName = isCareManager
    ? users.find((user) => user.id === senderId)?.name ?? t('chat.inactiveCaremanager')
    : patientName
  const readName = users.find((user) => user.id === readBy)?.name ?? t('chat.inactiveCaremanager')

  return (
    <Wrap $isNew={!readAt && !isCareManager && !isOverview}>
      <Header>
        <NameWrap>
          <Avatar $color={background}>
            <FontAwesomeIcon icon={icon} size="xs" color={color} />
          </Avatar>
          <Name>{senderName}</Name>
        </NameWrap>
        <Time>{dayjs(createdAt).format(isOverview ? 'lll' : 'LT')}</Time>
      </Header>
      <Content $isOverview={isOverview}>
        <Text $isOverview={isOverview}>{content}</Text>
      </Content>
      {readAt && isCareManager && (
        <Read>{t('chat.readByAt', { patient: patientName, time: dayjs(readAt).format('lll') })}</Read>
      )}
      {readAt && !isCareManager && (
        <>
          <FontAwesomeIcon icon={faCheck} color={theme.tagSuccess} style={{ marginRight: '4px' }} />
          <Read>
            {readName}, {dayjs(readAt).format('lll')}
          </Read>
        </>
      )}
      {!readAt && !isCareManager && isOverview && <Viewers patientId={patient?.id} />}
    </Wrap>
  )
}
