import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { matchPath, useLocation, useMatch, useNavigate } from 'react-router-dom'
import { animated, useSpring } from 'react-spring'
import styled, { useTheme } from 'styled-components'

import { Button, Typography } from 'antd'

import { Notifications } from '../components/Chat'
import { MainMenu, ProfileMenu } from '../components/Menus'
import { PatientSearch } from '../components/PatientSearch'
import { ProsperCare } from '../components/Svg/ProsperCare'
import { VetaHealth } from '../components/Svg/VetaHealth'
import { PopulationViewState } from '../lib/PopulationViewState'
import { usePatients } from '../lib/hooks/usePatients'
import { TrackingEvent, trackEvent } from '../lib/tracking'
import { useSitesStore } from '../stores/sites'
import TopBarPatientNavigation from './TopBarPatientNavigation'
import { routes } from './routes'
import { PatientDetailsTab } from './routes/PatientDetails'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 60px;
  background-color: ${({ theme }) => theme.quinaryBackground};
  box-shadow:
    0 1px 2px rgb(0 0 0 / 5%),
    0 1px 6px -1px rgb(0 0 0 / 3%),
    0 2px 4px rgb(0 0 0 / 3%);
  z-index: 3;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
`
const LogoWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin-left: 20px;
  margin-top: 5px;
  cursor: pointer;
`
const ProsperCareLogo = styled.div`
  height: 25px;
`
const VetaHealthLogo = styled.div`
  height: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
`
const SearchWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  input {
    padding-right: 28px;
  }
  span.ant-select-clear {
    right: 48px;
  }
`

const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  > *:last-child {
    margin-right: 20px;
  }
`

const NavigateBack = styled.div`
  margin-right: 28px;
  cursor: pointer;
`

export function TopBar(): JSX.Element {
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const theme = useTheme()

  const viewState = new PopulationViewState(location.search)

  const [overviewPatients] = usePatients(viewState)

  const { id: currentPatientId, tab } =
    (useMatch(routes.patientDetails.path)?.params as { id: string; tab: PatientDetailsTab }) ?? {}

  const currentPatientIndex = overviewPatients.findIndex((patient) => patient.id === currentPatientId)

  const [sites] = useSitesStore((state) => [state.sites])
  const hasMessaging = sites?.some(({ hasMessaging }) => hasMessaging)

  const canNavigateSelection = overviewPatients.length > 0 && !!currentPatientId && currentPatientIndex > -1
  const [expandSearchInput, setExpandSearchInput] = useState<boolean>(false)

  const searchInputStyles = useSpring({
    from: {
      width: canNavigateSelection ? 220 : 500,
    },
    to: {
      width: expandSearchInput ? 500 : 220,
    },
  })

  const filterNavigationStyles = useSpring({
    from: {
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: 220,
      opacity: 1,
    },
    to: {
      opacity: expandSearchInput ? 0 : 1,
    },
  })

  function handleLogoClick(): void {
    trackEvent(TrackingEvent.logoClicked)

    if (!matchPath(location.pathname, routes.patientList.path)) {
      navigate(routes.patientList())
    }
  }

  function handleNavigateToOverview(): void {
    trackEvent(TrackingEvent.backToPatientListClicked)

    navigate({
      pathname: routes.patientList(),
      search: location.search,
    })
  }

  return (
    <Wrapper>
      <LogoWrapper onClick={handleLogoClick}>
        <ProsperCareLogo>
          <ProsperCare width="100%" height="100%" color={theme.logo} />
        </ProsperCareLogo>
        <VetaHealthLogo>
          <VetaHealth width="100%" height="100%" color={theme.logo} />
        </VetaHealthLogo>
      </LogoWrapper>

      {tab && (
        <animated.div style={filterNavigationStyles}>
          <>
            <NavigateBack onClick={handleNavigateToOverview}>
              <Button type="link" style={{ padding: 0 }}>
                {t('widgets.patient.back')}
              </Button>
            </NavigateBack>
            {canNavigateSelection && (
              <>
                <TopBarPatientNavigation
                  previous
                  currentIndex={currentPatientIndex}
                  ids={overviewPatients.map((patient) => patient.id)}
                  tab={tab}
                  locationSearch={location.search}
                />
                <Typography.Text>
                  {t('router.topBarSelection', { current: currentPatientIndex + 1, total: overviewPatients.length })}
                </Typography.Text>
                <TopBarPatientNavigation
                  currentIndex={currentPatientIndex}
                  ids={overviewPatients.map((patient) => patient.id)}
                  tab={tab}
                  locationSearch={location.search}
                />
              </>
            )}
          </>
        </animated.div>
      )}

      <MenuWrapper>
        <SearchWrapper>
          <animated.div style={searchInputStyles}>
            <PatientSearch onFocus={setExpandSearchInput} />
          </animated.div>
        </SearchWrapper>
        {hasMessaging && <Notifications />}
        <ProfileMenu />
        <MainMenu />
      </MenuWrapper>
    </Wrapper>
  )
}
