import isUndefined from 'lodash/isUndefined'
import omitBy from 'lodash/omitBy'

import { CreateNoteBody, UpdateNoteBody } from '@vetahealth/tuna-can-api'

import { FormKeys, NoteFormValues } from '../../components/Forms'
import { convertEmptyStringToNull } from '../../lib/normalizers'

export function normalizeNoteValues(values: Partial<NoteFormValues>): UpdateNoteBody | CreateNoteBody {
  return convertEmptyStringToNull(
    omitBy(
      {
        ...values,
        [FormKeys.TIMESTAMP]: values.timestamp?.toISOString(),
      },
      isUndefined,
    ),
  )
}
