import { faCheck, faSquare } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { Tooltip } from 'antd'

const Task = styled.div<{ $isCompleted?: boolean }>`
  display: flex;
  cursor: ${({ $isCompleted }) => ($isCompleted ? 'default' : 'pointer')};
`

const Label = styled.div<{ $isCompleted?: boolean }>`
  display: flex;
  align-items: center;
  padding: 2px 8px;
  font-size: 13px;
  color: white;
  border-radius: 4px 0 0 4px;
  background-color: ${({ theme, $isCompleted }) => ($isCompleted ? theme.tagSuccess : theme.careTaskBackgroundPrimary)};
  &:hover {
    background-color: ${({ theme, $isCompleted }) =>
      !$isCompleted && `color-mix(in srgb, ${theme.careTaskBackgroundPrimary} 90%, white)`};
  }
`

const StateWrap = styled.div<{ $isCompleted?: boolean }>`
  display: flex;
  align-items: center;
  padding: 2px 8px;
  border-radius: 0 4px 4px 0;
  background-color: ${({ theme, $isCompleted }) =>
    $isCompleted ? theme.tagSuccess : theme.careTaskBackgroundSecondary};
  &:hover {
    background-color: ${({ theme, $isCompleted }) =>
      !$isCompleted && `color-mix(in srgb, ${theme.careTaskBackgroundSecondary} 80%, white)`};
  }
`

export function CareTask({
  title,
  isCompleted,
  onOpen,
  onComplete,
}: {
  title: string
  isCompleted: boolean
  onOpen?: () => void
  onComplete?: () => void
}): JSX.Element | null {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Task $isCompleted={isCompleted}>
      <Label $isCompleted={isCompleted} onClick={!isCompleted ? onOpen : undefined}>
        {title}
      </Label>
      <Tooltip title={!isCompleted && t('careTask.complete')}>
        <StateWrap $isCompleted={isCompleted} onClick={!isCompleted ? onComplete : undefined}>
          <FontAwesomeIcon
            icon={isCompleted ? faCheck : faSquare}
            color={isCompleted ? theme.textInverted : theme.primary}
          />
        </StateWrap>
      </Tooltip>
    </Task>
  )
}
