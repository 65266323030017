import dayjs from 'dayjs'
import range from 'lodash/range'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'
import { VictoryAxis, VictoryBar, VictoryChart, VictoryGroup, VictoryScatter, VictoryVoronoiContainer } from 'victory'

import { ToolTip } from './ToolTip'
import { getAggregationLabel, getSleepIntervalData, hasUnreviewedAlerts } from './helpers'
import { HoverLine, Wrapper, chartPadding } from './styles'
import { getTheme } from './theme'
import { ChartProps, LabelFormatterParams } from './types'

export function SleepChart({
  data,
  dataKeys,
  intervalDates,
  intervalType,
  alerts,
  timeZone,
  onChangeAggregationLabel,
  getToolTipData,
}: ChartProps): JSX.Element | null {
  const { t } = useTranslation()
  const timeFormat = t('dateFormats.time')
  const theme = useTheme()
  const [width, setWidth] = useState<number | undefined>()
  const ref = useRef<HTMLDivElement>(null)

  const { intervalData, ticks, formatter, aggregationInterval } = useMemo(() => {
    return getSleepIntervalData({ data, dataKeys, intervalDates, intervalType })
  }, [data, dataKeys, intervalDates, intervalType])

  function handleChartWidth(): void {
    if (ref) setWidth(ref.current?.getBoundingClientRect().width)
  }

  useEffect(() => {
    onChangeAggregationLabel(getAggregationLabel(aggregationInterval))
  }, [aggregationInterval])

  useEffect(() => {
    handleChartWidth()
    window.addEventListener('resize', handleChartWidth)
    return () => window.removeEventListener('resize', handleChartWidth)
  }, [])

  return (
    <Wrapper ref={ref}>
      <VictoryChart
        padding={{ ...chartPadding, left: 75 }}
        domainPadding={{ x: 20, y: 10 }}
        width={width}
        theme={getTheme(theme)}
        containerComponent={
          <VictoryVoronoiContainer
            voronoiBlacklist={intervalData[0]?.map((_, index) => `bar-${index}`)}
            voronoiDimension="x"
            labelComponent={
              <ToolTip
                getToolTipData={getToolTipData}
                aggregationInterval={aggregationInterval}
                alerts={alerts}
                chartType="sleep"
              />
            }
            labels={() => ' '} // https://github.com/FormidableLabs/victory/issues/1805#issuecomment-802179487
          />
        }
      >
        <VictoryAxis tickValues={ticks} tickFormat={formatter} />

        <VictoryAxis
          dependentAxis
          tickValues={range(5).map((index) => index * 360)}
          tickFormat={(value) => dayjs().startOf('day').minute(value).tz(timeZone).format(timeFormat)}
        />

        {dataKeys.map(({ color }, dataKeyIndex) => {
          return (intervalData[dataKeyIndex] || []).map((item, index) => {
            return (
              <VictoryGroup key={index} data={[item]}>
                <VictoryBar
                  name={`bar-${index}`}
                  barWidth={8}
                  cornerRadius={{ top: 4, bottom: 4 }}
                  style={{
                    data: {
                      fill: ({ datum: { ids } }: LabelFormatterParams) =>
                        hasUnreviewedAlerts(alerts, ids) ? theme.concerningActive : color,
                    },
                  }}
                />
                <VictoryScatter name={`scatterLine-${index}`} dataComponent={<HoverLine />} />
              </VictoryGroup>
            )
          })
        })}
      </VictoryChart>
    </Wrapper>
  )
}
