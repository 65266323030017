import dayjs from 'dayjs'
import i18next from 'i18next'
import { produce } from 'immer'
import isEmpty from 'lodash/isEmpty'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

import { message } from 'antd'

import { PermissionName } from '@vetahealth/fishing-gear/permissions'
import { API, isAxiosError } from '../../lib/api'
import { omitEqualValues } from '../../lib/normalizers'
import { takeLeading } from '../../lib/takeLeading'
import { useUserStore } from '../user'
import { getManagedSiteKeys } from '../user/helpers'
import { normalizeSiteValues } from './helpers'
import { SitesState } from './types'

const dataExpirationMinutes = 5

const initialState: Pick<SitesState, 'sites' | 'managedSiteKeys' | 'availableCareTasks' | 'siteExpiration' | 'users'> =
  {
    sites: undefined,
    managedSiteKeys: undefined,
    availableCareTasks: undefined,
    siteExpiration: Date.now(),
    users: [],
  }

function expireSites(): number {
  return Date.now() + dayjs.duration({ minutes: dataExpirationMinutes }).asMilliseconds()
}

export const useSitesStore = createWithEqualityFn<SitesState>(
  (set, get) => ({
    ...initialState,
    getSites: takeLeading(async () => {
      let siteKeys: string[] | undefined
      const { siteExpiration } = get()
      const { hasPermission, permissions } = useUserStore.getState()

      if (!permissions || siteExpiration > Date.now()) return

      if (hasPermission(PermissionName.manageSite)) {
        siteKeys = await API.getSiteKeys()
      } else {
        siteKeys = getManagedSiteKeys(permissions)
      }

      const [sites, users, availableCareTasks] = await Promise.all([
        API.getSites(),
        API.getUserNamesWithSites(),
        API.getCareTaskNames(),
      ])

      if (sites || users || availableCareTasks) {
        set({
          sites,
          users,
          availableCareTasks,
          ...(sites && { managedSiteKeys: siteKeys?.sort() }),
          siteExpiration: expireSites(),
        })
      }
    }),
    createSite: async (siteData) => {
      const { sites, managedSiteKeys } = get()
      const site = await API.createSite(siteData)

      if (isAxiosError(site)) {
        if (site.response?.status === 409) {
          message.error({ content: i18next.t('error.keyDuplicate'), key: 'keyDuplicate' })
        }
        return false
      }

      if (site && sites && managedSiteKeys) {
        set({ sites: [...sites, site], managedSiteKeys: [...managedSiteKeys, site.key], siteExpiration: expireSites() })
        return true
      }
      return false
    },
    updateSite: async (siteData) => {
      const { sites } = get()
      const normalizedValues = normalizeSiteValues(siteData)
      const storedSite = sites?.find((site) => site.key === siteData.key)
      const valuesToUpdate = storedSite && omitEqualValues(storedSite, normalizedValues)
      if (isEmpty(valuesToUpdate)) return false

      const [site, users] = await Promise.all([
        API.updateSite({ id: siteData.key, values: valuesToUpdate }),
        API.getUserNamesWithSites(),
      ])

      if (site && sites && users) {
        const siteIndex = sites.findIndex((storedSite) => storedSite.key === site.key)
        const newSites = [...sites]
        newSites[siteIndex] = site
        set({ sites: newSites, users, siteExpiration: expireSites() })
        return true
      }
      return false
    },
    freezeSite: async (siteKey, name, overwriteId) => {
      const updatedSnapshot = await API.freezeSite(siteKey, name, overwriteId)

      if (updatedSnapshot) {
        set(
          produce<SitesState>((state) => {
            if (!state.sites) return
            const site = state.sites.find(({ key }) => key === siteKey)
            if (!site?.snapshots) return
            const snapshotIndex = overwriteId ? site.snapshots.findIndex(({ id }) => id === overwriteId) ?? -1 : -1
            if (snapshotIndex > -1) site.snapshots[snapshotIndex] = updatedSnapshot
            else site.snapshots.unshift(updatedSnapshot)
          }),
        )
        return true
      }
      return false
    },
    unfreezeSite: async (siteKey, snapshotKey) => {
      const success = await API.unfreezeSite(siteKey, snapshotKey)
      if (success) return true
      return false
    },
    deleteSnapshot: async (siteKey, snapshotId) => {
      const success = await API.deleteSnapshot(siteKey, snapshotId)
      if (success) {
        set(
          produce<SitesState>((state) => {
            const site = state.sites?.find((site) => site.key === siteKey)
            if (!site?.snapshots) return
            site.snapshots = site.snapshots.filter(({ id }) => id !== snapshotId)
          }),
        )
        return true
      }
      return false
    },
    createProgramTemplate: async (siteKey, programData) => {
      const { sites } = get()
      const program = await API.createProgramTemplate(siteKey, programData)

      if (isAxiosError(program)) {
        if (program.response?.status === 409) {
          message.error({ content: i18next.t('error.keyDuplicate'), key: 'keyDuplicate' })
        }
        return false
      }

      if (program && sites) {
        const siteIndex = sites.findIndex((site) => site.key === siteKey)
        if (siteIndex === -1) return false
        set(
          produce<SitesState>((state) => {
            if (!state.sites) return
            state.sites[siteIndex].programs.push(program)
          }),
        )
        return true
      }
      return false
    },
    updateSiteProgram: (siteKey, updatedProgram) => {
      const { sites } = get()
      if (!sites) return false
      const siteIndex = sites.findIndex((site) => site.key === siteKey)

      if (siteIndex === -1) return false

      set(
        produce<SitesState>((state) => {
          if (!state.sites) return
          const programIndex = state.sites[siteIndex].programs.findIndex((program) => program.id === updatedProgram.id)
          state.sites[siteIndex].programs[programIndex] = updatedProgram
        }),
      )
      return true
    },
    updateProgramTemplate: async (siteKey, programKey, update) => {
      const { updateSiteProgram } = get()
      const updatedProgram = await API.updateProgramTemplate(siteKey, programKey, update)

      return updatedProgram ? updateSiteProgram(siteKey, updatedProgram) : false
    },
    updateToDoTemplate: async (props) => {
      const { updateSiteProgram } = get()
      const updatedProgram = await API.updateToDoTemplate(props)
      return updatedProgram ? updateSiteProgram(props.siteKey, updatedProgram) : false
    },
    deleteToDoTemplate: async (props) => {
      const { updateSiteProgram } = get()
      const updatedProgram = await API.deleteToDoTemplate(props)
      return updatedProgram ? updateSiteProgram(props.siteKey, updatedProgram) : false
    },
    updateAlertConfigTemplate: async (props) => {
      const { updateSiteProgram } = get()
      const updatedProgram = await API.updateAlertConfigTemplate(props)
      return updatedProgram ? updateSiteProgram(props.siteKey, updatedProgram) : false
    },
    deleteAlertConfigTemplate: async (props) => {
      const { updateSiteProgram } = get()
      const updatedProgram = await API.deleteAlertConfigTemplate(props)
      return updatedProgram ? updateSiteProgram(props.siteKey, updatedProgram) : false
    },
    deleteProgramTemplate: async (siteKey, programKey) => {
      const { sites } = get()
      const success = await API.deleteProgramTemplate(siteKey, programKey)

      if (success && sites) {
        const siteIndex = sites.findIndex((site) => site.key === siteKey)
        if (siteIndex === -1) return false
        set(
          produce<SitesState>((state) => {
            if (!state.sites) return
            const programIndex = state.sites[siteIndex].programs.findIndex((program) => program.id === programKey)
            if (programIndex > -1) state.sites[siteIndex].programs.splice(programIndex, 1)
          }),
        )
        return true
      }
      return false
    },
    reset: () => set({ ...initialState, siteExpiration: Date.now() }),
  }),
  shallow,
)
