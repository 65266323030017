import { PermissionName } from '@vetahealth/fishing-gear/permissions'
import { PatientQuickInfoEnum, ThemeEnum } from '@vetahealth/tuna-can-api'
import { storage } from '../../lib/storage'

export function getManagedSiteKeys(permissions: string[]): string[] {
  return permissions
    .filter((permission) => permission.split(':')[0] === PermissionName.manageSite)
    .map((permission) => permission.split(':')[1])
}

export function getFallbackAppearance(userId?: string) {
  return {
    theme: storage.getAppearance() ?? ThemeEnum.System,
    summary: storage.getSummarySettings(userId),
    chart: storage.getChartVisibility(userId),
    patientQuickInfo: [
      PatientQuickInfoEnum.Id,
      PatientQuickInfoEnum.Site,
      PatientQuickInfoEnum.DateOfBirth,
      PatientQuickInfoEnum.DaysSinceLastOnboarded,
      PatientQuickInfoEnum.Phone,
      PatientQuickInfoEnum.Address,
      PatientQuickInfoEnum.BillingPeriod,
    ],
  }
}
