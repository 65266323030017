import { PatientTask, PatientTaskDetailAnswer } from '@vetahealth/tuna-can-api'
import { Table, Typography } from 'antd'
import last from 'lodash/last'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { usePatientStore } from '../../../../../stores/patient'
import { isAlertUnreviewed, isTaskAlert, useResultsStore } from '../../../../../stores/results'
import { Alert } from '../../../../Alerts'
import { Field, getTableData } from '../../../../helpers'
import { getResultsConfig, isResultsTaskType } from '../config'
import { TaskDetailsRecord, createActions, createTimestampField, useTaskDetailsTable } from './helpers'

const DetailsTableSection = styled.div`
  margin-top: 28px;
  padding-left: 32px;
`
const StyledTable = styled(Table)`
  .highlight-alert {
    > td {
      background-color: ${({ theme }) => theme.tableBackgroundConcerning};
    }
    &:hover > td {
      background-color: ${({ theme }) => theme.tableBackgroundConcerningHover} !important;
    }
  }
`
const QuestionWrapper = styled.div`
  padding: 20px;
  background-color: ${({ theme }) => theme.secondaryBackground};
  border-radius: 10px;
  margin-bottom: 20px;
`

export function TaskTable({ type }: { type: 'riskScore' | 'task' }): JSX.Element {
  const { t } = useTranslation()
  const [selectedRow, setSelectedRow] = useState<number>(-1)
  const [selectedTaskResponseId, setSelectedTaskResponseId] = useState<number | undefined>()
  const [patient] = usePatientStore((state) => [state.patient])
  const [allResults, alerts] = useResultsStore((state) => [state.allResults, state.alerts])

  const data = (isResultsTaskType(type) && allResults[type]) || []
  const config = getResultsConfig(type)

  const tableData = useMemo(() => {
    const taskFields: Field<PatientTask>[] = [
      {
        key: 'title',
        title: t('table.taskTitle'),
      },
      {
        key: 'editor',
        title: t('table.editor'),
      },
      {
        key: 'status',
        title: t('table.status'),
        format: (value) =>
          value === 'answered' ? t('tracking.task.status.complete') : t('tracking.task.status.incomplete'),
      },
    ]
    const riskScoreFields: Field<PatientTask>[] = [
      {
        key: 'title',
        title: t('table.taskTitle'),
      },
      {
        key: 'status',
        title: t('table.status'),
        format: (value) =>
          value === 'answered' ? t('tracking.task.status.complete') : t('tracking.task.status.incomplete'),
      },
      {
        key: 'riskScore',
        title: t('table.riskScore'),
      },
      {
        key: 'responseId',
        title: t('table.alert'),
        format: (value: number) => (
          <div>
            {alerts.riskScore?.[value]?.map((alert) =>
              alert.alertConfig ? <Alert key={alert.id} alertConfig={alert.alertConfig} /> : null,
            )}
          </div>
        ),
      },
    ]

    const fields: Field<PatientTask>[] = [
      createTimestampField<PatientTask>(patient?.timeZone, 'll'),
      ...(type === 'task' ? taskFields : []),
      ...(type === 'riskScore' ? riskScoreFields : []),
    ]

    const actions = createActions<PatientTask>({ ...config, table: { defaultFields: false, actions: ['review'] } })

    return getTableData({ data, fields, actions })
  }, [data, patient?.timeZone])

  const detailsTables = useTaskDetailsTable(data, selectedRow)

  function handleTaskRow(task: PatientTask): React.HTMLAttributes<HTMLElement> {
    const hasAlerts =
      (type === 'riskScore' || type === 'task') && !!alerts?.[type]?.[task.responseId]?.filter(isAlertUnreviewed).length

    return {
      onClick: () => {
        const index = data.findIndex((item) => item.timestamp === task.timestamp)
        setSelectedRow(selectedRow !== index ? index : -1)
        setSelectedTaskResponseId(selectedTaskResponseId !== task.responseId ? task.responseId : undefined)
      },
      className: hasAlerts ? 'highlight-alert' : undefined,
    }
  }

  function handleDetailsRow(answer: PatientTaskDetailAnswer): React.HTMLAttributes<HTMLElement> {
    if (type === 'riskScore' || !selectedTaskResponseId) return {}

    const hasAlerts = alerts.task?.[selectedTaskResponseId]
      ?.filter(isTaskAlert)
      .filter(isAlertUnreviewed)
      .some((alert) => alert.meta.questionId === answer.id)

    return { className: hasAlerts ? 'highlight-alert' : undefined }
  }

  function handleSelectionChange(selectedRowKeys: number[]): void {
    const lastChangedKey = last(selectedRowKeys)
    setSelectedRow(lastChangedKey !== undefined ? lastChangedKey : -1)
  }

  return (
    <>
      <StyledTable<typeof Table<PatientTask>>
        key={`table-${type}`}
        tableLayout="fixed"
        pagination={{ showSizeChanger: true }}
        {...tableData}
        onRow={handleTaskRow}
        rowSelection={{ onChange: handleSelectionChange, selectedRowKeys: selectedRow > -1 ? [selectedRow] : [] }}
      />
      {detailsTables.map(([question, detailsTableData]) => (
        <DetailsTableSection key={question}>
          <QuestionWrapper>
            <Typography.Text strong>{question}</Typography.Text>
          </QuestionWrapper>
          <StyledTable<typeof Table<TaskDetailsRecord>>
            scroll={{ x: true }}
            key="task-details"
            tableLayout="fixed"
            {...detailsTableData}
            onRow={handleDetailsRow}
          />
        </DetailsTableSection>
      ))}
    </>
  )
}
