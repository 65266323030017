import dayjs, { Dayjs } from 'dayjs'
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { GenericValueEvent } from '@vetahealth/tuna-can-api'

import { Button, DatePicker, Form, Input, Modal, Popconfirm } from 'antd'

import { useLoading } from '../../../../../lib/hooks/useLoading'
import { TrackingEvent, trackEvent } from '../../../../../lib/tracking'
import { usePatientStore } from '../../../../../stores/patient'
import { ResultsType, useResultsStore } from '../../../../../stores/results'
import { InlineForm } from '../../../../Forms/styles'
import { getResultsConfig } from '../config'
import { ForwardActionProps } from './types'

const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

type AddProps = ForwardActionProps<
  ResultsType,
  { isPausedUntilNextMeasurement: boolean; onSubmit?: (event: GenericValueEvent) => void }
>

export const Add = forwardRef(function Add(
  { hasButton = true, isPausedUntilNextMeasurement, type, onSubmit }: AddProps,
  ref,
): JSX.Element | null {
  const { t } = useTranslation()
  const [isVisible, setVisibility] = useState(false)
  const [isLoading, withLoading] = useLoading()
  const [form] = Form.useForm()
  const [patient] = usePatientStore((state) => [state.patient])
  const [addTrackingEvent] = useResultsStore((state) => [state.addTrackingEvent])

  const config = getResultsConfig(type)

  useImperativeHandle(ref, () => ({
    open: () => setVisibility(true),
    close: handleClose,
  }))

  if (!patient || !config.actions?.add) return null

  function handleOpen(): void {
    trackEvent(TrackingEvent.resultAddValueClicked)
    setVisibility(true)
  }

  function handleClose(): void {
    setVisibility(false)
    form.resetFields()
  }

  const handleSubmit = async (values: Record<string, string> & { timestamp: Dayjs }): Promise<void> => {
    if (patient && config.unit) {
      const trackingEvent: GenericValueEvent = {
        type,
        unit: config.unit,
        timestamp: dayjs(values.timestamp).tz(patient.timeZone).toISOString(),
        value: Number(values.value),
        additionalValue: values.additionalValue ? Number(values.additionalValue) : undefined,
      }

      const success = await withLoading(addTrackingEvent(patient.id, trackingEvent))

      if (success) {
        handleClose()
        onSubmit?.(trackingEvent)
      }
    }
  }

  return (
    <>
      {hasButton && (
        <Popconfirm
          open={isPausedUntilNextMeasurement ? undefined : false}
          title={t('widgets.results.confirmResumeProgram')}
          onConfirm={handleOpen}
        >
          <Button onClick={isPausedUntilNextMeasurement ? undefined : handleOpen}>{t('actions.add')}</Button>
        </Popconfirm>
      )}
      <Modal
        title={t('widgets.results.addEventTitle', { title: config.title })}
        open={isVisible}
        footer={null}
        onCancel={handleClose}
        destroyOnClose
      >
        <Form
          form={form}
          layout="vertical"
          validateTrigger="onSubmit"
          onFinish={handleSubmit}
          initialValues={{ timestamp: dayjs() }}
        >
          <Form.Item
            name="timestamp"
            label={t('form.date')}
            rules={[
              { required: true, message: t('validations.enterDate') },
              {
                type: 'date',
                validator: (_, value: Dayjs) => (dayjs(value) < dayjs() ? Promise.resolve() : Promise.reject()),
                message: t('validations.noFutureDate'),
              },
            ]}
          >
            <DatePicker style={{ width: '100%' }} format="lll" showTime={{ format: t('dateFormats.time') }} showNow />
          </Form.Item>
          <InlineForm>
            {config.actions.add.map((value) => (
              <Form.Item
                style={{ width: '100%' }}
                key={value.key}
                name={value.key}
                label={value.label}
                rules={value.validators}
                validateFirst
              >
                <Input type="number" />
              </Form.Item>
            ))}
          </InlineForm>
          <ModalButtonWrapper>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              {t('actions.save')}
            </Button>
          </ModalButtonWrapper>
        </Form>
      </Modal>
    </>
  )
})
