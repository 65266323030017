import dayjs from 'dayjs'
import i18next from 'i18next'
import React from 'react'

import { Colors } from '@vetahealth/fishing-gear/colors'
import { defaultUnits, getDisplayValue, sleepShiftHour } from '@vetahealth/fishing-gear/conversions'
import { CardDetails } from '@vetahealth/tuna-can-api'

import { usePatientStore } from '../../../../../stores/patient'
import { SleepChart } from '../Chart/SleepChart'
import { SleepTable } from '../Table'
import { GetResultsConfigParams, ResultsConfig } from './types'

export function getSleepConfig({ userUnits }: GetResultsConfigParams): ResultsConfig {
  const unit = userUnits.sleep || defaultUnits.sleep
  const patient = usePatientStore.getState().patient

  return {
    key: 'sleep',
    title: i18next.t('tracking.sleep.title'),
    category: 'tracking',
    unit,
    format: (event) => (event.value && event.unit ? getDisplayValue(event.value, event.unit, 'sleep', 'h_min') : '–'),
    card: {
      formatTime: (event) => dayjs(event?.timestamp).tz(patient?.timeZone).format(i18next.t('dateFormats.dayAndMonth')),
    },
    chart: {
      component: SleepChart,
      intervals: ['week', 'month', 'all'],
      dataKeys: [{ key: 'value', color: Colors.azure500 }],
      getData: (data: CardDetails[]) =>
        data.map((event) => ({
          ...event,
          timestamp: dayjs(event.timestamp).add(sleepShiftHour, 'hour').toISOString(),
        })),
    },
    render: () => <SleepTable />,
  }
}
