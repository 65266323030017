import { faWarning } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Colors } from '@vetahealth/fishing-gear/colors'
import { PopulationView } from '@vetahealth/tuna-can-api'

import { Button, Form, Input, Modal, Popconfirm } from 'antd'

import { useLoading } from '../../../lib/hooks/useLoading'
import { FormKeys } from '../../Forms'

interface AddViewModalProps {
  view?: PopulationView
  isVisible: boolean
  currentQueryString: string
  onSubmit: (values: PopulationView) => Promise<void>
  onRemove: () => Promise<void>
  onCancel: () => void
}

interface ViewFormValues {
  name: string
  description?: string
  mediumAlert?: number
  highAlert?: number
}

const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  > *:not(:last-child) {
    margin-right: 10px;
  }
`

const GrowWrap = styled.div`
  flex-grow: 1;
`

export function ViewModal({
  isVisible,
  view,
  currentQueryString,
  onSubmit,
  onRemove,
  onCancel,
}: AddViewModalProps): JSX.Element {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [isLoading, withLoading] = useLoading()
  const [isTouched, setTouched] = useState(false)

  const initialValues = view
    ? {
        [FormKeys.NAME]: view.name,
        [FormKeys.DESCRIPTION]: view.description,
        [FormKeys.MEDIUM_ALERT]: view.mediumAlert,
        [FormKeys.HIGH_ALERT]: view.highAlert,
      }
    : {}

  useEffect(() => {
    form.resetFields()
    form.setFieldsValue(initialValues)
    setTouched(!view)
  }, [view, currentQueryString])

  async function handleSubmit(values: ViewFormValues): Promise<void> {
    const editedView: PopulationView = {
      name: values.name,
      description: values.description,
      queryString: currentQueryString,
      mediumAlert: values.mediumAlert,
      highAlert: values.highAlert,
    }
    await withLoading(onSubmit(editedView))
  }

  async function handleRemove(): Promise<void> {
    await withLoading(onRemove())
  }

  return (
    <Modal
      open={isVisible}
      onCancel={onCancel}
      maskClosable={!!view}
      title={view ? t('widgets.population.editView') : t('widgets.population.addViewTitle')}
      footer={null}
      destroyOnClose
      centered
    >
      <Form
        layout="vertical"
        style={{ width: '100%' }}
        form={form}
        validateTrigger="onChange"
        initialValues={initialValues}
        onFinish={handleSubmit}
        scrollToFirstError
        onFieldsChange={() => {
          if (!isTouched) setTouched(true)
        }}
      >
        <Form.Item
          name={FormKeys.NAME}
          label={t('form.name')}
          rules={[{ required: true, message: t('validations.enterViewName') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name={FormKeys.DESCRIPTION} label={t('form.shortDescription')}>
          <Input />
        </Form.Item>
        <ButtonWrap>
          {view && (
            <GrowWrap>
              <Popconfirm
                title={t('widgets.population.confirmDelete')}
                onConfirm={handleRemove}
                okText={t('actions.delete')}
                okButtonProps={{ danger: true }}
                cancelText={t('actions.cancel')}
                icon={<FontAwesomeIcon icon={faWarning} color={Colors.ruby600} />}
              >
                <Button danger>{t('actions.delete')}</Button>
              </Popconfirm>
            </GrowWrap>
          )}
          <Button disabled={isLoading || !isTouched} loading={isLoading} type="primary" htmlType="submit">
            {view ? t('actions.saveChanges') : t('actions.addView')}
          </Button>
        </ButtonWrap>
      </Form>
    </Modal>
  )
}
