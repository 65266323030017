/* eslint-disable max-len */
import i18next from 'i18next'
import omit from 'lodash/omit'
import { DefaultTheme } from 'styled-components'

import {
  RangeType,
  ValidUnits,
  convert,
  getDisplayValue,
  normalizedUnitRanges,
  normalizedUnits,
} from '@vetahealth/fishing-gear/conversions'
import { isDefined } from '@vetahealth/fishing-gear/lib/typeguards'
import {
  AlertConfigTypeEnum,
  RiskAlertConfig,
  TrackingAlertConfig,
  TrackingAlertConfigAggregationEnum,
  TrackingAlertConfigReferenceAggregationEnum,
  TrackingAlertSubjectEnum,
  TrackingTypeEnum,
} from '@vetahealth/tuna-can-api'

import { AlertConfig } from '../../lib/api'
import { useUserStore } from '../../stores/user'
import { ExplanationConfig } from '../Explanation'
import { getTrackingTitle } from '../Widgets/helpers'
import { AlertDefinition, AlertExplanationKey, TrackingAlertWizardConfig } from './types'

const isRiskScoreAlertConfig = (config: AlertConfig): config is RiskAlertConfig => 'datoId' in config && !!config.datoId
export const isTrackingAlertConfig = (config: AlertConfig): config is TrackingAlertConfig =>
  'subject' in config && !!config.subject

export const convertTrackingAlertSubjectToTrackingType = (subject: TrackingAlertSubjectEnum): TrackingTypeEnum => {
  if (subject === 'systolicBloodPressure' || subject === 'diastolicBloodPressure') return TrackingTypeEnum.BloodPressure
  return subject
}

function normalizeReferenceAggregation(
  referenceAggregation: TrackingAlertWizardConfig['referenceAggregation'],
): TrackingAlertConfigReferenceAggregationEnum {
  if (referenceAggregation === 'avgHours') return TrackingAlertConfigReferenceAggregationEnum.Avg
  if (referenceAggregation === 'rangeHours') return TrackingAlertConfigReferenceAggregationEnum.Range
  return referenceAggregation
}

function normalizeAggregation(
  aggregation: TrackingAlertWizardConfig['aggregation'],
): TrackingAlertConfigAggregationEnum {
  if (aggregation === 'avgHours') return TrackingAlertConfigAggregationEnum.Avg
  return aggregation
}

export function toTrackingAlertWizardConfig(
  alertConfig: TrackingAlertConfig & { id?: number },
): TrackingAlertWizardConfig {
  const trackingAlertWizardConfig: TrackingAlertWizardConfig = {
    ...omit(alertConfig, ['id', 'aggregationDays', 'referenceAggregationDays', 'referenceOffsetDays']),
    aggregationTimespan: alertConfig.aggregationDays,
    referenceAggregationTimespan: alertConfig.referenceAggregationDays,
    referenceOffsetTimespan: alertConfig.referenceOffsetDays,
    referenceUnit: 'day',
  }
  if (alertConfig.aggregation === 'avg' && alertConfig.aggregationDays < 1) {
    trackingAlertWizardConfig.aggregation = 'avgHours'
    trackingAlertWizardConfig.aggregationTimespan *= 24
  }
  if (
    (alertConfig.referenceAggregation === 'avg' || alertConfig.referenceAggregation === 'range') &&
    alertConfig.referenceAggregationDays < 1
  ) {
    trackingAlertWizardConfig.referenceAggregation =
      alertConfig.referenceAggregation === 'avg' ? 'avgHours' : 'rangeHours'
    trackingAlertWizardConfig.referenceAggregationTimespan *= 24
  }
  if (alertConfig.referenceOffsetDays < 1) {
    trackingAlertWizardConfig.referenceOffsetTimespan *= 24
    trackingAlertWizardConfig.referenceUnit = 'hour'
  }
  return trackingAlertWizardConfig
}

export function toTrackingAlertConfig(alertConfig: TrackingAlertWizardConfig): TrackingAlertConfig {
  return {
    ...omit(alertConfig, [
      'referenceUnit',
      'aggregationTimespan',
      'referenceAggregationTimespan',
      'referenceOffsetTimespan',
    ]),
    aggregation: normalizeAggregation(alertConfig.aggregation),
    aggregationDays: alertConfig.aggregation.endsWith('Hours')
      ? alertConfig.aggregationTimespan / 24
      : alertConfig.aggregationTimespan,
    referenceAggregation: normalizeReferenceAggregation(alertConfig.referenceAggregation),
    referenceAggregationDays: alertConfig.referenceAggregation.endsWith('Hours')
      ? alertConfig.referenceAggregationTimespan / 24
      : alertConfig.referenceAggregationTimespan,
    referenceOffsetDays:
      alertConfig.referenceUnit === 'hour'
        ? alertConfig.referenceOffsetTimespan / 24
        : alertConfig.referenceOffsetTimespan,
  }
}

function getThresholdWithPreferredUnit(config: TrackingAlertConfig, units?: ValidUnits): string {
  const valueType = convertTrackingAlertSubjectToTrackingType(config.subject)
  const preferredUnit = units?.[valueType]

  return config.relativeThreshold
    ? `${config.threshold} %`
    : getDisplayValue(config.threshold, config.unit, valueType, { targetUnit: preferredUnit })
}

type ConvertedAlert = Omit<AlertConfig, 'value' | 'comparator'> & {
  value: string
  comparator: '>' | '>=' | '<' | '<='
}

export const printableComparator: Record<
  TrackingAlertConfig['comparator'] | RiskAlertConfig['comparator'],
  ConvertedAlert['comparator']
> = {
  gt: '>',
  gte: '>=',
  lt: '<',
  lte: '<=',
}

function convertAlertToMessage(alertConfig: AlertConfig, units?: ValidUnits): string {
  let config: AlertConfig = alertConfig
  /*
    new message format

    >= 400 lbs                                                                    value: none >= / threshold 400 lbs
    7-day-average >= 400 lbs                                                      value: avg(7) >= / threshold 400 lbs
    5-day-average increase by >= 3 lbs in 7 days                                  increase: avg(5) >= avg(5) offset 7 / threshold 3 lbs
    decrease by > 3 lbs compared to 5-day-average from 7 days ago                 decrease: none > avg(5) offset 7 / threshold 3 lbs
    5-day-average change by > 5 % in one day                                      change: avg(5) > avg(5) offset 1 / threshold 5 relative
    change by => 3 lbs within one day                                             change: none >= range(1) offset 0 / threshold 3 lbs
    5-day-average change <= 1 lbs in 14 days                                      change: avg(5) <= avg(5) offset 14 / threshold 1 lbs

    stupid, but possible messages (should we allow these combinations?)
    increase by < 1 % within 2 days                                               increase: none < range(2) offset 0 / threshold 1 relative
    5-day-average change by > 5 lbs within 3 days                                 increase: avg(5) > range(3) offset 0 / threshold 5 lbs
  */

  const isNegatedChange = config.type !== 'value' && (config.comparator === 'lt' || config.comparator === 'lte')
  if (isNegatedChange) {
    config = { ...config, comparator: config.comparator === 'lt' ? 'gte' : 'gt' }
  }

  const params = {
    threshold: isTrackingAlertConfig(config) ? getThresholdWithPreferredUnit(config, units) : config.threshold,
    comparator: printableComparator[config.comparator],
    count:
      'referenceAggregation' in config && config.referenceAggregation === 'range'
        ? config.referenceAggregationDays
        : config.referenceOffsetDays,
    referenceUnit: i18next.t('alerts.units.days'),
    averageSpan: isTrackingAlertConfig(config) ? config.aggregationDays || config.referenceAggregationDays : 0,
    averageUnit: i18next.t('alerts.units.avgDay'),
    context: isNegatedChange ? 'neg' : undefined,
  }

  if (params.averageSpan < 1) {
    params.averageSpan *= 24
    params.averageUnit = i18next.t('alerts.units.avgHour')
  }

  if (params.count === 1) params.referenceUnit = i18next.t('alerts.units.day')

  if (params.count < 1) {
    params.count *= 24
    params.referenceUnit = params.count === 1 ? i18next.t('alerts.units.hour') : i18next.t('alerts.units.hours')
  }

  params.averageSpan = +params.averageSpan.toFixed(2)
  params.count = +params.count.toFixed(2)

  // risk score, simple value and value change
  if (isRiskScoreAlertConfig(config) || (config.aggregation === 'none' && config.referenceAggregation === 'none')) {
    if (config.type === 'value') return i18next.t('alerts.val', params)
    if (config.type === 'change') return i18next.t('alerts.valChange', params)
    if (config.type === 'increase') return i18next.t('alerts.valIncrease', params)
    if (config.type === 'decrease') return i18next.t('alerts.valDecrease', params)

    return i18next.t('alerts.complex', params)
  }

  // value to range
  if (config.aggregation === 'none' && config.referenceAggregation === 'range') {
    if (config.type === 'change') return i18next.t('alerts.valChangeRange', params)
    if (config.type === 'increase') return i18next.t('alerts.valIncreaseRange', params)
    if (config.type === 'decrease') return i18next.t('alerts.valDecreaseRange', params)

    return i18next.t('alerts.complex', params)
  }

  // value to average
  if (config.aggregation === 'none' && config.referenceAggregation === 'avg') {
    if (config.type === 'change') {
      return config.referenceOffsetDays
        ? i18next.t('alerts.valChangeAvg', params)
        : i18next.t('alerts.valChangeAvgCurrent', params)
    }
    if (config.type === 'increase') {
      return config.referenceOffsetDays
        ? i18next.t('alerts.valIncreaseAvg', params)
        : i18next.t('alerts.valIncreaseAvgCurrent', params)
    }
    if (config.type === 'decrease') {
      return config.referenceOffsetDays
        ? i18next.t('alerts.valDecreaseAvg', params)
        : i18next.t('alerts.valDecreaseAvgCurrent', params)
    }

    return i18next.t('alerts.complex', params)
  }

  // average
  if (config.aggregation === 'avg') {
    if (config.type === 'value') return i18next.t('alerts.avgValue', params)

    if (config.referenceAggregation === 'avg') {
      if (config.type === 'change') return i18next.t('alerts.avgChange', params)
      if (config.type === 'increase') return i18next.t('alerts.avgIncrease', params)
      if (config.type === 'decrease') return i18next.t('alerts.avgDecrease', params)
    }
  }

  return i18next.t('alerts.complex', params)
}

function getTrackingAlertPrefix(type: TrackingAlertSubjectEnum): string {
  const translations: Record<TrackingAlertSubjectEnum, string> = {
    [TrackingAlertSubjectEnum.BloodGlucose]: i18next.t('tracking.bloodGlucose.title'),
    [TrackingAlertSubjectEnum.BloodOxygen]: i18next.t('tracking.bloodOxygen.title'),
    [TrackingAlertSubjectEnum.DiastolicBloodPressure]: i18next.t('tracking.bloodPressure.diastolicTitle'),
    [TrackingAlertSubjectEnum.SystolicBloodPressure]: i18next.t('tracking.bloodPressure.systolicTitle'),
    [TrackingAlertSubjectEnum.HbA1c]: i18next.t('tracking.hbA1c.title'),
    [TrackingAlertSubjectEnum.HeartRate]: i18next.t('tracking.heartRate.title'),
    [TrackingAlertSubjectEnum.RespiratoryRate]: i18next.t('tracking.respiratoryRate.title'),
    [TrackingAlertSubjectEnum.BodyTemperature]: i18next.t('tracking.bodyTemperature.title'),
    [TrackingAlertSubjectEnum.Weight]: i18next.t('tracking.weight.title'),
    [TrackingAlertSubjectEnum.Height]: i18next.t('tracking.height.title'),
  }
  return `${translations[type]}: `
}

export function getTrackingSubjectTitle(subject: TrackingAlertSubjectEnum): string {
  const bloodPressureTitle = i18next.t('tracking.bloodPressure.title')

  if (subject === 'diastolicBloodPressure')
    return `${bloodPressureTitle} - ${i18next.t('tracking.bloodPressure.diastolicTitle')}`
  if (subject === 'systolicBloodPressure')
    return `${bloodPressureTitle} - ${i18next.t('tracking.bloodPressure.systolicTitle')}`

  return getTrackingTitle(subject)
}

export function getAlertTypeTitle(type: AlertConfigTypeEnum): string {
  const translations: Record<AlertConfigTypeEnum, string> = {
    value: i18next.t('alerts.type.value'),
    increase: i18next.t('alerts.type.increase'),
    decrease: i18next.t('alerts.type.decrease'),
    change: i18next.t('alerts.type.change'),
  }

  return translations[type]
}

export function getAlertAggregationTitle(aggregation: TrackingAlertWizardConfig['referenceAggregation']): string {
  const translations: Record<TrackingAlertWizardConfig['referenceAggregation'], string> = {
    none: i18next.t('alerts.aggregation.none'),
    avg: i18next.t('alerts.aggregation.avgDays'),
    avgHours: i18next.t('alerts.aggregation.avgHours'),
    range: i18next.t('alerts.aggregation.rangeDays'),
    rangeHours: i18next.t('alerts.aggregation.rangeHours'),
  }

  return translations[aggregation]
}

export function getAlertMessage(alertConfig?: AlertConfig, alertUnit?: Partial<ValidUnits>): string {
  if (!alertConfig) return ''

  const { units } = useUserStore.getState()
  const message = convertAlertToMessage(alertConfig, { ...units, ...alertUnit })

  const hasPrefix =
    (isTrackingAlertConfig(alertConfig) &&
      convertTrackingAlertSubjectToTrackingType(alertConfig.subject) === 'bloodPressure') ||
    true

  const prefix = isTrackingAlertConfig(alertConfig)
    ? getTrackingAlertPrefix(alertConfig.subject)
    : `${i18next.t('tracking.riskScore.title')}`

  return hasPrefix ? `${prefix} ${message}` : message
}

function isTrackingType(category: string): category is TrackingTypeEnum {
  return Object.values<string>(TrackingTypeEnum).includes(category)
}

export function getAlertDefinition(category: string, theme: DefaultTheme): AlertDefinition {
  if (isTrackingType(category)) {
    return {
      color: {
        background: theme.trackingBackground,
        hover: theme.trackingHover,
        active: theme.trackingActive,
      },
      title: getTrackingTitle(category),
    }
  }
  return {
    color: {
      background: theme.taskBackground,
      hover: theme.taskHover,
      active: theme.taskActive,
    },
    title: i18next.t('alerts.taskId', { taskId: category }),
  }
}

export const trackingUnits: Record<TrackingTypeEnum, Array<ValidUnits[keyof ValidUnits]>> = {
  bloodGlucose: ['mmol/l', 'mg/dl'],
  bloodOxygen: ['%'],
  bloodPressure: ['kPa', 'mmHg'],
  hbA1c: ['mmol/mol', '%'],
  heartRate: ['/min'],
  height: ['m', 'cm', 'ft'],
  respiratoryRate: ['/min'],
  bodyTemperature: ['°C', '°F'],
  sleep: ['min', 'h'],
  steps: [''],
  weight: ['kg', 'lbs'],
}

export function getDefaultTrackingAlertConfig(
  subject: TrackingAlertConfig['subject'],
  units: ValidUnits,
): TrackingAlertWizardConfig {
  const trackingType = convertTrackingAlertSubjectToTrackingType(subject)
  const unit = units[trackingType]

  const allowedUnit = trackingUnits[trackingType].includes(unit) ? unit : normalizedUnits[trackingType]

  const alertConfig: TrackingAlertWizardConfig = {
    aggregation: 'none',
    aggregationTimespan: 0,
    referenceAggregation: 'none',
    referenceAggregationTimespan: 0,
    referenceOffsetTimespan: 0,
    referenceUnit: 'day',
    type: 'value',
    comparator: 'gte',
    threshold: 1,
    relativeThreshold: false,
    subject,
    unit: allowedUnit,
  }

  const [, defaultMaxThreshold = 1] = getThresholdRange(alertConfig)

  return { ...alertConfig, threshold: Math.floor(defaultMaxThreshold) }
}

export function getThresholdRange(alertConfig: TrackingAlertWizardConfig): [number?, number?] {
  if (alertConfig.relativeThreshold) return [0, 100]

  const trackingType = convertTrackingAlertSubjectToTrackingType(alertConfig.subject)

  if (trackingType === 'sleep') return [0, 24]
  if (trackingType === 'steps') return [0, undefined]

  const minChange: Record<RangeType, number> = {
    bloodGlucose: 0.1,
    bloodOxygen: 0,
    bloodPressure: 0.1,
    hbA1c: 0.1,
    heartRate: 1,
    height: 0.1,
    respiratoryRate: 1,
    bodyTemperature: 0.1,
    weight: 0.1,
  }

  const [min, max] = normalizedUnitRanges[trackingType]

  return [
    alertConfig.type === 'value'
      ? convert(min, normalizedUnits[trackingType], trackingType, alertConfig.unit)[0]
      : minChange[trackingType],
    convert(max, normalizedUnits[trackingType], trackingType, alertConfig.unit)[0],
  ]
}

function getThreshold(
  alertConfig: TrackingAlertWizardConfig,
  update: Partial<TrackingAlertWizardConfig>,
  trackingType: TrackingTypeEnum,
): number {
  const [minThreshold, maxThreshold] = getThresholdRange({ ...alertConfig, ...update })
  const convertedThreshold = convert(alertConfig.threshold, alertConfig.unit, trackingType, update.unit)[0]

  if (minThreshold && convertedThreshold < minThreshold) return Math.ceil(minThreshold)
  if (maxThreshold && convertedThreshold > maxThreshold) return Math.floor(maxThreshold)

  return convertedThreshold
}

export function getUpdatedAlertConfig({
  alertConfig,
  update,
  units,
}: {
  alertConfig: TrackingAlertWizardConfig
  update: Partial<TrackingAlertWizardConfig>
  units: ValidUnits
}): TrackingAlertWizardConfig {
  const trackingType = convertTrackingAlertSubjectToTrackingType(alertConfig.subject)

  let overwrite: Partial<TrackingAlertWizardConfig> = {}

  if (update.type === 'value') {
    overwrite = {
      referenceAggregation: 'none',
      referenceAggregationTimespan: 0,
      referenceOffsetTimespan: 0,
      relativeThreshold: false,
      unit: units[trackingType],
    }

    overwrite = { ...overwrite, threshold: getThreshold({ ...alertConfig, ...overwrite }, update, trackingType) }
  }

  if (update.type && update.type !== 'value') {
    if (alertConfig.aggregation.startsWith('avg')) {
      overwrite = {
        referenceAggregation: alertConfig.aggregation,
        referenceAggregationTimespan: alertConfig.aggregationTimespan,
      }
    }
    overwrite.referenceOffsetTimespan = alertConfig.referenceOffsetTimespan || 1
  }

  if (update.aggregation === 'none') {
    overwrite = { aggregationTimespan: 0 }
  }

  if (update.aggregation?.startsWith('avg')) {
    if (alertConfig.type === 'value') {
      overwrite = {
        aggregationTimespan: alertConfig.aggregationTimespan || 1,
        referenceAggregation: 'none',
        referenceAggregationTimespan: 0,
        referenceOffsetTimespan: 0,
      }
    } else {
      const days = alertConfig.aggregationTimespan || alertConfig.referenceAggregationTimespan

      overwrite = {
        aggregationTimespan: days || 1,
        referenceAggregation: update.aggregation,
        referenceAggregationTimespan: days || 1,
        referenceOffsetTimespan: alertConfig.referenceOffsetTimespan || 1,
      }
    }
  }

  if (update.aggregationTimespan !== undefined && alertConfig.type !== 'value') {
    overwrite = { referenceAggregationTimespan: update.aggregationTimespan }
  }

  if (update.referenceAggregation === 'none') {
    overwrite = { referenceAggregationTimespan: 0 }
  }

  if (update.referenceAggregation?.startsWith('range')) {
    overwrite = { referenceOffsetTimespan: 0 }
  }

  if (update.referenceAggregation?.startsWith('avg')) {
    overwrite = { referenceAggregationTimespan: alertConfig.referenceAggregationTimespan || 1 }
  }

  if (isDefined(update.unit)) {
    overwrite = { threshold: update.relativeThreshold ? 100 : getThreshold(alertConfig, update, trackingType) }
  }

  return { ...alertConfig, ...update, ...overwrite }
}

export function getAlertConfigExplanations(key: AlertExplanationKey): ExplanationConfig {
  return {
    avg: { title: i18next.t('alerts.wizard.aggregation.avg'), description: i18next.t('alerts.wizard.explanation.avg') },
    none: {
      title: i18next.t('alerts.wizard.aggregation.none'),
      description: i18next.t('alerts.wizard.explanation.none'),
    },
    range: {
      title: i18next.t('alerts.wizard.aggregation.range'),
      description: i18next.t('alerts.wizard.explanation.range'),
    },
    offset: {
      title: i18next.t('alerts.wizard.reference.offset'),
      description: i18next.t('alerts.wizard.explanation.offset'),
    },
    relative: {
      title: i18next.t('alerts.wizard.unit.relative'),
      description: i18next.t('alerts.wizard.explanation.relative'),
    },
    absolute: {
      title: i18next.t('alerts.wizard.unit.absolute'),
      description: i18next.t('alerts.wizard.explanation.absolute'),
    },
  }[key]
}
