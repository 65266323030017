import { faCloudArrowUp, faUser } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayjs from 'dayjs'
import i18next from 'i18next'
import last from 'lodash/last'
import sortBy from 'lodash/sortBy'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Colors } from '@vetahealth/fishing-gear/colors'
import { CardDetails, PatientTask, PatientTaskDetailAnswer } from '@vetahealth/tuna-can-api'

import { Tag, Tooltip } from 'antd'
import { CompareFn } from 'antd/es/table/interface'

import { ResultsAlertType, useResultsStore } from '../../../../../stores/results'
import { Alert } from '../../../../Alerts'
import { Field, TableData, getTableData } from '../../../../helpers'
import { getDeviceName } from '../../Devices/helpers'
import { Delete, Review } from '../Actions'
import { ResultsConfig, TableAction } from '../config'
import { ValueFormatter } from './types'

const formatDevice = (item: CardDetails['deviceType']): JSX.Element => {
  return (
    <Tooltip title={item ? getDeviceName(item) : i18next.t('source.manual')}>
      {item ? <FontAwesomeIcon icon={faCloudArrowUp} /> : <FontAwesomeIcon icon={faUser} />}
    </Tooltip>
  )
}

const AlertWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
`

const formatAlert = (item: CardDetails['id']): JSX.Element | null => {
  const { alerts } = useResultsStore.getState()
  const eventAlerts = alerts.tracking?.[item] || []

  if (eventAlerts.length === 0) return null

  return (
    <AlertWrapper>
      {eventAlerts.map((alert) =>
        alert.alertConfig ? <Alert key={alert.id} alertConfig={alert.alertConfig} /> : null,
      )}
      {eventAlerts.some((alert) => alert.reviewType === 'escalation') && (
        <Tag color={Colors.green500}>{i18next.t('actions.escalated')}</Tag>
      )}
      {eventAlerts.some((alert) => alert.reviewType === 'self') && (
        <Tag color={Colors.green500}>{i18next.t('actions.reviewed')}</Tag>
      )}
    </AlertWrapper>
  )
}

export const getDefaultTableFields = ({
  format,
  timeFormat,
  timeZone,
  title,
  compare,
  additionalFields = [],
}: {
  format: ValueFormatter
  timeFormat?: string
  timeZone?: string
  title: string
  compare?: CompareFn<CardDetails>
  additionalFields?: Field<CardDetails>[]
}): Field<CardDetails>[] => {
  return [
    createTimestampField<CardDetails>(timeZone, timeFormat),
    {
      key: 'value',
      title,
      format,
      compare,
      noSorting: !compare,
    },
    ...additionalFields,
  ]
}

export const createDeviceField: () => Field<CardDetails> = () => ({
  key: 'deviceType',
  title: i18next.t('table.source'),
  width: 100,
  align: 'center',
  format: formatDevice,
})

export const createTimestampField = <T,>(
  timeZone = i18next.t('dateFormats.defaultTimezone'),
  timeFormat = 'lll z',
): Field<T> => ({
  key: 'timestamp',
  title: i18next.t('form.date'),
  format: ((value: string) => dayjs(value).tz(timeZone).format(timeFormat)) as never,
  defaultSortOrder: 'descend',
  sortDirections: ['descend', 'ascend', 'descend'],
})

export const createAlertField: () => Field<CardDetails> = () => ({
  key: 'id',
  title: i18next.t('table.alert'),
  noSorting: true,
  format: formatAlert,
})

const ActionWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export function createActions<R extends CardDetails | PatientTask>(config: ResultsConfig) {
  return function Actions({ record }: { record: R }): JSX.Element | null {
    let type: ResultsAlertType | undefined
    let id: number | undefined

    if ('responseId' in record) {
      id = record.responseId

      if (config.key === 'riskScore') type = 'riskScore'
      if (config.key === 'task') type = 'task'
    }

    if (config.category === 'tracking' && 'id' in record) {
      type = 'tracking'
      id = record.id
    }

    if (!type || !id) return null

    const actionToComponent: Record<TableAction, React.ElementType> = {
      delete: Delete,
      review: Review,
    }

    return (
      <ActionWrapper>
        {config?.table?.actions?.map((action, index) => {
          const Action = actionToComponent[action]

          return Action ? <Action key={index} id={id} type={type} /> : null
        })}
      </ActionWrapper>
    )
  }
}

export type TaskDetailsRecord = PatientTaskDetailAnswer & {
  taskIndex: number
  detailsIndex: number
  answerIndex: number
  previousAnswer?: string | string[]
}

export const useTaskDetailsTable = (
  data: PatientTask[],
  selectedRow: number,
): [string, TableData<TaskDetailsRecord>][] => {
  const { t } = useTranslation()

  return useMemo(() => {
    if (selectedRow < 0 || data[selectedRow]?.status !== 'answered') return []

    const selectedTask = data[selectedRow]
    const previousTask = last(
      sortBy(
        data.filter(
          (task) => task !== selectedTask && task.timestamp < selectedTask.timestamp && task.ref === selectedTask.ref,
        ),
        'timestamp',
      ),
    )

    const fields: Field<TaskDetailsRecord>[] = [
      {
        key: 'label',
        title: t('table.question'),
        noSorting: true,
      },
      {
        key: 'answer',
        title: dayjs(selectedTask.timestamp).format('L'),
        format: (answer: PatientTaskDetailAnswer['answer']) =>
          typeof answer === 'string' ? answer : (answer ?? []).join(', '),
        noSorting: true,
      },
    ]

    if (previousTask) {
      fields.splice(1, 0, {
        key: 'previousAnswer',
        title: dayjs(previousTask.timestamp).format('L'),
        format: (answer: PatientTaskDetailAnswer['answer']) =>
          typeof answer === 'string' ? answer : (answer ?? []).join(', '),
        noSorting: true,
      })
    }

    return selectedTask.details.map(({ question, answers }, detailsIndex) => {
      const mergedAnswers = answers.map((answer, answerIndex) => {
        const record: TaskDetailsRecord = {
          ...answer,
          taskIndex: selectedRow,
          detailsIndex,
          answerIndex,
        }

        if (previousTask) record.previousAnswer = previousTask.details[detailsIndex]?.answers[answerIndex]?.answer

        return record
      })

      return [question, getTableData({ data: mergedAnswers, fields })]
    })
  }, [data, selectedRow])
}
