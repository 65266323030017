import React from 'react'
import { useTranslation } from 'react-i18next'

import { Form, Modal, Typography, message } from 'antd'

import { API } from '../../lib/api'
import { useLoading } from '../../lib/hooks/useLoading'
import { SupportFormValues } from '../Forms'
import { SupportMessage } from '../Forms/SupportMessage'
import { ModalProps } from './types'

export function EmailSupport({ isVisible, onCancel }: ModalProps): JSX.Element {
  const { t } = useTranslation()
  const [isLoading, withLoading] = useLoading()

  const handleSubmit = async (formValues: SupportFormValues): Promise<void> => {
    const formMessage = formValues.message.trim()
    if (!formMessage) return
    const success = await withLoading(API.submitSupportMessage({ message: formMessage }))
    if (success) {
      message.success(t('message.supportRequest'))
      onCancel()
    }
  }

  return (
    <Modal open={isVisible} onCancel={onCancel} title={t('menus.main.helpModal.subTitle')} footer={null} destroyOnClose>
      <Typography.Paragraph>
        {t('menus.main.helpModal.emailText')}{' '}
        <Typography.Link href="mailto:support@myvetahealth.com" data-i18n-ignore>
          support@myvetahealth.com
        </Typography.Link>{' '}
        {t('menus.main.helpModal.supportMessageText')}
      </Typography.Paragraph>
      <Form onFinish={handleSubmit}>
        <SupportMessage loading={isLoading} />
      </Form>
    </Modal>
  )
}
