import isArray from 'lodash/isArray'
import isEqual from 'lodash/isEqual'
import isNull from 'lodash/isNull'
import isPlainObject from 'lodash/isPlainObject'
import isString from 'lodash/isString'
import isUndefined from 'lodash/isUndefined'
import mapValues from 'lodash/mapValues'
import omitBy from 'lodash/omitBy'

export function convertEmptyStringToNull<T extends object>(
  values: T & (T extends unknown[] ? 'no array' : object),
): {
  [key in keyof T]: T[key] extends string ? T[key] | null : T[key]
} {
  return mapValues(values, (value, key) => {
    if (key === 'dateOfBirth' && value === '0000-00-00') return null
    if (isString(value)) return value || null

    if (isPlainObject(value)) return convertEmptyStringToNull(value as object)

    return value
  }) as T
}

export function convertEmptyStringToUndefined<T extends object>(
  values: T & (T extends unknown[] ? 'no array' : object),
): { [key in keyof T]: T[key] extends string ? T[key] | undefined : T[key] } {
  return mapValues(values, (value) => {
    if (isString(value)) return value || undefined

    if (isPlainObject(value)) return convertEmptyStringToUndefined(value as object)

    return value
  }) as T
}

export function omitUndefined<T extends object>(values: T & (T extends unknown[] ? 'no array' : object)): Partial<T> {
  return omitBy(
    mapValues(values, (value) => {
      if (isPlainObject(value)) return omitUndefined(value as Record<string, unknown>)
      return value
    }),
    isUndefined,
  ) as Partial<T>
}

function isAnyObject(item: unknown): item is Record<string, unknown> {
  return isPlainObject(item)
}

// undefined base values are considered equal to null changes
function isConsideredEqual(baseValue: unknown, newValue: unknown): boolean {
  if (baseValue === newValue) return true
  if (isUndefined(baseValue) && isNull(newValue)) return true
  if (isArray(baseValue) && isEqual(baseValue, newValue)) return true
  if (isAnyObject(baseValue) && isAnyObject(newValue)) {
    const allKeys = [...Object.keys(baseValue), ...Object.keys(newValue)].sort()
    return allKeys.every((key) => isConsideredEqual(baseValue[key], newValue[key]))
  }
  return false
}

// Just to make sure the Generic is an object
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function omitEqualValues<Values extends Record<string, any>>(base = {} as Values, change = {}): Partial<Values> {
  return omitBy(change, (value, key) => isConsideredEqual(base[key], value)) as Partial<Values>
}

/* trims all string properties in objects (nested) and arrays */
export function trimDeep<T>(thing: T): T {
  if (typeof thing === 'string') return thing.trim() as T
  if (typeof thing === 'object') {
    if (isPlainObject(thing)) return mapValues(thing, trimDeep) as T
    if (isArray(thing)) return thing.map(trimDeep) as T
  }
  return thing
}
