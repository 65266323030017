import { produce } from 'immer'
import { jwtDecode } from 'jwt-decode'
import omit from 'lodash/omit'
import remove from 'lodash/remove'
import { Socket, io } from 'socket.io-client'
import { v4 as randomUUID } from 'uuid'

import { BaseClaims } from '@vetahealth/fishing-gear/express/requireAuthorization'
import { ChatMessage } from '@vetahealth/tuna-can-api'
import {
  PatientChatClosePayload,
  PatientChatOpenPayload,
  PatientEditEndPayload,
  PatientEditStartPayload,
  WebsocketMessage,
} from '@vetahealth/tuna-can-api/websocket'

import { matchPath } from 'react-router-dom'
import { routes } from '../../Router/routes'
import { useChatStore } from '../../stores/chat'
import { ChatState } from '../../stores/chat/types'
import { usePatientsStore } from '../../stores/patients'
import { PatientsState } from '../../stores/patients/types'
import { storage } from '../storage'

export function getCurrentPatientId(): string | undefined {
  const { pathname } = window.location
  const {
    params: { id: currentPatientId },
  } = (matchPath(routes.patientDetails.path, pathname) as { params: { id: string } }) ?? { params: {} }
  return currentPatientId
}

function removeAllViewersOfClientId(
  viewedPatients: PatientsState['currentlyViewedPatients'] | ChatState['currentlyViewedChats'],
  clientId: string,
): void {
  Object.values(viewedPatients).forEach((viewers) => {
    remove(viewers, ({ clientId: id }) => id === clientId)
  })
}

interface Options {
  isChatOpen?: boolean
}
class WebsocketClient {
  private socket?: Socket
  private userId: string
  private clientId = randomUUID()
  private viewedPatient: string | undefined
  private viewedChat: string | undefined

  public connect = (token: string, currentPatientId?: string, options?: Options): void => {
    const isChatOpen = options?.isChatOpen
    if (this.socket?.connected) return

    const { sub } = jwtDecode<BaseClaims>(token)
    this.userId = sub
    this.socket = io('', { auth: { jwt: token, clientId: this.clientId }, transports: ['websocket'] })

    this.socket
      .on('disconnect', (reason) => {
        if (reason === 'io server disconnect' || reason === 'io client disconnect') {
          this.socket = undefined
        }
      })
      .on('connect', () => {
        this.socket?.emit(WebsocketMessage.RequestPatientEditState)
        this.socket?.emit(WebsocketMessage.RequestPatientChatOpen)
        if (currentPatientId) {
          this.sendPatientEditStart(currentPatientId)
          if (isChatOpen) this.sendPatientChatOpen(currentPatientId)
        }
      })
      .on('connect_error', () => {
        const accessToken = storage.getTokens()?.accessToken

        if (accessToken && this.socket && typeof this.socket.auth === 'object') {
          this.socket.auth.jwt = accessToken
          setTimeout(() => {
            this.socket?.connect()
          }, 5000)
        }
      })
      .on(WebsocketMessage.RequestPatientEditState, (callback: (payload?: PatientEditStartPayload) => void) => {
        if (!this.viewedPatient) return callback()
        callback({ userId: this.userId, clientId: this.clientId, patientId: this.viewedPatient })
      })
      .on(WebsocketMessage.PatientEditStart, (payload: Required<PatientEditStartPayload>) => {
        this.addViewer(payload)
      })
      .on(WebsocketMessage.PatientEditEnd, (payload: Required<PatientEditEndPayload>) => {
        this.removeViewer(payload)
      })
      .on(WebsocketMessage.RequestPatientChatOpen, (callback: (payload?: PatientChatOpenPayload) => void) => {
        if (!this.viewedChat) return callback()
        callback({ userId: this.userId, clientId: this.clientId, patientId: this.viewedChat })
      })
      .on(WebsocketMessage.PatientChatOpen, (payload: Required<PatientChatOpenPayload>) => {
        this.addChatViewer(payload)
      })
      .on(WebsocketMessage.PatientChatClose, (payload: Required<PatientChatClosePayload>) => {
        this.removeChatViewer(payload)
      })
      .on(WebsocketMessage.PatientChatMessage, (payload: Required<ChatMessage>) => {
        this.addChatMessage(payload)
      })
      .on(WebsocketMessage.PatientChatMessagesRead, (payload: Required<ChatMessage[]>) => {
        this.markChatMessagesAsRead(payload)
      })
  }

  private addViewer = (payload: Required<PatientEditStartPayload>): void => {
    if (payload.clientId === this.clientId) return
    usePatientsStore.setState(
      produce<PatientsState>(({ currentlyViewedPatients }) => {
        removeAllViewersOfClientId(currentlyViewedPatients, payload.clientId)

        const viewer = omit(payload, 'patientId')
        if (currentlyViewedPatients[payload.patientId]) {
          currentlyViewedPatients[payload.patientId].push(viewer)
        } else {
          currentlyViewedPatients[payload.patientId] = [viewer]
        }
      }),
    )
  }

  private removeViewer = (payload: Required<PatientEditEndPayload>): void => {
    if (payload.clientId === this.clientId) return
    usePatientsStore.setState(
      produce<PatientsState>(({ currentlyViewedPatients }) => {
        removeAllViewersOfClientId(currentlyViewedPatients, payload.clientId)
      }),
    )
  }

  private addChatViewer = (payload: Required<PatientChatOpenPayload>): void => {
    if (payload.clientId === this.clientId) return
    useChatStore.setState(
      produce<ChatState>(({ currentlyViewedChats }) => {
        removeAllViewersOfClientId(currentlyViewedChats, payload.clientId)

        const viewer = omit(payload, 'patientId')
        if (currentlyViewedChats[payload.patientId]) {
          currentlyViewedChats[payload.patientId].push(viewer)
        } else {
          currentlyViewedChats[payload.patientId] = [viewer]
        }
      }),
    )
  }

  private addChatMessage = (message: Required<ChatMessage>): void => {
    const { isChatOpen } = useChatStore.getState()

    // Add new message to unreadChatMessages if patient message
    if (message.userId === message.senderId) {
      useChatStore.setState(
        produce<ChatState>(({ unreadChatMessages }) => {
          // Only show latest message when multiple messages are available
          const previousMessageFromPatient = unreadChatMessages.find(
            (unreadMessage) => unreadMessage.senderId === message.senderId,
          )
          const index = unreadChatMessages.findIndex((msg) => msg.userId === previousMessageFromPatient?.userId)
          if (index !== -1) unreadChatMessages.splice(index, 1)
          unreadChatMessages.push(message)
        }),
      )
    }

    // Add new message to chatMessages when viewed patient id matches message userId and chat is open
    if (message.userId === this.viewedPatient && isChatOpen) {
      useChatStore.setState(({ chatMessages }) => ({ chatMessages: { ...chatMessages, [message.id]: message } }))
    }
  }

  private markChatMessagesAsRead = (messages: ChatMessage[]): void => {
    const { isChatOpen } = useChatStore.getState()

    // Remove reviewed messages from unreadChatMessages if patient message
    if (messages.length && messages[0].userId === messages[0].senderId) {
      const idsToDelete = messages.map((message) => message.id)
      useChatStore.setState(({ unreadChatMessages }) => ({
        unreadChatMessages: unreadChatMessages.filter((message) => !idsToDelete.includes(message.id)),
      }))
    }

    // Overwrite chatMessages with reviewed messages when viewed patient id matches message userId and chat is open
    if (messages.length && messages[0].userId === this.viewedPatient && isChatOpen) {
      useChatStore.setState(
        produce<ChatState>(({ chatMessages }) => {
          messages.forEach((reviewedMessage) => {
            chatMessages[reviewedMessage.id] = reviewedMessage
          })
        }),
      )
    }
  }

  private removeChatViewer = (payload: Required<PatientChatClosePayload>): void => {
    if (payload.clientId === this.clientId) return
    useChatStore.setState(
      produce<ChatState>(({ currentlyViewedChats }) => {
        removeAllViewersOfClientId(currentlyViewedChats, payload.clientId)
      }),
    )
  }

  public sendPatientEditStart = (patientId: string): void => {
    if (this.viewedPatient === patientId) return
    this.viewedPatient = patientId
    if (!this.socket) return
    const payload: PatientEditStartPayload = { userId: this.userId, clientId: this.clientId, patientId }
    this.socket.emit(WebsocketMessage.PatientEditStart, payload)
  }

  public sendPatientEditEnd = (): void => {
    if (!this.viewedPatient) return
    this.viewedPatient = undefined
    if (!this.socket) return
    const payload: PatientEditEndPayload = { userId: this.userId, clientId: this.clientId }
    this.socket.emit(WebsocketMessage.PatientEditEnd, payload)
  }

  public sendPatientChatOpen = (patientId: string): void => {
    if (this.viewedChat === patientId) return
    this.viewedChat = patientId
    if (!this.socket) return
    const payload: PatientChatOpenPayload = { userId: this.userId, clientId: this.clientId, patientId }
    this.socket.emit(WebsocketMessage.PatientChatOpen, payload)
  }

  public sendPatientChatClose = (): void => {
    if (!this.viewedChat) return
    this.viewedChat = undefined
    if (!this.socket) return
    const payload: PatientChatClosePayload = { userId: this.userId, clientId: this.clientId }
    this.socket.emit(WebsocketMessage.PatientChatClose, payload)
  }

  public disconnect = (): void => {
    if (!this.socket) return
    this.viewedPatient = undefined
    this.viewedChat = undefined
    this.socket.off()
    this.socket.disconnect()
    this.socket = undefined
  }
}

export const Websocket = new WebsocketClient()
