import { faCopy } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import copy from 'copy-to-clipboard'
import React, { useEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Colors } from '@vetahealth/fishing-gear/colors'
import { TwoFactorSecretResponse } from '@vetahealth/tuna-can-api'

import { Button, Input, Modal, QRCode, Space, Typography } from 'antd'

import { useLoading } from '../../../lib/hooks/useLoading'
import { useAuthStore } from '../../../stores/auth'
import { useUserStore } from '../../../stores/user'

const QRCodeWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0;
`
const ManualCodeWrapper = styled.div`
  display: flex;
  justify-content: center;
`
const EnterCodeWrapper = styled.div`
  margin-top: 30px;
  padding: 20px 0;
  border-top: 2px solid ${Colors.mauve200};
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

type EnableTwoFactorState = Partial<TwoFactorSecretResponse> & { code: string }

const initialState: EnableTwoFactorState = { secretBase32Encoded: undefined, code: '' }

export function EnableTwoFactor({ isVisible, onCancel }: { isVisible: boolean; onCancel: () => void }): JSX.Element {
  const { t } = useTranslation()
  const [email] = useUserStore((state) => [state.userId, state.email])
  const [isLoading, withLoading] = useLoading()
  const [handleEnableTwoFactor, getTwoFactorSecret] = useAuthStore((state) => [
    state.handleEnableTwoFactor,
    state.getTwoFactorSecret,
  ])
  const [{ secretBase32Encoded, code }, set] = useReducer(
    (state: EnableTwoFactorState, update: Partial<EnableTwoFactorState>) => ({ ...state, ...update }),
    initialState,
  )

  const issuer = window.location.host
  const qrValue = new URL(`otpauth://totp/${issuer}:${email}?secret=${secretBase32Encoded}&issuer=${issuer}`).toString()

  useEffect(() => {
    if (isVisible) {
      getTwoFactorSecret().then((secretBase32Encoded) => set({ secretBase32Encoded }))
    } else {
      set(initialState)
    }
  }, [isVisible])

  async function handleEnable(): Promise<void> {
    const success = await withLoading(handleEnableTwoFactor({ secretBase32Encoded, code }))
    if (success) onCancel()
  }

  return (
    <Modal
      title={t('widgets.account.twoFactor.enable.title')}
      open={isVisible}
      footer={null}
      onCancel={onCancel}
      maskClosable={false}
      destroyOnClose
    >
      <Typography.Paragraph>{t('widgets.account.twoFactor.enable.generalDescription')}</Typography.Paragraph>
      <QRCodeWrapper>
        <QRCode
          status={email && secretBase32Encoded ? 'active' : 'loading'}
          value={qrValue}
          size={200}
          errorLevel="H"
        />
      </QRCodeWrapper>
      <Typography.Paragraph>{t('widgets.account.twoFactor.enable.manualDescription')}</Typography.Paragraph>
      <ManualCodeWrapper>
        <Space.Compact style={{ width: '100%' }}>
          <Input size="large" value={secretBase32Encoded} style={{ textAlign: 'center' }} />
          <Button onClick={() => copy(secretBase32Encoded ?? '')}>
            <FontAwesomeIcon icon={faCopy} />
          </Button>
        </Space.Compact>
      </ManualCodeWrapper>
      <EnterCodeWrapper>
        <Typography.Paragraph>{t('widgets.account.twoFactor.enable.enterCode')}</Typography.Paragraph>
        <Input
          size="large"
          value={code}
          onChange={(event) => set({ code: event.target.value })}
          style={{ textAlign: 'center', fontWeight: '500' }}
          maxLength={6}
          placeholder={t('placeholders.twoFactor')}
        />
      </EnterCodeWrapper>
      <ButtonWrapper>
        <Button
          disabled={code.length !== 6 || isLoading}
          loading={isLoading}
          type="primary"
          onClick={handleEnable}
          size="large"
        >
          {t('actions.enable')}
        </Button>
      </ButtonWrapper>
    </Modal>
  )
}
