import debounce from 'lodash/debounce'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { DeviceTypeEnum } from '@vetahealth/tuna-can-api'

import { Button, Form, Input, Modal, Select, message } from 'antd'

import { useForm } from 'antd/es/form/Form'
import { routes } from '../../../../Router/routes'
import { PatientDetailsTab } from '../../../../Router/routes/PatientDetails'
import { API, ApiError, AssignIdConflictError } from '../../../../lib/api'
import { convertEmptyStringToUndefined, trimDeep } from '../../../../lib/normalizers'
import { TrackingEvent, trackEvent } from '../../../../lib/tracking'
import { usePatientStore } from '../../../../stores/patient'
import { assignableDevices, getDeviceName, tenoviDevices } from './helpers'

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    margin-left: 12px;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

function isApiError(
  response: boolean | AssignIdConflictError | ApiError | undefined,
): response is AssignIdConflictError | ApiError {
  return !!response && typeof response !== 'boolean' && 'status' in response
}

export function AssignDevice({
  isVisible,
  onCancel,
  onAssignDevice,
}: {
  isVisible: boolean
  onCancel: () => void
  onAssignDevice: (patientId: string) => void
}): JSX.Element | null {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [patient] = usePatientStore((state) => [state.patient])
  const [form] = useForm()
  const selectedDevice = Form.useWatch('deviceType', form)
  const isTenoviDevice = tenoviDevices.includes(selectedDevice)

  const handleAssignDevice = useCallback(
    debounce((values: { id: string; deviceType: DeviceTypeEnum; hubId: string }): void => {
      if (!patient?.id) return

      const { id, hubId } = convertEmptyStringToUndefined(trimDeep(values))

      trackEvent(TrackingEvent.deviceAssignConfirmClicked, { type: values.deviceType })
      onCancel()

      API.assignDevice(patient.id, { id, deviceType: values.deviceType, hubId }).then((response) => {
        if (isApiError(response) && response.status === 409) {
          message.error(
            <>
              {t('error.deviceIdAlreadyAssigned')}
              <Button
                style={{ paddingLeft: '6px' }}
                type="link"
                onClick={() => {
                  message.destroy()
                  navigate(
                    routes.patientDetails((response as AssignIdConflictError)?.error.userId, PatientDetailsTab.devices),
                  )
                }}
              >
                {t('error.unassignHere')}
              </Button>
            </>,
            6,
          )
        } else if (isApiError(response) && response.status === 400) {
          message.error(t('error.noDashes'), 6)
        } else if (isApiError(response) && response.status === 404) {
          message.error(t('error.noDevice'), 6)
        } else {
          onAssignDevice(patient.id)
        }
      })
    }, 300),
    [],
  )

  return (
    <Modal title={t('widgets.devices.assignDevice')} open={isVisible} footer={null} onCancel={onCancel} destroyOnClose>
      <Form onFinish={handleAssignDevice} layout="vertical" form={form} preserve={false}>
        <Wrapper>
          <Form.Item
            name="deviceType"
            rules={[{ required: true, message: t('validations.enterDevice') }]}
            label={t('widgets.devices.selectDeviceType')}
          >
            <Select
              style={{ width: '100%' }}
              placeholder={t('placeholders.deviceType')}
              options={assignableDevices.map((device) => ({
                label: getDeviceName(device),
                value: device,
              }))}
            />
          </Form.Item>
        </Wrapper>
        <Wrapper>
          <Form.Item
            name="id"
            rules={[{ type: 'string', min: 1, required: !isTenoviDevice }]}
            label={t('widgets.devices.deviceId')}
          >
            <Input placeholder={t('placeholders.deviceId')} />
          </Form.Item>
          <Form.Item
            name="hubId"
            rules={[{ type: 'string', min: 1, required: isTenoviDevice }]}
            label={t('widgets.devices.hubId')}
          >
            <Input placeholder={t('placeholders.hubId')} />
          </Form.Item>
        </Wrapper>
        <ButtonWrapper>
          <Button htmlType="submit" type="primary">
            {t('widgets.devices.assignDevice')}
          </Button>
        </ButtonWrapper>
      </Form>
    </Modal>
  )
}
