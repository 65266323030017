import { PatientDetailsTab } from './PatientDetails'
import { SiteManagementTab } from './SiteManagement'

const root = Object.assign(() => '/', { path: '/' })
const patientList = Object.assign(() => '/app/patients', { path: '/app/patients' })
const patientDetails = Object.assign((id: string, tab: PatientDetailsTab) => `/app/patient/${id}/${tab}`, {
  path: '/app/patient/:id/:tab',
})
const settings = Object.assign(() => '/app/settings', { path: '/app/settings' })
const csvImport = Object.assign(() => '/app/data-import', { path: '/app/data-import' })
const siteManagement = Object.assign((tab: SiteManagementTab) => `/app/site-management/${tab}`, {
  path: '/app/site-management/:tab',
})
const userManagement = Object.assign(() => '/app/user-management', { path: '/app/user-management' })
const roleManagement = Object.assign(() => '/app/role-management', { path: '/app/role-management' })
const reportDownload = Object.assign(
  (site: string, year: number, month: number, format: string) => `/app/report/${site}/${year}/${month}/${format}`,
  { path: '/app/report/:site/:year/:month/:format' },
)
const fileDownload = Object.assign(
  (site: string, fileId: string, filename: string) =>
    `/app/files/${site}/${fileId}?name=${encodeURIComponent(filename)}`,
  { path: '/app/files/:site/:id' },
)
const manualOrders = Object.assign(() => '/app/manual-orders', { path: '/app/manual-orders' })

const archive = Object.assign(() => '/app/archive', { path: '/app/archive' })

export const routes = {
  root,
  patientList,
  csvImport,
  manualOrders,
  patientDetails,
  reportDownload,
  fileDownload,
  settings,
  siteManagement,
  userManagement,
  roleManagement,
  archive,
} as const
