import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Button, Modal, Typography } from 'antd'

import { API } from '../../lib/api'
import { useLoading } from '../../lib/hooks/useLoading'
import { ModalProps } from './types'

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`

export function ResetDemoPatients({ isVisible, onCancel }: ModalProps): JSX.Element {
  const { t } = useTranslation()
  const [isLoading, withLoading] = useLoading()

  async function handleConfirm(): Promise<void> {
    const success = await withLoading(API.devResetDemoData())
    if (success) window.location.reload()
  }

  return (
    <Modal open={isVisible} onCancel={onCancel} title={t('menus.main.resetDemoPatients')} footer={null} destroyOnClose>
      <Typography.Text>{t('menus.main.resetDemoModal.text')}</Typography.Text>
      <ButtonWrapper>
        <Button disabled={isLoading} loading={isLoading} type="primary" onClick={handleConfirm}>
          {t('menus.main.resetDemoModal.submit')}
        </Button>
      </ButtonWrapper>
    </Modal>
  )
}
