import * as Sentry from '@sentry/react'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjsDuration from 'dayjs/plugin/duration'
import dayjsIsSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isToday from 'dayjs/plugin/isToday'
import localeData from 'dayjs/plugin/localeData'
import dayjsLocalizedFormat from 'dayjs/plugin/localizedFormat'
import dayjsRelativeTime from 'dayjs/plugin/relativeTime'
import dayjsTimezone from 'dayjs/plugin/timezone'
import dayjsUtc from 'dayjs/plugin/utc'
import weekday from 'dayjs/plugin/weekday'
import mixpanel from 'mixpanel-browser'

import pkg from '../../package.json'
import { env } from './env'
import { initI18n } from './i18n'

dayjs.extend(dayjsUtc)
dayjs.extend(dayjsTimezone)
dayjs.extend(dayjsRelativeTime)
dayjs.extend(dayjsLocalizedFormat)
dayjs.extend(dayjsDuration)
dayjs.extend(isToday)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(advancedFormat)
dayjs.extend(customParseFormat)
dayjs.extend(dayjsIsSameOrAfter)

initI18n()

Sentry.init({
  dsn: env.PUBLIC_TUNA_SENTRY_DSN,
  environment: env.PUBLIC_STAGE,
  release: `tuna-app@${pkg.version}`,
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
  enabled: !!env.PUBLIC_STAGE && env.PUBLIC_STAGE !== 'development',
  debug: !!env.PUBLIC_STAGE && env.PUBLIC_STAGE === 'development',
  beforeSend: (event) => {
    if (event.request?.query_string) {
      // filter tokens which can be used to access ePHI
      const params = new URLSearchParams(event.request.query_string)
      if (params.has('launch')) params.set('launch', '<filtered>')
      if (params.has('code')) params.set('code', '<filtered>')
      event.request.query_string = params.toString()
    }
    return event
  },
})

if (env.PUBLIC_TUNA_MIXPANEL_KEY) {
  mixpanel.init(env.PUBLIC_TUNA_MIXPANEL_KEY, {
    track_pageview: false,
    disable_cookie: true,
  })
}
