import { PermissionName } from '@vetahealth/fishing-gear/permissions'
import { CareManagerRole, CreateCareManagerRole } from '@vetahealth/tuna-can-api'
import { Checkbox, Input } from 'antd'
import i18next, { TFunction } from 'i18next'
import React from 'react'
import { Field } from '../../helpers'
import { Actions } from './Actions'
import { EditableCell } from './EditableCell'
import { RoleTableFormValues } from './types'

export const isRoleUpdate = (role: CareManagerRole | CreateCareManagerRole): role is CareManagerRole => {
  return 'id' in role && role.id !== undefined
}

export function getPermissionLabel(permission: PermissionName): string {
  const labels: Record<PermissionName, string> = {
    manage_role: i18next.t('permissions.manageRole'),
    manage_user: i18next.t('permissions.manageUser'),
    manage_site: i18next.t('permissions.manageSite'),
    snapshot_site: i18next.t('permissions.snapshotSite'),
    access_patient: i18next.t('permissions.accessPatient'),
    delete_any_note: i18next.t('permissions.deleteAnyNote'),
    de_identify: i18next.t('permissions.deIdentify'),
    dispatch_manual_order: i18next.t('permissions.dispatchManualOrder'),
    upload_file: i18next.t('permissions.uploadFile'),
    download_file: i18next.t('permissions.downloadFile'),
    manage_any_file: i18next.t('permissions.manageAnyFile'),
    publish_billing_report: i18next.t('permissions.publishBillingReport'),
    delete_submitted_order: i18next.t('permissions.deleteSubmittedOrder'),
    manage_device: i18next.t('permissions.manageDevice'),
    mute_permanently: i18next.t('permissions.mutePermanently'),
    modify_patient_alert: i18next.t('permissions.modifyPatientAlert'),
    test_features: i18next.t('permissions.testFeatures'),
  }
  return labels[permission] || permission
}

export function convertToFormValues(roles: CareManagerRole[]): RoleTableFormValues[] {
  return roles.map((role) => {
    return {
      name: role.name,
      id: role.id,
      ...Object.values(PermissionName).reduce(
        (acc, permission) => Object.assign({}, acc, { [permission]: role.permissions.includes(permission) }),
        {} as Record<PermissionName, boolean>,
      ),
    }
  })
}

export function convertToRole(role: RoleTableFormValues): CareManagerRole | CreateCareManagerRole {
  const { name, id, ...permissions } = role
  return {
    name,
    id,
    permissions: Object.entries(permissions)
      .map(([permission, value]) => {
        return value ? permission : false
      })
      .filter(Boolean) as PermissionName[],
  }
}

export function getFields({
  t,
  editingIndex,
  onSave,
  onCancel,
  onDelete,
  onEdit,
}: {
  t: TFunction
  editingIndex?: number
  onSave: (index: number) => void
  onCancel: (index: number) => void
  onEdit: (index: number) => void
  onDelete: (index: number) => void
}): Field<any>[] {
  return [
    {
      key: 'name',
      title: t('table.role'),
      fixed: 'left',
      noSorting: true,
      width: 170,
      format: (_value, _record, index: number) => {
        return (
          <EditableCell
            editing={index === editingIndex}
            rules={[{ required: true, message: t('validations.roleName') }]}
            name={[index, 'name']}
          >
            <Input placeholder={t('placeholders.roleName')} />
          </EditableCell>
        )
      },
    },
    ...Object.values(PermissionName).map<Field<any>>((permission: PermissionName) => ({
      key: permission,
      title: getPermissionLabel(permission),
      noSorting: true,
      width: 100,
      format: (_value, _record, index: number) => {
        return (
          <EditableCell editing={index === editingIndex} name={[index, permission]} valuePropName="checked">
            <Checkbox />
          </EditableCell>
        )
      },
    })),
    {
      key: 'actions',
      title: t('table.actions'),
      fixed: 'right',
      noSorting: true,
      width: 170,
      format: (_value, _record: RoleTableFormValues, index: number) => (
        <Actions
          isEditing={index === editingIndex}
          isDisabled={editingIndex !== undefined}
          onSave={() => onSave(index)}
          onCancel={() => onCancel(index)}
          onEdit={() => onEdit(index)}
          onDelete={() => onDelete(index)}
        />
      ),
    },
  ]
}
