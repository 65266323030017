import dayjs from 'dayjs'
import i18next from 'i18next'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import React, { ReactNode } from 'react'
import styled from 'styled-components'

import { getDisplayValue } from '@vetahealth/fishing-gear/conversions'
import { isDefined } from '@vetahealth/fishing-gear/lib/typeguards'
import {
  Alert,
  CareStateEnum,
  ConditionEnum,
  MutedEnum,
  NotificationStatusEnum,
  PatientOverviewItemWarningsEnum,
  SexEnum,
  StatusEnum,
} from '@vetahealth/tuna-can-api'

import { Tag, Tooltip } from 'antd'
import { ColumnFilterItem } from 'antd/lib/table/interface'
import { FilterDropdown, SearchDropdown, searchIcon } from '../../../lib/antdFilters'
import { formatPhone } from '../../../lib/formatPhone'

import { PopulationViewState, columns } from '../../../lib/PopulationViewState'
import { FilterData, Patient } from '../../../lib/hooks/types'
import { isRiskScoreAlert, isTaskAlert, isTrackingAlert } from '../../../stores/results'
import { useSitesStore } from '../../../stores/sites'
import { useUserStore } from '../../../stores/user'
import { AlertIcon } from '../../AlertIcon'
import { getAlertMessage } from '../../Alerts'
import { convertTrackingAlertSubjectToTrackingType } from '../../Alerts/helpers'
import { getConditionNames } from '../../Forms/helpers'
import { Field, defaultSorting } from '../../helpers'
import { formatInterval, getCareStateNames, getNotificationStatusLabel, getSexLabel, getStatusLabel } from '../helpers'

export enum AlertFilterValue {
  hasAlerts = 'hasAlerts',
  hasNoAlerts = 'hasNoAlerts',
}
const AlertWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`
const ValueWithAlert = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

function asRadioFilter(filterArray: ColumnFilterItem[]): ColumnFilterItem[] & { asRadio: boolean } {
  return Object.assign([], filterArray, { asRadio: true })
}

function formatLastAlerts(lastAlerts: Alert[] | undefined, patient: Patient): ReactNode {
  if (!lastAlerts) return ''

  function renderAlert(alerts: Alert[], patient: Patient): ReactNode {
    const units = useUserStore.getState().units
    const alert = alerts[0]

    let valueStr = i18next.t('widgets.population.concerningDefault')

    if (isTaskAlert(alert)) {
      valueStr = i18next.t('widgets.population.concerningAnswer')
    }

    if (isTrackingAlert(alert)) {
      const type = convertTrackingAlertSubjectToTrackingType(alert.alertConfig.subject)
      valueStr = getDisplayValue(alert.meta.values.filter(isDefined), alert.meta.unit, type, units[type])
    }

    return (
      <ValueWithAlert key={valueStr}>
        <Tooltip title={dayjs(alert.timestamp).tz(patient.timeZone).format('lll z')}>
          {`${valueStr} – ${dayjs(alert.timestamp).fromNow()}`}
        </Tooltip>
        {(isTrackingAlert(alert) || isRiskScoreAlert(alert)) && (
          <AlertIcon messages={alerts.map(({ alertConfig }) => getAlertMessage(alertConfig))} />
        )}
      </ValueWithAlert>
    )
  }

  const groupedTrackingAlerts = groupBy(lastAlerts.filter(isTrackingAlert), 'trackingEventId')
  const groupedRiskScoreAlerts = groupBy(lastAlerts.filter(isRiskScoreAlert), 'taskResponseId')
  const groupedTaskAlerts = groupBy(lastAlerts.filter(isTaskAlert), 'taskResponseId')

  return (
    <AlertWrapper>
      {Object.values(groupedTrackingAlerts).map((trackingAlerts) => renderAlert(trackingAlerts, patient))}
      {Object.values(groupedRiskScoreAlerts).map((riskScoreAlerts) => renderAlert(riskScoreAlerts, patient))}
      {Object.values(groupedTaskAlerts).map((taskAlerts) => renderAlert(taskAlerts, patient))}
    </AlertWrapper>
  )
}

function formatTimestamp(timestamp: string | undefined, patient: Patient): React.ReactNode {
  if (!timestamp) return ''
  return <Tooltip title={dayjs(timestamp).tz(patient.timeZone).format('lll z')}>{dayjs(timestamp).fromNow()}</Tooltip>
}

function formatLastActivity(timestamp: string, patient: Patient): JSX.Element {
  if (!timestamp)
    return (
      <ValueWithAlert>
        <AlertIcon
          message={
            patient.warnings.includes(PatientOverviewItemWarningsEnum.MissingOnboarding)
              ? i18next.t('widgets.population.missingOnboarding')
              : i18next.t('widgets.population.lastActivityEmpty')
          }
        />
      </ValueWithAlert>
    )
  return (
    <ValueWithAlert>
      <Tooltip title={dayjs(timestamp).tz(patient.timeZone).format('lll z')}>{dayjs(timestamp).fromNow()}</Tooltip>
      {patient.warnings.includes(PatientOverviewItemWarningsEnum.Inactivity) && (
        <AlertIcon message={i18next.t('widgets.population.lastActivityEmpty3Days')} />
      )}
    </ValueWithAlert>
  )
}

function formatTotalActivityTime(activityTime: number | undefined, patient: Patient): JSX.Element {
  return (
    <ValueWithAlert>
      {activityTime ?? ''}
      {patient.warnings.includes(PatientOverviewItemWarningsEnum.MissingOnboarding) && (
        <AlertIcon message={i18next.t('widgets.population.missingOnboarding')} />
      )}
    </ValueWithAlert>
  )
}

function formatRpmMeasurements(_measurementCount: unknown, patient: Patient): JSX.Element {
  return (
    <ValueWithAlert>
      {patient.rpm?.billingPeriod ? (
        <Tooltip title={formatInterval(patient.rpm.billingPeriod)}>{patient.rpm?.measurementCount}</Tooltip>
      ) : (
        patient.rpm?.measurementCount ?? ''
      )}
      {patient.warnings.includes(PatientOverviewItemWarningsEnum.MissingOnboarding) && (
        <AlertIcon message={i18next.t('widgets.population.missingOnboarding')} />
      )}
      {patient.warnings.includes(PatientOverviewItemWarningsEnum.LowPredictedMeasurementCount) && (
        <AlertIcon
          message={i18next.t('widgets.population.rpmMeasurementsRequired', {
            prediction: patient.rpm?.measurementCountPrediction,
          })}
        />
      )}
    </ValueWithAlert>
  )
}

function formatSite(site: string, patient: Patient): JSX.Element {
  if (!patient.siteName || !patient.siteShortName) return <span>{site}</span>
  return <Tooltip title={patient.siteName}>{patient.siteShortName}</Tooltip>
}

function formatSubscribers(subscribers: string[]): React.ReactNode | null {
  const users = useSitesStore.getState().users

  return subscribers.map((id) => <Tag key={id}>{users.find((user) => user.id === id)?.name}</Tag>)
}

interface GetCommonFieldsParams {
  filterData: FilterData
  viewState: PopulationViewState
}

// Note: Order of this field map also determines the order in the column selection dropdown
export function getFields({
  filterData,
  viewState,
}: GetCommonFieldsParams): Record<(typeof columns)[keyof typeof columns], Field<Patient>> {
  const tableOrder = viewState.sortColumn ? { [viewState.sortColumn]: viewState.sortDirection } : {}
  const conditionNames = getConditionNames()

  return {
    [columns.firstName]: {
      key: 'firstName',
      title: i18next.t('table.firstName'),
      sortOrder: tableOrder[columns.firstName],
      filterDropdown: SearchDropdown,
      filterIcon: searchIcon,
      filteredValue: viewState.searchFirstName ? [viewState.searchFirstName] : null,
    },
    [columns.lastName]: {
      key: 'lastName',
      title: i18next.t('table.lastName'),
      sortOrder: tableOrder[columns.lastName],
      filterDropdown: SearchDropdown,
      filterIcon: searchIcon,
      filteredValue: viewState.searchLastName ? [viewState.searchLastName] : null,
    },
    [columns.dateOfBirth]: {
      key: 'dateOfBirth',
      title: i18next.t('table.dateOfBirth'),
      format: (dob: string) => dayjs(dob).format('L'),
      sortOrder: tableOrder[columns.dateOfBirth],
    },
    [columns.sex]: {
      key: 'sex',
      title: i18next.t('table.sex'),
      format: (sex: SexEnum) => getSexLabel(sex),
      filters: Object.values(SexEnum).map((sex) => ({ text: getSexLabel(sex), value: sex })),
      filteredValue: viewState.sexes,
      filterDropdown: FilterDropdown,
      noSorting: true,
    },
    [columns.site]: {
      key: 'site',
      title: i18next.t('table.site'),
      sortOrder: tableOrder[columns.site],
      format: formatSite,
      filters: filterData.sites,
      filteredValue: viewState.sites,
      filterDropdown: FilterDropdown,
    },
    [columns.state]: {
      key: 'state',
      title: i18next.t('table.state'),
      filters: filterData.states,
      filteredValue: viewState.states,
      filterDropdown: FilterDropdown,
    },
    [columns.clientIdentifier]: {
      key: 'clientIdentifier',
      title: i18next.t('table.clientReference'),
      sortOrder: tableOrder[columns.clientIdentifier],
    },
    [columns.email]: {
      key: 'email',
      title: i18next.t('table.email'),
      sortOrder: tableOrder[columns.email],
      filterDropdown: SearchDropdown,
      filterIcon: searchIcon,
      filteredValue: viewState.searchEmail ? [viewState.searchEmail] : null,
    },
    [columns.mobilePhone]: {
      key: 'mobilePhone',
      title: i18next.t('table.mobilePhone'),
      noSorting: true,
      format: (phone: string | undefined) => phone && formatPhone(phone),
    },
    [columns.secondaryMobilePhone]: {
      key: 'secondaryMobilePhone',
      title: i18next.t('table.secondaryMobilePhone'),
      noSorting: true,
      format: (phone: string | undefined) => phone && formatPhone(phone),
    },
    [columns.landlinePhone]: {
      key: 'landlinePhone',
      title: i18next.t('table.landlinePhone'),
      noSorting: true,
      format: (phone: string | undefined) => phone && formatPhone(phone),
    },
    [columns.secondaryLandlinePhone]: {
      key: 'secondaryLandlinePhone',
      title: i18next.t('table.secondaryLandlinePhone'),
      noSorting: true,
      format: (phone: string | undefined) => phone && formatPhone(phone),
    },
    [columns.timeZone]: {
      key: 'timeZone',
      title: i18next.t('table.timeZone'),
      sortOrder: tableOrder[columns.timeZone],
    },
    [columns.tags]: {
      key: 'tags',
      title: i18next.t('table.tags'),
      format: (tags: string[]) => [...tags].sort().map((tag) => <Tag key={tag}>{tag}</Tag>),
      noSorting: true,
      filterMultiple: true,
      filters: filterData.tags,
      filteredValue: viewState.tags,
      filterDropdown: FilterDropdown,
    },
    [columns.program]: {
      key: 'programName',
      title: i18next.t('table.program'),
      noSorting: true,
      filterMultiple: true,
      filters: filterData.programs,
      filteredValue: viewState.programs,
      filterDropdown: FilterDropdown,
    },
    [columns.provider]: {
      key: 'provider',
      title: i18next.t('table.provider'),
      noSorting: true,
      filterMultiple: true,
      filters: filterData.providers,
      filteredValue: viewState.providers,
      filterDropdown: FilterDropdown,
    },
    [columns.conditions]: {
      key: 'conditions',
      title: i18next.t('table.conditions'),
      format: (conditions?: ConditionEnum[]) =>
        (conditions ?? [])
          .map((key) => ({ condition: conditionNames[key] ?? key, isCustom: !(key in conditionNames) }))
          .sort()
          .map(({ condition, isCustom }) => (
            <Tag key={condition} style={{ fontStyle: isCustom ? 'italic' : 'normal' }}>
              {condition}
            </Tag>
          )),
      noSorting: true,
      filterMultiple: true,
      filters: filterData.conditions,
      filteredValue: viewState.conditions,
      filterDropdown: FilterDropdown,
    },
    [columns.diagnosisCodes]: {
      key: 'diagnosisCodes',
      title: i18next.t('table.diagnosisCodes'),
      format: (diagnosisCodes: string[]) => [...diagnosisCodes].sort().map((code) => <Tag key={code}>{code}</Tag>),
      noSorting: true,
      filterMultiple: true,
      filters: filterData.diagnosisCodes,
      filteredValue: viewState.diagnosisCodes,
      filterDropdown: FilterDropdown,
    },
    [columns.rpmMeasurementCount]: {
      key: 'rpm.measurementCount',
      align: 'center',
      format: formatRpmMeasurements,
      title: i18next.t('table.rpmMeasurementCount'),
      sortOrder: tableOrder[columns.rpmMeasurementCount],
    },
    [columns.rpmTotalActivityTime]: {
      key: 'rpm.totalActivityTime',
      align: 'center',
      title: i18next.t('table.rpmTotalActivityTime'),
      format: formatTotalActivityTime,
      sortOrder: tableOrder[columns.rpmTotalActivityTime],
    },
    [columns.rpmLastMeasurement]: {
      key: 'rpm.lastMeasurementTimestamp',
      align: 'center',
      title: i18next.t('table.rpmLastActivity'),
      format: formatLastActivity,
      sortOrder: tableOrder[columns.rpmLastMeasurement],
      sortDirections: ['descend', 'ascend', null],
    },
    [columns.lastConcerningEvent]: {
      key: 'lastAlerts',
      format: formatLastAlerts,
      align: 'center',
      title: i18next.t('table.concerningEvent'),
      compare: (a: Patient, b: Patient, sortOrder) =>
        defaultSorting(a.lastAlerts[0], b.lastAlerts[0], 'timestamp', sortOrder ?? null),
      sortDirections: ['descend', 'ascend', null],
      sortOrder: tableOrder[columns.lastConcerningEvent],
      filters: asRadioFilter([
        { value: AlertFilterValue.hasAlerts, text: i18next.t('alerts.hasAlerts') },
        { value: AlertFilterValue.hasNoAlerts, text: i18next.t('alerts.hasNoAlerts') },
      ]),
      filteredValue:
        viewState.concerning !== undefined
          ? [viewState.concerning ? AlertFilterValue.hasAlerts : AlertFilterValue.hasNoAlerts]
          : [],
      filterDropdown: FilterDropdown,
    },
    [columns.careState]: {
      key: 'careState',
      title: i18next.t('table.careState'),
      format: (state: CareStateEnum) => getCareStateNames()[state],
      sortOrder: tableOrder[columns.careState],
      filteredValue: viewState.careStates,
      filters: filterData.careStates,
      filterDropdown: FilterDropdown,
      noSorting: true,
    },
    [columns.careStateModifiedAt]: {
      key: 'careStateModifiedAt',
      title: i18next.t('table.careStateModifiedAt'),
      format: formatTimestamp,
      sortOrder: tableOrder[columns.careStateModifiedAt],
    },
    [columns.accountStatus]: {
      key: 'status',
      title: i18next.t('table.accountStatus'),
      format: (status: StatusEnum) => getStatusLabel(status),
      filters: Object.values(StatusEnum)
        .filter((status) => status !== StatusEnum.Archived)
        .map((status) => ({ text: getStatusLabel(status), value: status })),
      filteredValue: viewState.accountStatuses,
      filterDropdown: FilterDropdown,
      noSorting: true,
    },
    [columns.lastMeasurementAt]: {
      key: 'lastMeasurementAt',
      title: i18next.t('table.lastMeasurementAt'),
      format: formatTimestamp,
      sortOrder: tableOrder[columns.lastMeasurementAt],
    },
    [columns.lastInteractionNoteAt]: {
      key: 'lastInteractionNoteAt',
      title: i18next.t('table.lastInteractionNoteAt'),
      format: formatTimestamp,
      sortOrder: tableOrder[columns.lastInteractionNoteAt],
    },
    [columns.devicesDeliveredAt]: {
      key: 'devicesDeliveredAt',
      title: i18next.t('table.devicesDeliveredAt'),
      format: formatTimestamp,
      sortOrder: tableOrder[columns.devicesDeliveredAt],
    },
    [columns.subscribers]: {
      key: 'subscribers',
      title: i18next.t('table.subscribers'),
      format: formatSubscribers,
      filterMultiple: true,
      filters: filterData.subscribers,
      filteredValue: viewState.subscribers,
      filterDropdown: FilterDropdown,
      sortOrder: tableOrder[columns.subscribers],
    },
    [columns.firstDeviceOrderedAt]: {
      key: 'firstDeviceOrderedAt',
      title: i18next.t('table.firstDeviceOrderedAt'),
      format: formatTimestamp,
      sortOrder: tableOrder[columns.firstDeviceOrderedAt],
    },
    [columns.lastOnboardedAt]: {
      key: 'lastOnboardedAt',
      title: i18next.t('table.lastOnboardedAt'),
      format: formatTimestamp,
      sortOrder: tableOrder[columns.lastOnboardedAt],
    },
    [columns.openTasks]: {
      key: 'openTasks',
      title: i18next.t('table.openTasks'),
      format: (openTasks: string[]) => openTasks.map((tag) => <Tag key={tag}>{tag}</Tag>),
      filterMultiple: true,
      filters: filterData.openTasks,
      filteredValue: viewState.openTasks,
      filterDropdown: FilterDropdown,
      noSorting: true,
    },
    [columns.notificationStatus]: {
      key: 'notificationStatus',
      title: i18next.t('table.notificationStatus'),
      format: (status: MutedEnum | NotificationStatusEnum) => getNotificationStatusLabel(status),
      filters: sortBy(
        Object.values<NotificationStatusEnum | MutedEnum>(NotificationStatusEnum)
          .concat(Object.values(MutedEnum))
          .map((status) => ({
            text: getNotificationStatusLabel(status),
            value: status,
          })),
        'text',
      ),
      filteredValue: viewState.notificationStatuses,
      filterDropdown: FilterDropdown,
      noSorting: true,
    },
  }
}
