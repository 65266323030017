import i18next from 'i18next'
import get from 'lodash/get'
import React, { ElementType, ReactNode } from 'react'

import { FusionUserNameWithSites, NoteTypeEnum, Site } from '@vetahealth/tuna-can-api'

import { ColumnType } from 'antd/es/table'
import { CompareFn, SortOrder } from 'antd/es/table/interface'

export function defaultSorting<Item>(a: Item, b: Item, key: string, sortOrder: SortOrder): number {
  // using lodash/get enables nested paths in dot notation
  const rawA = get(a, key)
  const valueA = typeof rawA === 'string' ? rawA.toLowerCase() : rawA
  const rawB = get(b, key)
  const valueB = typeof rawB === 'string' ? rawB.toLowerCase() : rawB

  if (valueA === valueB) return 0
  const descendModifier = sortOrder === 'ascend' ? 1 : -1

  if (valueA === undefined) return 1 * descendModifier
  if (valueB === undefined) return -1 * descendModifier

  if (valueA < valueB) return -1
  if (valueA > valueB) return 1
  return 0
}

export interface TableData<DataItem> {
  dataSource: Array<DataItem & { key: number }>
  columns: Array<
    Pick<
      ColumnType<DataItem>,
      | 'width'
      | 'align'
      | 'sorter'
      | 'render'
      | 'key'
      | 'dataIndex'
      | 'title'
      | 'sortOrder'
      | 'defaultSortOrder'
      | 'sortDirections'
    >
  >
}

export type Field<DataItem> = Omit<ColumnType<DataItem>, 'key' | 'render'> & {
  key: keyof DataItem | string
  title: string
  compare?: CompareFn<DataItem>
  format?: (item: DataItem[keyof DataItem], record: DataItem, index?: number) => DataItem[keyof DataItem] | ReactNode
  noSorting?: boolean
}

export function getSiteSelectOption({ key, name, shortName }: Site): { value: string; label: string } {
  return { value: key, label: `${shortName} (${name})` }
}

export function getTableData<DataItem>({
  data,
  fields,
  onHeaderCell,
  actions,
}: {
  data: DataItem[]
  fields: Array<Field<DataItem>>
  onHeaderCell?: ColumnType<DataItem>['onHeaderCell']
  actions?: ElementType<{ record: DataItem }>
}): TableData<DataItem> {
  const columns: TableData<DataItem>['columns'] = fields.map(({ key, compare, noSorting, format, ...rest }) => ({
    ...rest,
    key: key as string,
    dataIndex: (key as string).split('.'),
    render: (item, record, index) => (format ? format(item, record, index) : item),
    ...(!noSorting && {
      sorter: {
        compare:
          compare ??
          ((a: DataItem, b: DataItem, sortOrder: SortOrder) => defaultSorting(a, b, key as string, sortOrder)),
      },
      onHeaderCell,
    }),
  }))

  const dataSource: TableData<DataItem>['dataSource'] = data.map((item, index) => ({
    ...item,
    key: index,
  }))

  if (actions) {
    const Actions = actions
    columns.push({
      key: 'actions',
      dataIndex: 'actions',
      title: i18next.t('table.actions'),
      width: 100,
      render(_, record) {
        return <Actions record={record} />
      },
    })
  }

  return { dataSource, columns }
}

export const noteTypes: NoteTypeEnum[] = Object.values(NoteTypeEnum)

export function getNoteTypeName(type: string): string | undefined {
  return {
    [NoteTypeEnum.AppointmentScheduled]: i18next.t('noteTypes.appointmentScheduled'),
    [NoteTypeEnum.CarePathwayConcernsOutreachCall]: i18next.t('noteTypes.carePathwayConcernsOutreachCall'),
    [NoteTypeEnum.CarePathwayEngagementOutreachCall]: i18next.t('noteTypes.carePathwayEngagementOutreachCall'),
    [NoteTypeEnum.ClinicalChampionEscalationWithoutTreatmentPlanChanges]: i18next.t(
      'noteTypes.clinicalChampionEscalationWithoutTreatmentPlanChanges',
    ),
    [NoteTypeEnum.ClinicalChampionEscalationWithTreatmentPlanChanges]: i18next.t(
      'noteTypes.clinicalChampionEscalationWithTreatmentPlanChanges',
    ),
    [NoteTypeEnum.ConsentCall]: i18next.t('noteTypes.consentCall'),
    [NoteTypeEnum.DataReviewWithEscalation]: i18next.t('noteTypes.dataReviewWithEscalation'),
    [NoteTypeEnum.DataReviewWithoutEscalation]: i18next.t('noteTypes.dataReviewWithoutEscalation'),
    [NoteTypeEnum.DeviceTroubleshootingWithNewDeviceShippedCall]: i18next.t(
      'noteTypes.deviceTroubleshootingWithNewDeviceShippedCall',
    ),
    [NoteTypeEnum.DeviceTroubleshootingWithResolutionCall]: i18next.t(
      'noteTypes.deviceTroubleshootingWithResolutionCall',
    ),
    [NoteTypeEnum.EmergencyCall]: i18next.t('noteTypes.emergencyCall'),
    [NoteTypeEnum.InsuranceNonCoverageAndContinuedParticipationOutreachCall]: i18next.t(
      'noteTypes.insuranceNonCoverageAndContinuedParticipationOutreachCall',
    ),
    [NoteTypeEnum.InsuranceNonCoverageAndDeclineToParticipateOutreachCall]: i18next.t(
      'noteTypes.insuranceNonCoverageAndDeclineToParticipateOutreachCall',
    ),
    [NoteTypeEnum.MeasurementFrequencyOutreachCall]: i18next.t('noteTypes.measurementFrequencyOutreachCall'),
    [NoteTypeEnum.Note]: i18next.t('noteTypes.note'),
    [NoteTypeEnum.OutOfRangeValuesOutreachCall]: i18next.t('noteTypes.outOfRangeValuesOutreachCall'),
    [NoteTypeEnum.PatientInquiryCall]: i18next.t('noteTypes.patientInquiryCall'),
    [NoteTypeEnum.ProgramOverviewAndConsentCall]: i18next.t('noteTypes.programOverviewAndConsentCall'),
    [NoteTypeEnum.Reminder]: i18next.t('noteTypes.reminder'),
    [NoteTypeEnum.TreatmentManagementOutreachCall]: i18next.t('noteTypes.treatmentManagementOutreachCall'),
  }[type]
}

interface Viewer {
  clientId: string
  userId: string
}

export interface PatientIdToViewers {
  [key: string]: Viewer[]
}

export type ViewersType = 'patient' | 'chat' | 'notifications'

export function getViewersTitle(
  viewers: PatientIdToViewers,
  patientId: string,
  users: FusionUserNameWithSites[],
  type: ViewersType,
): string {
  const uniqueUsers = [...new Set(viewers[patientId].map(({ userId }) => userId))]
  const names = uniqueUsers.map((userId) => users.find((user) => user.id === userId)?.name ?? userId)
  const count = names.length
  const lastOfNames = names.pop()
  const joinedNames = names.join(', ')

  const translations = {
    patient: i18next.t('widgets.patient.viewers', { count, names: joinedNames, lastOfNames }),
    chat: i18next.t('chat.viewersChat', { count, names: joinedNames, lastOfNames }),
    notifications: i18next.t('chat.viewersMessage', { count, names: joinedNames, lastOfNames }),
  }

  return translations[type]
}
