import dayjs from 'dayjs'
import orderBy from 'lodash/orderBy'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Note, NoteTypeEnum } from '@vetahealth/tuna-can-api'

import { Button, Form, Popconfirm, Select } from 'antd'

import { useLoading } from '../../../lib/hooks/useLoading'
import { trimDeep } from '../../../lib/normalizers'
import { TrackingEvent, trackEvent } from '../../../lib/tracking'
import { useNotesStore } from '../../../stores/notes'
import { usePatientStore } from '../../../stores/patient'
import { useUserStore } from '../../../stores/user'
import { CreateNote, FormKeys, NoteFormValues } from '../../Forms'
import { defaultSorting } from '../../helpers'
import { Card } from '../styles'
import { Card as NoteCard } from './Card'
import { NotesModal } from './NotesModal'
import { replaceNameAndCredentials, templates } from './templates'

export function Notes(): JSX.Element {
  const { t } = useTranslation()
  templates.sort((a, b) => defaultSorting(a, b, 'title', 'ascend'))
  const [form] = Form.useForm<NoteFormValues>()
  const [loading, withLoading] = useLoading()
  const [patient] = usePatientStore((state) => [state.patient])
  const [firstName, lastName, credentials] = useUserStore((state) => [
    state.firstName,
    state.lastName,
    state.credentials,
  ])
  const [notes, getNotes, createNote] = useNotesStore((state) => [state.notes, state.getNotes, state.createNote])
  const [isTemplateSelectionVisible, setIsTemplateSelectionVisible] = useState<boolean>(false)
  const [isNoteModalVisible, setIsNoteModalVisible] = useState<boolean>(false)
  const [selectedNoteId, setSelectedNoteId] = useState<Note['id']>()
  const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false)
  const [isFormTouched, setIsFormTouched] = useState<boolean>(form.isFieldsTouched())
  const escalationTemplates: string[] = [
    NoteTypeEnum.DataReviewWithEscalation,
    NoteTypeEnum.ClinicalChampionEscalationWithoutTreatmentPlanChanges,
    NoteTypeEnum.ClinicalChampionEscalationWithTreatmentPlanChanges,
  ]

  async function handleCreateNote(values: NoteFormValues): Promise<void> {
    if (!patient) return
    const isEscalation = escalationTemplates.includes(values.type)
    const success = await withLoading(createNote({ id: patient.id, values: trimDeep(values), isEscalation }))

    if (success) {
      form.resetFields()
      setIsFormTouched(false)
    }
  }

  const handleTemplateSelect = (type: NoteTypeEnum): void => {
    const template = templates.find((template) => template.type === type)
    if (template) form.setFieldsValue({ ...replaceNameAndCredentials(template, { firstName, lastName, credentials }) })
    setIsTemplateSelectionVisible(false)
    setIsFormTouched(false)
  }

  const handlePopConfirmVisibleChange = (visible: boolean): void => {
    if (!visible) {
      setIsPopupVisible(visible)
      return
    }
    if (!isTemplateSelectionVisible && (form.isFieldsTouched() || isFormTouched)) {
      setIsPopupVisible(visible)
      setIsFormTouched(true)
    } else {
      setIsTemplateSelectionVisible((prevVisible) => !prevVisible)
    }
  }

  function handleBeforeUnload(event: BeforeUnloadEvent): void {
    if (form.getFieldValue(FormKeys.TEXT)) {
      event.preventDefault()
      event.returnValue = 'changed'
    }
  }

  function handleNoteSelection(id: Note['id']): () => void {
    return () => {
      setSelectedNoteId(id)
      setIsNoteModalVisible(true)
    }
  }

  useEffect(() => {
    if (patient?.id) {
      getNotes(patient.id)
    }
  }, [patient?.id])

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => window.removeEventListener('beforeunload', handleBeforeUnload)
  }, [])

  if (!patient) return <div />

  return (
    <Card
      title={t('widgets.notes.title')}
      action={
        <Popconfirm
          title={t('widgets.notes.popupText')}
          placement="topRight"
          onOpenChange={handlePopConfirmVisibleChange}
          onConfirm={() => setIsTemplateSelectionVisible((prevVisible) => !prevVisible)}
          open={isPopupVisible}
        >
          <Button
            style={{ padding: 0 }}
            type="link"
            onClick={() => trackEvent(TrackingEvent.notesSelectTemplateClicked)}
          >
            {isTemplateSelectionVisible ? t('actions.cancel') : t('actions.selectTemplate')}
          </Button>
        </Popconfirm>
      }
    >
      {isTemplateSelectionVisible && (
        <Select
          style={{ width: '100%', marginBottom: '20px' }}
          showSearch={true}
          autoFocus
          placeholder={t('widgets.notes.selectTemplate')}
          open={true}
          options={templates.map((template) => ({
            value: template.type,
            label: template.title,
          }))}
          optionFilterProp="label"
          onSelect={handleTemplateSelect}
        />
      )}
      {!isTemplateSelectionVisible && (
        <>
          <Form
            form={form}
            layout="vertical"
            onFinish={handleCreateNote}
            validateTrigger="onChange"
            initialValues={{
              [FormKeys.PATIENT_INTERACTION]: true,
              [FormKeys.TIMESTAMP]: dayjs(),
              [FormKeys.NOTE_TYPE]: NoteTypeEnum.Note,
            }}
          >
            <CreateNote form={form} loading={loading} />
          </Form>
          {orderBy(notes, ['timestamp'], ['desc']).map((note) => (
            <NoteCard key={note.id} onClick={handleNoteSelection(note.id)} note={note} careState={note.careState} />
          ))}
        </>
      )}
      <NotesModal
        patientId={patient.id}
        note={notes.find((note) => note.id === selectedNoteId)}
        onCancel={() => {
          setSelectedNoteId(undefined)
          setIsNoteModalVisible(false)
        }}
        isVisible={isNoteModalVisible}
      />
    </Card>
  )
}
