import i18next from 'i18next'

import { Colors } from '@vetahealth/fishing-gear/colors'
import { CardDetails } from '@vetahealth/tuna-can-api'

import { BarChart } from '../Chart/BarChart'
import { createDeviceField } from '../Table/helpers'
import { ResultsConfig } from './types'

export function getStepsConfig(): ResultsConfig {
  return {
    key: 'steps',
    title: i18next.t('tracking.steps.title'),
    category: 'tracking',
    format: (event) => `${event.value ?? '-'}`,
    actions: { reviewBulk: true },
    chart: {
      component: BarChart,
      intervals: ['week', 'month', 'all'],
      dataKeys: [{ key: 'value', color: Colors.azure500 }],
      getData: (data: CardDetails[]) => data,
    },
    table: { defaultFields: true, additionalFields: [createDeviceField()], actions: ['review', 'delete'] },
  }
}
