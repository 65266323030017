import pull from 'lodash/pull'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

import { ModalName, ModalState } from './types'

const queue: ModalName[] = []

export const useModalStore = createWithEqualityFn<ModalState>(
  (set, get) => ({
    modal: undefined,
    openModal: (requested) => {
      const current = get().modal
      if (current === requested || queue.includes(requested)) return false
      if (current) {
        queue.push(requested)
      } else {
        set({ modal: requested })
      }
      return true
    },
    closeModal: (requested) => {
      const current = get().modal
      if (current === requested) {
        set({ modal: queue.shift() })
      } else {
        pull(queue, requested)
      }
    },
  }),
  shallow,
)
