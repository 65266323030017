import { faArrowLeftToLine, faArrowRightToLine, faCheck, faX } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { CareTask } from '@vetahealth/tuna-can-api'

import { Button, Drawer, Tooltip, message } from 'antd'

import { TrackingEvent, trackEvent } from '../../lib/tracking'
import { usePatientStore } from '../../stores/patient'
import { DatoAssets } from '../Assets'

const ButtonWrap = styled.div`
  display: flex;
  gap: 6px;
  margin-left: 16px;
`

type CareTaskState = {
  isOpen: boolean
  activeTask?: CareTask
  completedTasks: number[]
  taskPlacement: 'right' | 'left'
}
const initialState: CareTaskState = {
  isOpen: false,
  activeTask: undefined,
  completedTasks: [],
  taskPlacement: 'right',
}

export function CareTaskViewer({
  careTask,
  render,
}: {
  careTask: CareTask
  render: (openCareTaskViewer: () => void, onCompleteTask: () => void, isCompleted: boolean) => React.ReactNode
}): JSX.Element | null {
  const { t } = useTranslation()
  const [patient, completeCareTask] = usePatientStore((state) => [state.patient, state.completeCareTask])
  const [{ isOpen, activeTask, completedTasks, taskPlacement }, set] = useReducer(
    (state: CareTaskState, update: Partial<CareTaskState>) => ({ ...state, ...update }),
    initialState,
  )

  const handleCompleteCareTask = useCallback(
    async (id?: number, quickComplete?: boolean) => {
      if (patient && id) {
        trackEvent(TrackingEvent.careTaskCompleteClicked, { careTaskId: id, quickComplete })

        const success = await completeCareTask(id)

        if (success) {
          set({
            isOpen: false,
            completedTasks: [...completedTasks, id],
          })
          message.success(t('message.careTaskComplete'))
        }
      }
    },
    [patient, completedTasks],
  )

  function handleSwapWindow(): void {
    trackEvent(TrackingEvent.careTaskSwapWindowClicked)
    set({ taskPlacement: taskPlacement === 'left' ? 'right' : 'left' })
  }

  function handleOpenCareTask(careTask: CareTask): void {
    trackEvent(TrackingEvent.careTaskOpenClicked, { careTaskId: careTask.id })
    set({ isOpen: true, activeTask: careTask })
  }

  return (
    <>
      {render(
        () => handleOpenCareTask(careTask),
        () => handleCompleteCareTask(careTask.id, true),
        completedTasks.includes(careTask.id),
      )}
      <Drawer
        mask={false}
        width={'calc(33% + 6px)'}
        title={activeTask?.title}
        placement={taskPlacement}
        onClose={() => set({ isOpen: false })}
        open={isOpen}
        closeIcon={null}
        keyboard
        destroyOnClose
        extra={
          <ButtonWrap>
            <Tooltip title={t('careTask.complete')}>
              <Button
                type="primary"
                shape="circle"
                onClick={() => handleCompleteCareTask(activeTask?.id)}
                icon={<FontAwesomeIcon icon={faCheck} />}
              />
            </Tooltip>
            <Tooltip title={t('careTask.switchWindow')}>
              <Button
                shape="circle"
                onClick={handleSwapWindow}
                icon={<FontAwesomeIcon icon={taskPlacement === 'left' ? faArrowRightToLine : faArrowLeftToLine} />}
              />
            </Tooltip>
            <Button shape="circle" onClick={() => set({ isOpen: false })} icon={<FontAwesomeIcon icon={faX} />} />
          </ButtonWrap>
        }
      >
        <DatoAssets assets={activeTask?.assets ?? []} />
      </Drawer>
    </>
  )
}
