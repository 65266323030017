import { faPlay, faStop } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayjs from 'dayjs'
import i18next from 'i18next'
import last from 'lodash/last'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { getDisplayValue } from '@vetahealth/fishing-gear/conversions'
import { isDefined } from '@vetahealth/fishing-gear/lib/typeguards'
import { NotificationChannelEnum, PatientActivityNotification } from '@vetahealth/tuna-can-api'

import { Timeline, Tooltip } from 'antd'

import { API } from '../../../../lib/api'
import { useLoading } from '../../../../lib/hooks/useLoading'
import { usePatientStore } from '../../../../stores/patient'
import { useUserStore } from '../../../../stores/user'
import { Spinner } from '../../../Spinner'
import { NoData } from '../../styles'
import {
  getActivityType,
  getCardColor,
  getTaskStatus,
  getTitle,
  isNotificationItem,
  isOnboardingItem,
  isTaskItem,
  isTrackingItem,
} from './mappings'
import { ActivityType, Card, DayAndYear, Dot, LabelWrap, TaskStatus, Time, Title, Value } from './styles'
import { PatientActivity } from './types'

const Wrap = styled.div`
  padding-top: 16px;
`

const AudioLink = styled.a`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding-top: 4px;
  color: ${({ theme }) => theme.text};
  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`
const AudioIcon = styled.div`
  margin-right: 8px;
  padding: 4px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.tertiaryBackground};

`

const DATA_FETCH_LIMIT = 20

interface Pagination {
  limit: number
  offset: number
}

export function Activity(): JSX.Element {
  const theme = useTheme()
  const { t } = useTranslation()
  const [patient] = usePatientStore((state) => [state.patient])
  const [activity, setActivity] = useState<PatientActivity[]>([])
  const [pagination, setPagination] = useState<Pagination>({ limit: DATA_FETCH_LIMIT, offset: 0 })
  const [isLastElementVisible, setIsLastElementVisible] = useState(false)
  const [isLoading, withLoading] = useLoading()
  const [hasMoreData, setHasMoreData] = useState(true)
  const preferredUnitsByType = useUserStore((state) => state.units)
  const lastElementRef = useRef<HTMLDivElement>(null)
  const [audioIndex, setAudioIndex] = useState<number>(-1)
  const audioRefs = useRef<Array<HTMLAudioElement | null | undefined>>([])

  const patientTimeZone = patient?.timeZone ?? i18next.t('dateFormats.defaultTimezone')
  const timeFormat = t('dateFormats.time')

  function fetchPatientActivity(): void {
    if (patient?.id) {
      withLoading(
        API.getPatientActivity({ id: patient.id, ...pagination }).then((response) => {
          if (response) {
            const activityResponse = [...activity, ...response]
            setActivity(activityResponse)
            setPagination((oldPagination) => ({ ...oldPagination, offset: oldPagination.offset + DATA_FETCH_LIMIT }))
            if (response.length === 0) setHasMoreData(false)
          }
        }),
      )
    }
  }

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0]
      setIsLastElementVisible(entry.isIntersecting)
    })

    if (lastElementRef?.current) observer.observe(lastElementRef.current)
    return () => {
      if (lastElementRef?.current) observer.unobserve(lastElementRef.current)
    }
  }, [lastElementRef])

  useEffect(() => {
    if (isLastElementVisible && hasMoreData) fetchPatientActivity()
  }, [isLastElementVisible, hasMoreData])

  const togglePlayAudio = (index: number): void => {
    const audio = audioRefs.current[index]
    if (audioIndex === index) {
      audio?.pause()
      setAudioIndex(-1)
    } else if (audioIndex !== index) {
      audioRefs.current[audioIndex]?.pause()
      if (audio) audio.currentTime = 0
      audio?.play()
      setAudioIndex(index)
    }
  }

  function getNotifcationMessage(item: PatientActivityNotification, index: number): string | JSX.Element {
    if (item.channel === NotificationChannelEnum.Voice) {
      const shortLink = last(item.message.split('/'))
      return (
        <>
          <audio ref={(ref) => (audioRefs.current[index] = ref)} src={item.message} />
          <AudioLink onClick={() => togglePlayAudio(index)}>
            <AudioIcon>
              {audioIndex === index ? <FontAwesomeIcon icon={faStop} /> : <FontAwesomeIcon icon={faPlay} />}
            </AudioIcon>
            {shortLink}
          </AudioLink>
        </>
      )
    }
    return item.message
  }

  return (
    <Wrap>
      <Timeline
        mode="left"
        items={activity.map((item, index) => {
          return {
            label: (
              <Tooltip
                arrow={false}
                title={
                  isTrackingItem(item)
                    ? t('tracking.receivedAt', { time: dayjs(item.receivedAt).tz(patientTimeZone).format('lll') })
                    : undefined
                }
              >
                <LabelWrap>
                  <DayAndYear>{dayjs(item.timestamp).tz(patientTimeZone).format('ll')}</DayAndYear>
                  <Time>{dayjs(item.timestamp).tz(patientTimeZone).format(timeFormat)}</Time>
                </LabelWrap>
              </Tooltip>
            ),
            dot: <Dot $color={getCardColor(item, theme).active} />,
            children: (
              <Card $colors={getCardColor(item, theme)}>
                <ActivityType $colors={getCardColor(item, theme)}>{getActivityType(item)}</ActivityType>
                <Title>{getTitle(item)}</Title>
                {isTaskItem(item) && (
                  <>
                    <Value>{item.title}</Value>
                    <TaskStatus>{getTaskStatus(item)}</TaskStatus>
                  </>
                )}
                {isTrackingItem(item) && (
                  <Value>
                    {getDisplayValue(
                      [item.value, item.additionalValue].filter(isDefined),
                      item.unit,
                      item.trackingType,
                      preferredUnitsByType[item.trackingType],
                    )}
                  </Value>
                )}
                {isNotificationItem(item) && <Value>{getNotifcationMessage(item, index)}</Value>}
                {isOnboardingItem(item) && <Value>{item.message}</Value>}
              </Card>
            ),
          }
        })}
      />
      <div ref={lastElementRef} />
      {!isLoading && activity.length === 0 && <NoData />}
      {isLoading && <Spinner />}
    </Wrap>
  )
}
