import {
  EnableTwoFactorBody,
  EpicReconcileBody,
  EpicReconcileResponse,
  ImportEpicPatientBody,
  SigninBody,
} from '@vetahealth/tuna-can-api'

export enum AuthStep {
  SIGNIN = 'signin',
  TWO_FACTOR_SIGNIN = 'twoFactorSignin',
  TWO_FACTOR_RESET_PASSWORD = 'twoFactorResetPassword',
  FORGOT_PASSWORD = 'forgotPassword',
  RESET_PASSWORD = 'resetPassword',
}

export enum SigninType {
  SSO = 'SSO',
  CREDENTIALS = 'CREDENTIALS',
}

export type AuthState = {
  step: AuthStep
  setStep: (step: AuthStep) => void
  resetPasswordUserName?: string
  isAuthorized: boolean
  isInitialized: boolean
  isPendingEpicLaunch: boolean
  twoFactorId?: string
  trustToken?: string
  trustChallenge?: string
  epicImportData?: {
    patientId?: string
    patient: EpicReconcileResponse['patientData']
    importBody: ImportEpicPatientBody
  }
  handleSignin: (signinData: SigninBody) => Promise<void>
  handleEpicLaunch: (epicData: EpicReconcileBody) => Promise<void>
  handleTwoFactorSignin: (code: string) => Promise<void>
  handleSignout: () => void
  handleForgotPassword: (userName: string) => Promise<void>
  getTrustToken: (getTrustTokenData: { email: string; code: string }) => Promise<void>
  handleResetPassword: (resetPasswordData: { resetCode: string; newPassword: string }) => Promise<void>
  handleChangePassword: (changePasswordData: {
    password: string
    newPassword: string
    onSuccess: () => void
  }) => Promise<number | undefined>
  getTwoFactorSecret: () => Promise<string | undefined>
  handleEnableTwoFactor: (enableTwoFactorData: EnableTwoFactorBody) => Promise<boolean>
  handleDisableTwoFactor: (code: string) => Promise<boolean>
  handleEpicImportOrLink: () => Promise<boolean>
  setInitialized: () => void
  setIsAuthorized: () => void
  setIsUnauthorized: () => void
  errorStatus?: number
  resetSoft: () => void
}
