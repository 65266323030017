import { SummaryIntervalEnum, ThemeEnum } from '@vetahealth/tuna-can-api'

import { SupportedLocale } from '../i18n'
import { LocalStorageKey, Tokens } from './types'

interface SummarySettings {
  interval: SummaryIntervalEnum
  isInverted: boolean
}

function userSpecificKey(localStorageKey: LocalStorageKey, userId: string): string {
  return `${localStorageKey}|${userId}`
}

export const storage = {
  getTokens: (): Tokens | undefined => {
    const tokens = localStorage.getItem(LocalStorageKey.tokens)
    if (tokens) return JSON.parse(tokens)
  },
  updateTokens: (tokens: Tokens): void => {
    localStorage.setItem(LocalStorageKey.tokens, JSON.stringify(tokens))
  },
  deleteTokens: (): void => {
    localStorage.removeItem(LocalStorageKey.tokens)
  },
  getLocale: (): SupportedLocale | undefined => {
    const locale = localStorage.getItem(LocalStorageKey.locale)
    if (locale) return locale as SupportedLocale
  },
  updateLocale: (locale: SupportedLocale): void => {
    localStorage.setItem(LocalStorageKey.locale, locale)
  },

  // TODO Remove with version 32.0 https://myvetahealth.atlassian.net/browse/PRO-1330
  getAppearance: (): ThemeEnum | undefined => {
    const appearance = localStorage.getItem(LocalStorageKey.appearance)
    if (appearance) return appearance as ThemeEnum
  },
  // TODO Remove with version 32.0 https://myvetahealth.atlassian.net/browse/PRO-1330
  getSummarySettings: (userId?: string): SummarySettings => {
    const defaultSettings = { interval: SummaryIntervalEnum.Day, isInverted: true }
    if (!userId) return defaultSettings
    const entry = localStorage.getItem(userSpecificKey(LocalStorageKey.summarySettings, userId))
    return entry ? JSON.parse(entry) : defaultSettings
  },
  // TODO Remove with version 32.0 https://myvetahealth.atlassian.net/browse/PRO-1330
  getChartVisibility: (userId?: string): boolean => {
    if (!userId) return true

    // TODO: temporary for 1-2 versions

    const chartEntry = localStorage.getItem(userSpecificKey(LocalStorageKey.chart, userId))
    const graphEntry = localStorage.getItem(userSpecificKey(LocalStorageKey.graph, userId))

    const entry = chartEntry || graphEntry
    const isVisible = entry ? Boolean(JSON.parse(entry)) : true

    storage.setChartVisibility(userId, isVisible)
    localStorage.removeItem(userSpecificKey(LocalStorageKey.graph, userId))

    return isVisible
  },
  // TODO Remove with version 32.0 https://myvetahealth.atlassian.net/browse/PRO-1330
  setChartVisibility: (userId: string, isVisible: boolean): void => {
    localStorage.setItem(userSpecificKey(LocalStorageKey.chart, userId), JSON.stringify(isVisible))
  },
}
