import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Colors } from '@vetahealth/fishing-gear/colors'

import { Select } from 'antd'

import { MappingKey } from './helpers'

interface TitleProps {
  mapping?: MappingKey
  mappingLabels: Record<MappingKey, string>
  errorCount?: number
  isSelected?: boolean
  field: string
  onClick: () => void
  onBlur: () => void
  onSelect: (key: MappingKey) => void
  availableMappings: Array<MappingKey>
}

const HeaderLabel = styled.div`
  cursor: pointer;
  min-width: 160px;
`

const HeaderSelect = styled(Select)`
  min-width: 160px;
  width: 100%;
  border: none;
`

const ColumnState = styled.div<{ $hasError?: boolean }>`
  color: ${({ $hasError }) => ($hasError ? Colors.ruby600 : Colors.gray600)};
  font-style: italic;
`

const Strong = styled.div`
  font-weight: bold;
`

const Pale = styled.div`
  color: ${Colors.gray600};
`

export default function TableHead({
  mapping,
  mappingLabels,
  errorCount = 0,
  field,
  isSelected,
  availableMappings,
  onClick,
  onBlur,
  onSelect,
}: TitleProps): JSX.Element {
  const { t } = useTranslation()
  if (isSelected) {
    return (
      <HeaderSelect
        optionLabelProp="label"
        value={mapping ?? field}
        bordered={false}
        defaultOpen
        autoFocus
        onDropdownVisibleChange={onBlur}
        onSelect={onSelect}
      >
        {mapping && mapping !== MappingKey.customData && (
          <Select.Option value={mapping} label={mappingLabels[mapping]}>
            {mappingLabels[mapping]}
          </Select.Option>
        )}
        {availableMappings.map((mappingKey) => (
          <Select.Option key={mappingKey} value={mappingKey} label={mappingLabels[mappingKey]}>
            {mappingLabels[mappingKey]}
          </Select.Option>
        ))}
        <Select.Option value="<ignore>" label="ignore">
          <ColumnState>{t('widgets.csvImport.ignore')}</ColumnState>
        </Select.Option>
      </HeaderSelect>
    )
  }
  if (mapping) {
    return (
      <HeaderLabel onClick={onClick}>
        <Strong>{mappingLabels[mapping]}</Strong>
        <ColumnState $hasError={errorCount > 0}>
          {errorCount > 0 ? t('widgets.csvImport.countErrors', { count: errorCount }) : t('widgets.csvImport.ok')}
        </ColumnState>
      </HeaderLabel>
    )
  }

  return (
    <HeaderLabel onClick={onClick}>
      <Pale>{field}</Pale>
      <ColumnState>{t('widgets.csvImport.ignored')}</ColumnState>
    </HeaderLabel>
  )
}
