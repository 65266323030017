import isEmpty from 'lodash/isEmpty'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

import { CreateNoteBody, Note } from '@vetahealth/tuna-can-api'

import { message } from 'antd'

import { API } from '../../lib/api'
import { omitEqualValues } from '../../lib/normalizers'
import { takeLeading } from '../../lib/takeLeading'
import { normalizeNoteValues } from './helpers'
import { NotesState } from './types'

const initialState: Pick<NotesState, 'notes'> = {
  notes: [],
}

export const useNotesStore = createWithEqualityFn<NotesState>(
  (set, get) => ({
    ...initialState,
    getNotes: takeLeading(async (id) => {
      const notes = await API.getNotes(id)

      if (notes) set({ notes })
    }),
    createNote: async ({ id, values, isEscalation }) => {
      const newNote = await API.createNote({
        id,
        values: normalizeNoteValues(values) as CreateNoteBody,
        escalation: isEscalation,
      })

      if (newNote) set(({ notes }) => ({ notes: [...notes, newNote] }))

      return !!newNote
    },
    updateNote: async ({ id, noteId, values }) => {
      const { notes } = get()
      const note = notes.find(({ id }) => noteId === id) as Note
      const normalizedValues = normalizeNoteValues(values)
      const valuesToUpdate = omitEqualValues(note, normalizedValues)

      if (isEmpty(valuesToUpdate)) {
        message.info('Nothing to update')
        return false
      }

      const updatedNote = await API.updateNote({ id, noteId, values: normalizedValues })

      if (updatedNote) {
        const noteIndex = notes.findIndex((note) => note.id === noteId)

        set({
          notes: [...notes.slice(0, noteIndex), updatedNote, ...notes.slice(noteIndex + 1)],
        })

        return true
      }
    },
    deleteNote: async ({ id, noteId }) => {
      const success = await API.deleteNote({ id, noteId })

      if (success) {
        set(({ notes }) => ({ notes: notes.filter((note) => note.id !== noteId) }))

        return true
      }
    },
    enhanceNote: async ({ id, values }) => {
      return await API.enhanceNote({ id, values })
    },
    reset: () => set(initialState),
  }),
  shallow,
)
