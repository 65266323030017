import { produce } from 'immer'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { MutedEnum } from '@vetahealth/tuna-can-api'

import { Radio, Switch, message } from 'antd'

import { PermissionName } from '@vetahealth/fishing-gear/permissions'
import { useLoading } from '../../../../lib/hooks/useLoading'
import { TrackingEvent, trackEvent } from '../../../../lib/tracking'
import { usePatientStore } from '../../../../stores/patient'
import { useUserStore } from '../../../../stores/user'
import { LabelWithDescription } from '../../../Forms/styles'

const SwitchWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
`

export const Pause = (): JSX.Element => {
  const [loading, withLoading] = useLoading()
  const { t } = useTranslation()
  const [hasPermission] = useUserStore((state) => [state.hasPermission])
  const [patient, updatePatient] = usePatientStore((state) => [state.patient, state.updatePatient])

  const muted = patient?.preferences.notifications.muted
  const isMuted = muted !== MutedEnum.None

  async function toggleMuted(isChecked: boolean): Promise<void> {
    if (patient) {
      const success = await withLoading(
        updatePatient(
          patient,
          produce(patient, (draft) => {
            const value = isChecked ? MutedEnum.UntilNextMeasurement : MutedEnum.None
            draft.preferences.notifications.muted = value
            trackEvent(TrackingEvent.demographicsMuteNotificationsClicked, { mode: value })
          }),
        ),
      )
      if (success) message.success({ content: t('message.patientUpdate') })
    }
  }

  async function handleMute(muteState: MutedEnum): Promise<void> {
    if (patient) {
      const success = await updatePatient(
        patient,
        produce(patient, (draft) => {
          draft.preferences.notifications.muted = muteState
        }),
      )
      if (success) message.success({ content: t('message.patientUpdate') })
    }
  }

  return (
    <>
      <LabelWithDescription label={t('form.pauseProgram')} description={t('form.pauseProgramDescription')} />
      <SwitchWrapper>
        <Switch checked={isMuted} onChange={toggleMuted} loading={loading} />
        <Radio.Group
          options={[
            { value: MutedEnum.UntilNextMeasurement, label: t('form.untilNextMeasurement') },
            ...(hasPermission(PermissionName.mutePermanently, patient?.site)
              ? [
                  {
                    value: MutedEnum.Permanent,
                    label: t('form.permanently'),
                  },
                ]
              : []),
          ]}
          value={muted}
          onChange={({ target }) => target.value && handleMute(target.value)}
          optionType="button"
          buttonStyle="solid"
          disabled={!isMuted}
        />
      </SwitchWrapper>
    </>
  )
}
