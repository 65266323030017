import i18next from 'i18next'

import { Colors } from '@vetahealth/fishing-gear/colors'
import {
  convert,
  defaultUnits,
  getDisplayValue,
  normalize,
  normalizedUnitRanges,
  normalizedUnits,
} from '@vetahealth/fishing-gear/conversions'
import { CardDetails } from '@vetahealth/tuna-can-api'

import { LineChart } from '../Chart/LineChart'
import { createAlertField, createDeviceField } from '../Table/helpers'
import { GetResultsConfigParams, ResultsConfig } from './types'

export function getBloodGlucoseConfig({ userUnits }: GetResultsConfigParams): ResultsConfig {
  const unit = userUnits.bloodGlucose || defaultUnits.bloodGlucose

  const [min, max] = normalizedUnitRanges.bloodGlucose
  const [convertedMin] = convert(min, normalizedUnits.bloodGlucose, 'bloodGlucose', unit)
  const [convertedMax] = convert(max, normalizedUnits.bloodGlucose, 'bloodGlucose', unit)

  return {
    key: 'bloodGlucose',
    title: i18next.t('tracking.bloodGlucose.title'),
    category: 'tracking',
    unit,
    format: (event, targetUnit, omitUnit) =>
      event.value !== undefined
        ? getDisplayValue(
            [event.value, event.additionalValue ?? 0],
            event.unit ?? normalizedUnits.bloodGlucose,
            'bloodGlucose',
            { targetUnit, omitUnit },
          )
        : '–',
    actions: {
      add: [
        {
          key: 'value',
          label: i18next.t('tracking.bloodGlucose.bloodGlucose', { unit }),
          validators: [
            { required: true, message: i18next.t('validations.enterBloodGlucose') },
            {
              min,
              max,
              type: 'number',
              transform: (value) => normalize('bloodGlucose', Number(value), unit)[0],
              message: i18next.t('validations.trackingRange', {
                min: convertedMin,
                max: convertedMax,
                unit,
              }),
            },
          ],
        },
      ],
      reviewBulk: true,
    },
    chart: {
      component: LineChart,
      intervals: ['day', 'week', 'month', 'trend'],
      dataKeys: [{ key: 'value', color: Colors.azure500 }],
      getData: (data: CardDetails[]) =>
        data.map((event) => ({
          ...event,
          value: convert(event.value, event.unit, 'bloodGlucose', unit)[0],
        })),
    },
    table: {
      defaultFields: true,
      additionalFields: [createAlertField(), createDeviceField()],
      actions: ['review', 'delete'],
    },
  }
}
