import { faClockRotateLeft } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import groupBy from 'lodash/groupBy'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { CareStateEnum } from '@vetahealth/tuna-can-api'

import { Button, Select, Space, Tooltip, message } from 'antd'
import { BaseOptionType } from 'antd/es/select'

import { usePatientStore } from '../../../../stores/patient'
import { getCareStateCategoryName, getCareStateNames } from '../../helpers'
import { CareStateCategoryEnum } from '../../types'
import { ConsentModal } from './ConsentModal'
import { HistoryModal } from './HistoryModal'

const Wrap = styled.div`
  display: flex;
  .ant-select.ant-select-single.ant-select-sm .ant-btn-default.ant-btn-sm {
    height: 26px;
  }
  .ant-select .ant-select-selection-item {
    font-weight: 500;
  }
`

type CareStateValues = {
  careState: CareStateEnum
}

const isCareStateCategory = (state: string): state is CareStateCategoryEnum => {
  return Object.values<string>(CareStateCategoryEnum).includes(state)
}

export function CareState(): JSX.Element {
  const { t } = useTranslation()
  const [patient, setCareState, consent, getConsent] = usePatientStore((state) => [
    state.patient,
    state.setCareState,
    state.consent,
    state.getConsent,
  ])
  const [isCareStateInitialized, setCareStateInitialization] = useState<boolean>(false)
  const [isConsentVisible, setIsConsentVisible] = useState<boolean>(false)
  const [isHistoryVisible, setIsHistoryVisible] = useState<boolean>(false)
  const availableCareStates = patient?.program?.availableCareStates || []
  const careStateCategoryNames = getCareStateCategoryName()
  const careStateNames = getCareStateNames()

  const getSelectOptions = (availableCareStates: CareStateEnum[]): BaseOptionType[] => {
    const groupedCareStates = groupBy(availableCareStates, (state) => state.split('_')[0])
    return Object.entries(groupedCareStates).map(([key, values]) => {
      if (isCareStateCategory(key)) {
        return {
          label: careStateCategoryNames[key],
          options: values.map((value) => ({
            value,
            label: careStateNames[value],
          })),
        }
      }

      return {
        value: key,
        label: careStateNames[values[0]],
      }
    })
  }

  const renderOption = (option: BaseOptionType): React.ReactNode => {
    const careStateCategories = Object.values(careStateCategoryNames)
    if (careStateCategories.some((category) => option.label.startsWith(category)) && option.label.includes('-'))
      return option.label.split('-')[1]
    return option.label
  }

  const updatePatientState = useCallback(
    async (values: CareStateValues) => {
      if (patient) {
        const success = await setCareState(values)

        if (success) {
          message.success(t('message.careStateUpdate'))
        }
      }
    },
    [patient],
  )

  const handleSetCareState = useCallback(
    (careState: CareStateEnum) => {
      // No update as consent form needs to be filled
      if (careState === CareStateEnum.Consented && !consent) {
        setIsConsentVisible(true)
        return
      }

      updatePatientState({ careState })
    },
    [patient, consent],
  )

  const handleConsented = (): void => {
    updatePatientState({ careState: CareStateEnum.Consented })
    setIsConsentVisible(false)
  }

  useEffect(() => {
    if (!patient?.id) return
    getConsent().finally(() => setCareStateInitialization(true))
  }, [patient?.id])

  useEffect(() => {
    if (isCareStateInitialized && patient?.careState?.name === CareStateEnum.Consented && !consent) {
      setIsConsentVisible(true)
    }
  }, [patient?.careState?.name, consent])

  return (
    <Wrap>
      <Tooltip title={!patient?.program ? t('careState.notAvailable') : undefined}>
        <Space.Compact>
          <Select
            disabled={!patient?.program}
            optionFilterProp="children"
            optionLabelProp="label"
            optionRender={renderOption}
            options={getSelectOptions(availableCareStates)}
            onSelect={handleSetCareState}
            value={patient?.careState?.name}
            placeholder={t('placeholders.careState')}
            size="small"
            popupMatchSelectWidth={false}
          />
          {patient?.careState?.name === CareStateEnum.Consented && (
            <Button size="small" onClick={() => setIsConsentVisible(true)}>
              {t('widgets.patientEdit.showOnboardingConsent')}
            </Button>
          )}
          <Tooltip title={t('careState.history')}>
            <Button
              size="small"
              icon={<FontAwesomeIcon icon={faClockRotateLeft} size="sm" />}
              onClick={() => setIsHistoryVisible(true)}
              disabled={!patient?.program || (patient.program && !patient?.careState)}
            />
          </Tooltip>
        </Space.Compact>
      </Tooltip>
      <ConsentModal
        isVisible={isConsentVisible}
        onCancel={() => setIsConsentVisible(false)}
        onConsent={handleConsented}
      />
      <HistoryModal isOpen={isHistoryVisible} onCancel={() => setIsHistoryVisible(false)} />
    </Wrap>
  )
}
