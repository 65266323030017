import dayjs, { Dayjs } from 'dayjs'
import i18next from 'i18next'
import groupBy from 'lodash/groupBy'
import meanBy from 'lodash/meanBy'
import range from 'lodash/range'
import { DomainTuple } from 'victory-core'

import { getDisplayValue, sleepShiftHour } from '@vetahealth/fishing-gear/conversions'

import { ResultsState, isAlertUnreviewed } from '../../../../../stores/results'
import {
  AggregationInterval,
  ChartData,
  ChartItem,
  ChartItems,
  ChartType,
  DataKey,
  GetSleepDisplayLabel,
  IntervalDates,
  IntervalType,
} from './types'

function getDailyAverage(data: ChartItem[]): ChartItem[] {
  return Object.values(groupBy(data, 'x')).map((perDay) => ({
    ...perDay[0],
    y: meanBy(perDay, 'y'),
    ids: perDay.flatMap(({ ids }) => ids),
  }))
}

function getAggregated(data: ChartItem[]): ChartItem[] {
  return data.reduce<ChartItem[]>((aggregation, current, index) => {
    const lastItem: ChartItem = aggregation[aggregation.length - 1]
    if (index === 0) return [current]

    if (lastItem && lastItem.x === current.x) {
      lastItem.y += current.y
      lastItem.ids = [...lastItem.ids, ...current.ids]
      return aggregation
    }

    return aggregation.concat(current)
  }, [])
}

export function getSleepDisplayLabel({
  x,
  y0,
  y,
  timeZone,
}: {
  x: number
  y0: number
  y: number
  timeZone?: string
}): GetSleepDisplayLabel {
  const value = y - y0

  const timeFormat = i18next.t('dateFormats.time')
  const timestamp = dayjs(x).subtract(sleepShiftHour, 'hour').add(y0, 'minute')
  const start = dayjs(x).subtract(sleepShiftHour, 'hour').add(y0, 'minute').format(timeFormat)
  const end = dayjs(x).subtract(sleepShiftHour, 'hour').add(y, 'minute').format(timeFormat)

  const timeZoneLabel = dayjs(x).tz(timeZone).format('z')
  const sleepAmount = getDisplayValue(value, 'min', 'sleep', 'h_min')
  return { label: sleepAmount, subLabel: `${start} - ${end} ${timeZoneLabel}`, timestamp }
}

type Ticks = {
  ticks: number[]
  formatter: (value: number) => string
}

export function getAggregationDisplayLabel(value: number, aggregationInterval: string): string {
  switch (aggregationInterval) {
    case 'day':
      return dayjs(value).format(i18next.t('dateFormats.dayAndMonthAndYear'))
    case 'week':
      return `
      ${dayjs(value).format('DD')} - ${dayjs(value).add(6, 'days').format(i18next.t('dateFormats.dayAndMonthAndYear'))}
      `
    case 'month':
      return dayjs(value).format(i18next.t('dateFormats.monthAndYear'))
    case 'year':
      return dayjs(value).format('YYYY')
    default:
      return dayjs(value).format('ll')
  }
}

function getTicks(intervalStart: Dayjs, intervalEnd: Dayjs, intervalType: IntervalType, chartType: ChartType): Ticks {
  let ticks: Dayjs[] = []
  let formatter: (value: number) => string
  const intervalDayDiff = intervalEnd.startOf('day').diff(intervalStart.startOf('day'), 'day')
  const intervalMonthDiff = intervalEnd.startOf('month').diff(intervalStart.startOf('month'), 'month')
  const intervalYearDiff = intervalEnd.startOf('year').diff(intervalStart.startOf('year'), 'year')
  const isAllTimeOrTrend = ['all', 'trend'].includes(intervalType)

  if (intervalDayDiff === 0 && chartType === 'line') {
    // day
    formatter = (value) => dayjs(value).format('LT')
    ticks = [...range(6).map((index) => intervalStart.add(index * 4, 'hours')), intervalEnd]
  } else if (intervalDayDiff < 8) {
    // week
    if (isAllTimeOrTrend) {
      formatter = (value) => dayjs(value).format('D')
      ticks = [
        ...range(intervalDayDiff + 1).map((index) => intervalStart.add(index, 'day')),
        intervalEnd.startOf('day').add(1, 'day'),
      ]
    } else {
      formatter = (value) => dayjs(value).format('dd')
      ticks = range(7).map((index) => intervalStart.add(index, 'days'))
    }
  } else if (intervalDayDiff < 32) {
    // month
    formatter = (value) => dayjs(value).format('D')
    if (isAllTimeOrTrend) {
      ticks = [...range(intervalDayDiff).map((index) => intervalStart.add(index, 'day')), intervalEnd.startOf('day')]
    } else {
      ticks = [
        ...range(6).map((index) => intervalStart.add(index * 5, 'days').subtract(index ? 1 : 0, 'days')),
        intervalEnd,
      ]
    }
  } else if (intervalDayDiff < 517) {
    // 16 month
    formatter = (value) => dayjs(value).format('MMM')
    ticks = [
      ...range(intervalMonthDiff + 1).map((index) => intervalStart.startOf('month').add(index, 'month')),
      intervalEnd.startOf('month').add(1, 'month'),
    ]
  } else {
    // years
    formatter = (value) => dayjs(value).format('YYYY')
    ticks = [
      ...range(intervalYearDiff + 1).map((index) => intervalStart.startOf('year').add(index, 'year')),
      intervalEnd.startOf('year').add(1, 'year'),
    ]
  }

  return {
    ticks: ticks.map((tick) => tick.valueOf()),
    formatter,
  }
}

type IntervalData = {
  intervalData: ChartItems
  y: { interval: DomainTuple; all: DomainTuple }
  aggregationInterval: AggregationInterval
  isAveragedPerDay?: boolean
} & Ticks

type SleepIntervalData = Omit<IntervalData, 'y'>

type GetIntervalDataParams = {
  data: ChartData[]
  dataKeys: DataKey[]
  intervalDates: IntervalDates
  intervalType: IntervalType
}

export function getAggregationLabel(
  aggregationInterval: AggregationInterval,
  isAveragedPerDay?: boolean,
): string | undefined {
  const translations: Partial<Record<AggregationInterval, string>> = {
    week: i18next.t('dateFormats.per.week'),
    month: i18next.t('dateFormats.per.month'),
    year: i18next.t('dateFormats.per.year'),
  }
  if (isAveragedPerDay) return i18next.t('widgets.results.averagedPerDay')
  return translations[aggregationInterval]
}

export function getIntervalDates({
  intervalType,
  intervalDates,
  offset = 0,
  minDate,
  maxDate,
}: {
  intervalType: IntervalType
  intervalDates: IntervalDates
  offset?: number
  minDate: Dayjs
  maxDate: Dayjs
}): IntervalDates {
  if (intervalType === 'all' || intervalType === 'trend') {
    return [minDate, maxDate]
  }

  if (!offset) {
    const today = dayjs()
    const start = intervalDates[1].startOf(intervalType)
    const end = intervalDates[1].endOf(intervalType)

    if (end.isAfter(today.endOf(intervalType))) {
      return [today.startOf(intervalType), today.endOf(intervalType)]
    }

    return [start, end]
  }

  const shiftedDate = intervalDates[0].subtract(offset, intervalType)

  return [shiftedDate.startOf(intervalType), shiftedDate.endOf(intervalType)]
}

export function getLineIntervalData({
  data,
  dataKeys,
  intervalDates,
  intervalType,
}: GetIntervalDataParams): IntervalData {
  const intervalStart = intervalDates[0].startOf('day')
  const intervalEnd = intervalDates[1].endOf('day')
  const intervalData: ChartItems = {}
  const ys: { interval: number[]; all: number[] } = { interval: [], all: [] }

  const hasMoreThanOneMeasurementPerDay = data.some((datum, index) => {
    if (!index) return false
    return dayjs(datum.timestamp).isSame(data[index - 1].timestamp, 'day')
  })

  const intervalDiff = intervalEnd.diff(intervalStart, 'days')
  const isAveragedPerDay =
    (hasMoreThanOneMeasurementPerDay && intervalType === 'month') ||
    (hasMoreThanOneMeasurementPerDay && ['trend', 'all'].includes(intervalType) && intervalDiff > 7)
  const aggregationInterval: AggregationInterval = 'day'

  dataKeys.forEach(({ key }, index) => {
    data.forEach((item) => {
      const timestamp = dayjs(item.timestamp)
      const x = isAveragedPerDay ? timestamp.startOf(aggregationInterval).valueOf() : timestamp.valueOf()
      const y = Number(item[key])
      const ids = item.id ? [item.id] : []

      ys.all.push(y)

      if (timestamp.isAfter(intervalStart) && timestamp.isBefore(intervalEnd)) {
        intervalData[index] = [...(intervalData[index] || []), { x, y, ids }]

        // TODO: this would need to be re-calculated if sampled or averaged
        ys.interval.push(y)
      }
    })

    if (intervalData[index] && isAveragedPerDay) {
      intervalData[index] = getDailyAverage(intervalData[index])
    }
  })

  const y: IntervalData['y'] = {
    interval: [Math.min(...ys.interval), Math.max(...ys.interval)],
    all: [Math.min(...ys.all), Math.max(...ys.all)],
  }

  const { ticks, formatter } = getTicks(intervalStart, intervalEnd, intervalType, 'line')

  return {
    intervalData,
    ticks,
    formatter,
    y,
    aggregationInterval,
    isAveragedPerDay,
  }
}

export function getBarIntervalData({
  data,
  dataKeys,
  intervalDates,
  intervalType,
}: GetIntervalDataParams): IntervalData {
  const intervalStart = intervalDates[0].startOf('day')
  const intervalEnd = intervalDates[1].endOf('day')
  const intervalData: ChartItems = {}
  const ys: { interval: number[]; all: number[] } = { interval: [], all: [] }
  const intervalDiff = intervalEnd.diff(intervalStart, 'days')

  let aggregationInterval: AggregationInterval = 'day'
  if (intervalDiff > 45) aggregationInterval = 'week' // > 1.5 months
  if (intervalDiff > 180) aggregationInterval = 'month' // > 6 months
  if (intervalDiff > 1080) aggregationInterval = 'year' // > 3 years

  dataKeys.forEach(({ key }, index) => {
    data.forEach((item) => {
      const timestamp = dayjs(item.timestamp)
      const x = timestamp.startOf(aggregationInterval).valueOf()
      const y = Number(item[key])
      const ids = item.id ? [item.id] : []

      ys.all.push(y)

      if (timestamp.isAfter(intervalStart) && timestamp.isBefore(intervalEnd)) {
        intervalData[index] = [...(intervalData[index] || []), { x, y, ids }]

        ys.interval.push(y)
      }
    })
    if (intervalData[index]) {
      intervalData[index] = getAggregated(intervalData[index])
    }
  })

  const y: IntervalData['y'] = {
    interval: [Math.min(...ys.interval), Math.max(...ys.interval)],
    all: [Math.min(...ys.all), Math.max(...ys.all)],
  }

  const { ticks, formatter } = getTicks(intervalStart, intervalEnd, intervalType, 'bar')

  return { intervalData, ticks, formatter, y, aggregationInterval }
}

export function getSleepIntervalData({
  data,
  dataKeys,
  intervalDates,
  intervalType,
}: GetIntervalDataParams): SleepIntervalData {
  const intervalStart = intervalDates[0].startOf('day')
  const intervalEnd = intervalDates[1].endOf('day')
  const intervalData: ChartItems = {}

  const aggregationInterval: AggregationInterval = 'day'

  dataKeys.forEach(({ key }, index) => {
    data.forEach((item) => {
      const timestamp = dayjs(item.timestamp)
      const start = dayjs(timestamp).hour() * 60 + dayjs(timestamp).minute()
      const end = start + Number(item[key])

      const x = timestamp.startOf(aggregationInterval).valueOf()
      const y0 = start
      const y = end
      const ids = item.id ? [item.id] : []

      if (timestamp.isAfter(intervalStart) && timestamp.isBefore(intervalEnd)) {
        intervalData[index] = [...(intervalData[index] || []), { x, y, y0, ids }]
      }
    })
  })

  const { ticks, formatter } = getTicks(intervalStart, intervalEnd, intervalType, 'sleep')

  return { intervalData, ticks, formatter, aggregationInterval }
}

export function hasUnreviewedAlerts(alerts: ResultsState['alerts'], ids: number[]): boolean {
  return (
    ids.some(
      (id) =>
        alerts?.tracking?.[id]?.some(isAlertUnreviewed) ||
        alerts?.task?.[id]?.some(isAlertUnreviewed) ||
        alerts?.riskScore?.[id]?.some(isAlertUnreviewed),
    ) ?? false
  )
}
