import React, { useMemo } from 'react'

import { CareTask as ICareTask, CareTaskTemplate as ICareTaskTemplate } from '@vetahealth/tuna-can-api'

import { CareTask } from './CareTask'
import { CareTaskTemplate } from './CareTaskTemplate'
import { CareTaskTemplateWizard } from './CareTaskTemplateWizard'
import { CareTaskViewer } from './CareTaskViewer'
import { isCareTask, isCareTaskTemplateWitId } from './helpers'

export function CareTasks({
  careTasks,
  onUpdate,
  onDelete,
}: {
  careTasks: ICareTask[] | ICareTaskTemplate[] | undefined
  onUpdate?: (index: number, updatedCareTaskTemplate: ICareTaskTemplate) => Promise<boolean>
  onDelete?: (index: number) => Promise<boolean>
}): JSX.Element | null {
  if (!careTasks) return null
  const careTasksWithId = useMemo(() => {
    return careTasks.map((task, index) => ({ ...task, id: 'id' in task ? task.id : index }))
  }, [careTasks])
  return (
    <>
      {careTasksWithId?.map((careTask) => {
        if (isCareTask(careTask)) {
          return (
            <CareTaskViewer
              key={careTask.id}
              careTask={careTask}
              render={(handleOpenCareTask, handleCompleteCareTask, isCompleted) => (
                <CareTask
                  title={careTask.title}
                  isCompleted={isCompleted}
                  onComplete={handleCompleteCareTask}
                  onOpen={handleOpenCareTask}
                />
              )}
            />
          )
        }
        if (isCareTaskTemplateWitId(careTask)) {
          return (
            <CareTaskTemplateWizard
              key={careTask.id}
              onUpdate={onUpdate}
              onDelete={onDelete}
              editCareTask={careTask}
              render={(handleOpenCareTaskTemplateWizard) => (
                <CareTaskTemplate careTaskTemplate={careTask} onOpen={handleOpenCareTaskTemplateWizard} />
              )}
            />
          )
        }
      })}
    </>
  )
}
