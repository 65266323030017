import { DefaultTheme } from 'styled-components'

import { Colors } from '@vetahealth/fishing-gear/colors'

import { ThemeEnum } from '@vetahealth/tuna-can-api'
import { theme } from 'antd'
import { ThemeConfig } from 'antd/lib/config-provider/context'

interface Theme {
  antTheme: ThemeConfig
  customTheme: DefaultTheme
}

const antLight = {
  colorPrimary: Colors.mauve600,
  colorPrimaryActive: Colors.mauve800,
  colorPrimaryHover: Colors.mauve700,
  colorLink: Colors.mauve600,
  colorLinkActive: Colors.mauve800,
  colorLinkHover: Colors.mauve700,
  colorError: Colors.ruby600,
  colorSuccess: Colors.green500,
  colorWarning: Colors.apricot500,
  fontFamily: '"Montserrat", Helvetica, Arial, sans-serif',
}

const antDark = {
  colorBgContainer: Colors.anthrazit575,
  colorPrimary: Colors.mauve500,
  colorPrimaryActive: Colors.mauve400,
  colorPrimaryHover: Colors.mauve500,
  colorLink: Colors.mauve500,
  colorLinkActive: Colors.mauve300,
  colorLinkHover: Colors.mauve400,
  colorError: Colors.ruby500,
  colorSuccess: Colors.green500,
  colorWarning: Colors.apricot400,
  fontFamily: '"Montserrat", Helvetica, Arial, sans-serif',
}

const vetaLight: DefaultTheme = {
  logo: Colors.black,
  error: Colors.ruby600,
  primary: Colors.mauve600,
  primaryBackground: Colors.gray100,
  secondaryBackground: Colors.gray75,
  tertiaryBackground: Colors.gray150,
  quaternaryBackground: Colors.anthrazit500,
  quinaryBackground: Colors.white,
  loginBackground: Colors.mint150,
  badgeActive: Colors.mauve200,
  badgeBackground: Colors.mauve100,
  badgeText: Colors.mauve700,
  chatHover: Colors.gray50,
  chatNew: Colors.mauve100,
  chatAvatarBackground: Colors.gray100,
  chatAvatarSecondary: Colors.gray600,
  chatAvatarTertiary: Colors.teal500,
  trackingHover: Colors.azure100,
  trackingBackground: Colors.azure75,
  trackingActive: Colors.azure500,
  trackingBorder: Colors.azure300,
  trackingBorderHover: Colors.azure400,
  noteBackground: Colors.canary50,
  noteBorder: Colors.canary200,
  noteHover: Colors.canary75,
  careTaskBackgroundPrimary: Colors.mauve600,
  careTaskBackgroundSecondary: Colors.mauve150,
  taskHover: Colors.plum100,
  taskBackground: Colors.plum75,
  taskActive: Colors.plum500,
  taskBorder: Colors.plum300,
  taskBorderHover: Colors.plum400,
  text: Colors.anthrazit600,
  textInverted: Colors.white,
  toolTipBackground: Colors.anthrazit500,
  statsActive: Colors.ink400,
  statsHover: Colors.ink300,
  concerningBackground: Colors.ruby150,
  concerningActive: Colors.ruby650,
  concerningCurrent: Colors.ruby650,
  concerningBorder: Colors.ruby300,
  concerningHover: Colors.ruby400,
  concerningIcon: Colors.ruby650,
  alertActive: Colors.ruby700,
  alertBackground: Colors.ruby75,
  alertBackgroundHover: Colors.ruby100,
  tagNeutral: Colors.gray300,
  tagArchived: Colors.anthrazit500,
  tagCreated: Colors.apricot400,
  tagSubmitted: Colors.canary400,
  tagFullfilled: Colors.azure600,
  tagDelivered: Colors.mint500,
  tagReturned: Colors.ruby500,
  tagAssigned: Colors.ink600,
  tagSuccess: Colors.green500,
  tagViewSelector: Colors.ink900,
  tableBackground: Colors.white,
  tableBackgroundConcerning: Colors.ruby150,
  tableBackgroundConcerningHover: Colors.ruby100,
  tableConcerning: Colors.ruby700,
  tableBackgroundNonConcerning: Colors.azure100,
  tableBackgroundNoValue: Colors.azure60,
  reminderBackground: Colors.apricot75,
  reminderActive: Colors.apricot500,
  deviceBackground: Colors.mint50,
  deviceBorder: Colors.mint200,
  chartGridLines: Colors.gray300,
}

const vetaDark: DefaultTheme = {
  logo: Colors.white,
  error: Colors.ruby700,
  primary: Colors.mauve500,
  primaryBackground: Colors.anthrazit700,
  secondaryBackground: Colors.gray900,
  tertiaryBackground: Colors.gray700,
  quaternaryBackground: Colors.gray200,
  quinaryBackground: Colors.anthrazit550,
  loginBackground: Colors.anthrazit700,
  badgeActive: Colors.mauve800,
  badgeBackground: Colors.mauve1000,
  badgeText: Colors.mauve50,
  chatHover: Colors.anthrazit500,
  chatNew: Colors.mauve1000,
  chatAvatarBackground: Colors.gray900,
  chatAvatarSecondary: Colors.gray500,
  chatAvatarTertiary: Colors.teal600,
  trackingHover: Colors.azure900,
  trackingBackground: Colors.azure1000,
  trackingActive: Colors.azure500,
  trackingBorder: Colors.azure800,
  trackingBorderHover: Colors.azure700,
  noteBackground: Colors.canary900,
  noteBorder: Colors.canary700,
  noteHover: Colors.canary900,
  careTaskBackgroundPrimary: Colors.mauve800,
  careTaskBackgroundSecondary: Colors.mauve1000,
  taskHover: Colors.plum900,
  taskBackground: Colors.plum1000,
  taskActive: Colors.plum500,
  taskBorder: Colors.plum700,
  taskBorderHover: Colors.plum600,
  text: Colors.gray50,
  textInverted: Colors.gray50,
  toolTipBackground: Colors.gray800,
  statsActive: Colors.ink400,
  statsHover: Colors.ink600,
  concerningBackground: Colors.ruby700,
  concerningActive: Colors.ruby400,
  concerningCurrent: Colors.canary200,
  concerningBorder: Colors.ruby700,
  concerningHover: Colors.ruby500,
  concerningIcon: Colors.white,
  alertActive: Colors.ruby200,
  alertBackground: Colors.ruby1000,
  alertBackgroundHover: Colors.ruby900,
  tagNeutral: Colors.gray700,
  tagArchived: Colors.anthrazit575,
  tagCreated: Colors.apricot800,
  tagSubmitted: Colors.canary600,
  tagFullfilled: Colors.azure800,
  tagDelivered: Colors.mint700,
  tagReturned: Colors.ruby500,
  tagAssigned: Colors.mauve800,
  tagSuccess: Colors.green700,
  tagViewSelector: Colors.ink100,
  tableBackground: Colors.anthrazit575,
  tableBackgroundConcerning: Colors.ruby900,
  tableBackgroundConcerningHover: Colors.ruby1000,
  tableConcerning: Colors.white,
  tableBackgroundNonConcerning: Colors.azure1000,
  tableBackgroundNoValue: Colors.anthrazit550,
  reminderBackground: Colors.apricot1000,
  reminderActive: Colors.apricot500,
  deviceBackground: Colors.mint1000,
  deviceBorder: Colors.mint700,
  chartGridLines: Colors.gray800,
}

export const getTheme = (localeTheme?: ThemeEnum): Theme => {
  const isDark = localeTheme === 'dark'
  const antTheme = {
    token: isDark ? antDark : antLight,
    algorithm: isDark ? theme.darkAlgorithm : theme.defaultAlgorithm,
  }
  const customTheme: DefaultTheme = {
    ...(isDark ? vetaDark : vetaLight),
  }

  return {
    customTheme,
    antTheme,
  }
}
