import dayjs, { Dayjs } from 'dayjs'
import orderBy from 'lodash/orderBy'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'

import { getDisplayValue } from '@vetahealth/fishing-gear/conversions'

import { TrackingEvent, trackEvent } from '../../../../../lib/tracking'
import { usePatientStore } from '../../../../../stores/patient'
import { ResultsType, useResultsStore } from '../../../../../stores/results'
import { isTrackingType } from '../../../helpers'
import { NoData } from '../../../styles'
import { getResultsConfig } from '../config'
import { Controls } from './Controls'
import { getAggregationDisplayLabel, getIntervalDates, getSleepDisplayLabel } from './helpers'
import { GetToolTipData, IntervalDates, IntervalType, ToolTipData } from './types'

const Wrapper = styled.div`
  flex: 1;
  margin-bottom: 10px;
`
const ChartWrapper = styled.div`
  flex: 1;
`

export function Chart({ type }: { type: ResultsType }): JSX.Element | null {
  const config = getResultsConfig(type)
  const [patient] = usePatientStore((state) => [state.patient])
  const [allResults, alerts] = useResultsStore((state) => [state.allResults, state.alerts])
  const timeZone = patient?.timeZone
  const data = allResults[type] || []

  const chartData = useMemo(
    () =>
      orderBy(config?.chart?.getData(data) || [], ['timestamp'], ['asc']).map((item) => ({
        ...item,
        timestamp: dayjs(item.timestamp).tz(patient?.timeZone).format('YYYY-MM-DDTHH:mm'),
      })) ?? [],
    [data, config, patient?.timeZone],
  )

  let initialIntervalType: IntervalType = 'day'

  if (config.chart) {
    initialIntervalType = config.chart.intervals.includes('week')
      ? 'week'
      : config.chart.intervals[config.chart.intervals.length - 1]
  }

  let initialIntervalDates: IntervalDates = [dayjs(), dayjs()]
  if (chartData.length) {
    initialIntervalDates = getIntervalDates({
      intervalType: initialIntervalType,
      intervalDates: [dayjs(), dayjs()],
      minDate: dayjs(chartData[0].timestamp),
      maxDate: dayjs(chartData[chartData.length - 1].timestamp),
    })
  }

  const [intervalType, setIntervalType] = useState<IntervalType>(initialIntervalType)
  const [intervalDates, setIntervalDates] = useState<IntervalDates>(initialIntervalDates)
  const [aggregationLabel, setAggregationLabel] = useState<string | undefined>(undefined)

  function getToolTipData({
    x,
    y,
    y0 = 0,
    aggregationInterval,
    isAveragedPerDay = false,
  }: GetToolTipData): ToolTipData {
    let label: string | number = y
    let dateLabel = getAggregationDisplayLabel(x, aggregationInterval)
    let subLabel: string | undefined
    let timestamp: Dayjs | undefined
    const color = config?.chart?.dataKeys[0].color
    const value = y - y0

    if (config.unit && isTrackingType(type)) {
      if (config.key === 'sleep') {
        const sleep = getSleepDisplayLabel({ x, y, y0, timeZone })
        label = sleep.label
        subLabel = sleep.subLabel
        timestamp = sleep.timestamp
        dateLabel = dayjs(x).format('ll')
      } else {
        label = getDisplayValue(y, config.unit?.split('_')[0], type, config.unit)
        dateLabel = isAveragedPerDay
          ? dayjs(x).format('ll')
          : `${dayjs(x).format('lll')} ${dayjs(x).tz(timeZone).format('z')}`
      }
    }
    return { label, subLabel, dateLabel, color, value, timestamp }
  }

  if (!config.chart || !patient) return null

  const Chart = config.chart.component

  return (
    <Wrapper key={type}>
      {chartData.length ? (
        <>
          <Controls
            intervalType={intervalType}
            intervalDates={intervalDates}
            minDate={dayjs(chartData[0].timestamp)}
            maxDate={dayjs(chartData[chartData.length - 1].timestamp)}
            intervals={config.chart.intervals}
            aggregationLabel={aggregationLabel}
            onChange={(type, dates) => {
              trackEvent(TrackingEvent.resultChartRangeClicked, { range: type })
              setIntervalType(type)
              setIntervalDates(dates)
            }}
          />
          <ChartWrapper>
            <Chart
              data={chartData}
              dataKeys={config.chart.dataKeys}
              getToolTipData={getToolTipData}
              intervalDates={intervalDates}
              intervalType={intervalType}
              timeZone={timeZone}
              alerts={alerts}
              onChangeAggregationLabel={setAggregationLabel}
            />
          </ChartWrapper>
        </>
      ) : (
        <NoData />
      )}
    </Wrapper>
  )
}
