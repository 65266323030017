import { faPlay, faRepeat, faRotateRight } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'

import { CareTaskTemplate as ICareTaskTemplate } from '@vetahealth/tuna-can-api'
import { Tooltip } from 'antd'

import { useSitesStore } from '../../stores/sites'
import {
  getRepeatTimesTooltip,
  getRepeatTimesValue,
  getRepeatTooltip,
  getRepeatValue,
  getStartTooltip,
  getStartValue,
} from './helpers'

const Task = styled.div<{ $isClickable: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  padding: 2px 2px 2px 8px;
  font-size: 13px;
  color: white;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.careTaskBackgroundPrimary};
  ${({ $isClickable, theme }) =>
    $isClickable &&
    `
    cursor: pointer;
    &:hover {
      background-color: color-mix(in srgb, ${theme.careTaskBackgroundPrimary} 90%, white);
    }
  `}
`

const Label = styled.div``

const Timing = styled.div`
  opacity: 0.6;
`

const TimingItem = styled.div`
  min-width: 30px;
  margin-right: 8px;
  display: inline-block;
  &:last-child {
    margin-right: 0;
  }
`

const Value = styled.span`
  font-size: 13px;
  margin-left: 3px;
`

export function CareTaskTemplate({
  careTaskTemplate,
  onOpen,
}: {
  careTaskTemplate: ICareTaskTemplate
  onOpen?: () => void
}): JSX.Element | null {
  const [availableCareTasks] = useSitesStore((state) => [state.availableCareTasks])
  const title = availableCareTasks?.find((task) => task.id === careTaskTemplate.datoId)?.name

  return (
    <Task onClick={onOpen} $isClickable={!!onOpen}>
      <Label>{title}</Label>
      <Timing>
        <Tooltip title={getStartTooltip(careTaskTemplate)} overlayStyle={{ maxWidth: '500px' }}>
          <TimingItem>
            <FontAwesomeIcon icon={faPlay} size="sm" />
            <Value>{getStartValue(careTaskTemplate)}</Value>
          </TimingItem>
        </Tooltip>
        <Tooltip title={getRepeatTooltip(careTaskTemplate)}>
          <TimingItem>
            <FontAwesomeIcon icon={faRepeat} size="sm" />
            <Value>{getRepeatValue(careTaskTemplate)}</Value>
          </TimingItem>
        </Tooltip>
        <Tooltip title={getRepeatTimesTooltip(careTaskTemplate)} overlayStyle={{ maxWidth: '500px' }}>
          <TimingItem>
            <FontAwesomeIcon icon={faRotateRight} size="sm" />
            <Value>{getRepeatTimesValue(careTaskTemplate)}</Value>
          </TimingItem>
        </Tooltip>
      </Timing>
    </Task>
  )
}
