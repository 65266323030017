import { produce } from 'immer'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

import { message } from 'antd'
import dayjs from 'dayjs'
import i18next from 'i18next'
import sortBy from 'lodash/sortBy'
import { API } from '../../lib/api'
import { useUserStore } from '../user'
import { RoleState } from './types'

const dataExpirationMinutes = 5

export const useUsersStore = createWithEqualityFn<RoleState>(
  (set, get) => ({
    usersExpiration: Date.now(),
    users: [],
    getUsers: async () => {
      const { usersExpiration } = get()
      if (usersExpiration > Date.now()) return
      set({ usersExpiration: Date.now() + dayjs.duration({ minutes: dataExpirationMinutes }).asMilliseconds() })

      const users = await API.getUsers()
      if (users) {
        set({ users })
      }
    },
    createUser: async (user) => {
      const newUser = await API.createUser(user)
      const { users } = get()

      if (newUser) {
        const index = users.findIndex((user) => user.id === newUser.id)
        if (index !== -1) {
          set(
            produce<RoleState>((state) => {
              state.users.push(newUser)
              state.users = sortBy(state.users, 'lastName')
            }),
          )
          message.success(i18next.t('message.userUpdate'))
        } else {
          set(
            produce<RoleState>((state) => {
              state.users[index] = newUser
            }),
          )
          message.success(i18next.t('message.userCreate'))
        }
      }
    },
    updateUser: async (id, user) => {
      const updatedUser = await API.updateUser(id, user)
      if (updatedUser) {
        set((prevState) => ({
          users: prevState.users.map((prevUser) => (prevUser.id === updatedUser.id ? updatedUser : prevUser)),
        }))
        const ownId = useUserStore.getState().userId
        if (updatedUser.id === ownId) await API.refreshToken()
        message.success(i18next.t('message.userUpdate'))
      }
    },
    updateUserRoles: async (id, roles) => {
      const success = await API.updateUserRoles(id, roles)
      if (success) {
        set((prevState) => ({
          users: prevState.users.map((prevUser) => (prevUser.id === id ? { ...prevUser, roles } : prevUser)),
        }))
      }
    },
  }),
  shallow,
)
