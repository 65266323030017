import invert from 'lodash/invert'

import { isDefined } from '@vetahealth/fishing-gear/lib/typeguards'
import {
  CareStateEnum,
  ConditionEnum,
  MutedEnum,
  NotificationStatusEnum,
  PatientOverviewItem,
  PatientOverviewItemWarningsEnum,
  SexEnum,
  StatusEnum,
} from '@vetahealth/tuna-can-api'

type SortOrder = 'ascend' | 'descend'

/*
  Watch out when adding new columns:
  - For compatibility reasons, you must not change the values of existing columns
  - Column values must be unique, always add at the end, after z go on with aa, ab, ac ...
    (uniqueness is checked at runtime)
 */
export const columns = {
  firstName: 'a',
  lastName: 'b',
  dateOfBirth: 'c',
  timeZone: 'd',
  mobilePhone: 'e',
  secondaryMobilePhone: 'f',
  landlinePhone: 'g',
  secondaryLandlinePhone: 'h',
  clientIdentifier: 'i',
  site: 'j',
  careState: 'k',
  //  onboardingSubState: 'l',  Removed. Do not reuse this parameter as it could still occur in old view configurations!
  careStateModifiedAt: 'm',
  tags: 'n',
  program: 'o',
  conditions: 'p',
  accountStatus: 'q',
  rpmMeasurementCount: 'r',
  rpmTotalActivityTime: 's',
  rpmLastMeasurement: 't',
  lastConcerningEvent: 'u',
  sex: 'v',
  email: 'w',
  lastMeasurementAt: 'x',
  lastInteractionNoteAt: 'y',
  devicesDeliveredAt: 'z',
  subscribers: 'aa',
  firstDeviceOrderedAt: 'ab',
  lastOnboardedAt: 'ac',
  openTasks: 'ad',
  state: 'ae',
  notificationStatus: 'af',
  diagnosisCodes: 'ag',
  provider: 'ah',
} as const

const columnValues = Object.values(columns)

const encodeSex: Record<SexEnum, string> = {
  [SexEnum.Female]: 'a',
  [SexEnum.Male]: 'b',
  [SexEnum.Other]: 'c',
}

const decodeSex = invert(encodeSex) as Record<string, SexEnum>

const encodeAccountStatus: Record<StatusEnum, string> = {
  [StatusEnum.Active]: 'a',
  [StatusEnum.Locked]: 'b',
  [StatusEnum.Inactive]: 'c',
  [StatusEnum.Archived]: 'd',
}

const decodeAccountStatus = invert(encodeAccountStatus) as Record<string, StatusEnum>

const encodeCareState: Record<CareStateEnum, string> = {
  [CareStateEnum.Eligible]: 'a',
  [CareStateEnum.Qualified]: 'b',
  [CareStateEnum.DeviceOrdered]: 'c',
  [CareStateEnum.ConsentAttempted]: 'd',
  [CareStateEnum.Consented]: 'e',
  [CareStateEnum.Onboarded]: 'f',
  [CareStateEnum.None]: 'i',
  [CareStateEnum.PendingReferral]: 'j',
  [CareStateEnum.Registered]: 'k',
  [CareStateEnum.Soc]: 'l',
  [CareStateEnum.WaitingOnDelivery]: 'm',
  [CareStateEnum.DisqualifiedBedWheelchairBound]: 'n',
  [CareStateEnum.DisqualifiedCancerDiagnosis]: 'o',
  [CareStateEnum.DisqualifiedDeceased]: 'p',
  [CareStateEnum.DisqualifiedDialysis]: 'q',
  [CareStateEnum.DisqualifiedNotMedicare]: 'r',
  [CareStateEnum.DisqualifiedOther]: 's',
  [CareStateEnum.DisqualifiedTooHighBmi]: 't',
  [CareStateEnum.NotInterestedDidntSeeTheValue]: 'u',
  [CareStateEnum.NotInterestedProviderNotInvolved]: 'v',
  [CareStateEnum.NotInterestedTooExpensive]: 'w',
  [CareStateEnum.NotInterestedTooMuchTime]: 'x',
  [CareStateEnum.MonitoringStable]: 'y',
  [CareStateEnum.MonitoringOutOfRange]: 'z',
  [CareStateEnum.MonitoringConcerningAnswers]: 'aa',
  [CareStateEnum.MonitoringDeviceTroubleshooting]: 'ab',
  [CareStateEnum.MonitoringMedicalQuestion]: 'ac',
  [CareStateEnum.NotInterestedDoesNotWantToParticipate]: 'ad',
  [CareStateEnum.NotInterestedOverwhelmed]: 'ae',
  [CareStateEnum.DisqualifiedManagedBySpecialist]: 'af',
  [CareStateEnum.DisqualifiedNonCompliant]: 'ag',
  [CareStateEnum.DisqualifiedMedicallyUnstable]: 'ah',
  [CareStateEnum.DisqualifiedAdvancedAge]: 'ai',
  [CareStateEnum.DisqualifiedNotSeenIn12Months]: 'aj',
  [CareStateEnum.InitialUpload]: 'ak',
  [CareStateEnum.DisqualifiedNoInsuranceCoverage]: 'al',
}

const decodeCareState = invert(encodeCareState) as Record<string, CareStateEnum>

const encodeCondition: Record<ConditionEnum, string> = {
  [ConditionEnum.Avr]: 'a',
  [ConditionEnum.Diabetes]: 'b',
  [ConditionEnum.Cabg]: 'c',
  [ConditionEnum.Cap]: 'd',
  [ConditionEnum.Mvr]: 'e',
  [ConditionEnum.Cardiomyopathy]: 'f',
  [ConditionEnum.Cellulitis]: 'g',
  [ConditionEnum.ChronicHeartFailure]: 'h',
  [ConditionEnum.Copd]: 'i',
  [ConditionEnum.CorPulmonale]: 'j',
  [ConditionEnum.Covid]: 'k',
  [ConditionEnum.Dvtpe]: 'l',
  [ConditionEnum.Hypertension]: 'm',
  [ConditionEnum.HypertensiveDisorderOfPregnancy]: 'n',
  [ConditionEnum.Obesity]: 'o',
  [ConditionEnum.PulmonaryHtn]: 'p',
  [ConditionEnum.Pyelonephritis]: 'q',
  [ConditionEnum.Stroke]: 'r',
  [ConditionEnum.Tavr]: 's',
  [ConditionEnum.Uti]: 't',
}

const decodeCondition = invert(encodeCondition) as Record<string, ConditionEnum>

const encodeSortDirection: Record<NonNullable<SortOrder>, string> = {
  ascend: 'a',
  descend: 'b',
}

const decodeSortDirection = invert(encodeSortDirection) as Record<string, NonNullable<SortOrder>>

const encodeNotificationStatus: Record<MutedEnum | NotificationStatusEnum, string> = {
  none: 'a',
  untilNextMeasurement: 'b',
  permanent: 'c',
  noProgram: 'd',
  programNotStarted: 'e',
  noPhone: 'f',
  phoneInvalid: 'g',
}

const decodeNotificationStatus = invert(encodeNotificationStatus) as Record<string, MutedEnum | NotificationStatusEnum>

const parameters = {
  sortColumn: 'a',
  sortDirection: 'b',
  page: 'c',
  pageSize: 'd',
  searchFirstName: 'e',
  searchLastName: 'f',
  sites: 'g',
  tags: 'h',
  careStates: 'i',
  accountStatuses: 'j',
  conditions: 'k',
  programs: 'l',
  concerning: 'm',
  offTrack: 'n',
  neglected: 'o',
  noCommunication: 'p',
  columns: 'q',
  sexes: 'r',
  searchEmail: 's',
  subscribers: 't',
  openTasks: 'u',
  states: 'v',
  notificationStatuses: 'w',
  diagnosisCodes: 'x',
  providers: 'y',
} as const

export type ColumnValueType = (typeof columns)[keyof typeof columns]

const defaultColumns = [
  columns.firstName,
  columns.lastName,
  columns.dateOfBirth,
  columns.sex,
  columns.site,
  columns.accountStatus,
]

function getAllQueryParams(params: URLSearchParams, key: keyof typeof parameters): string[] {
  if (params.has(parameters[key])) return params.getAll(parameters[key])
  return []
}

function getQueryParam(params: URLSearchParams, key: keyof typeof parameters): string | undefined {
  if (params.has(parameters[key])) return params.get(parameters[key]) ?? undefined
}

function isValidColumnValue(column: ColumnValueType): boolean {
  return columnValues.includes(column)
}

// Consistency check - see note above
const duplicateColumnValues = Object.values(columns).filter(
  (item, index) => Object.values(columns).indexOf(item) !== index,
)
if (duplicateColumnValues.length) {
  throw new Error(`Duplicate column value ${duplicateColumnValues[0]} detected. Column values must be unique!`)
}

export class PopulationViewState {
  sortColumn?: ColumnValueType
  sortDirection?: `${NonNullable<SortOrder>}`
  page: number
  pageSize: number
  searchFirstName: string
  searchLastName: string
  searchEmail: string
  sites: string[]
  tags: string[]
  careStates: CareStateEnum[]
  accountStatuses: StatusEnum[]
  conditions: ConditionEnum[]
  programs: string[]
  concerning: boolean | undefined
  offTrack: boolean
  neglected: boolean
  noCommunication: boolean
  columns: ColumnValueType[]
  sexes: SexEnum[]
  subscribers: string[]
  openTasks: string[]
  states: string[]
  notificationStatuses: Array<MutedEnum | NotificationStatusEnum>
  diagnosisCodes: string[]
  providers: string[]

  constructor(queryString: string) {
    const params = new URLSearchParams(queryString)

    const sortDirection = getQueryParam(params, 'sortDirection')

    this.page = Number(getQueryParam(params, 'page') ?? 1)
    this.pageSize = Number(getQueryParam(params, 'pageSize') ?? 10)
    this.searchFirstName = getQueryParam(params, 'searchFirstName') ?? ''
    this.searchLastName = getQueryParam(params, 'searchLastName') ?? ''
    this.tags = getAllQueryParams(params, 'tags')
    this.sites = getAllQueryParams(params, 'sites')
    this.careStates = getAllQueryParams(params, 'careStates')
      .map((key) => decodeCareState[key])
      .filter(isDefined)
    this.accountStatuses = getAllQueryParams(params, 'accountStatuses')
      .map((key) => decodeAccountStatus[key])
      .filter(isDefined)
    this.conditions = getAllQueryParams(params, 'conditions')
      .map((key) => decodeCondition[key] ?? key)
      .filter(isDefined)
    this.programs = getAllQueryParams(params, 'programs')
    this.sortColumn = getQueryParam(params, 'sortColumn') as ColumnValueType | undefined
    if (this.sortColumn && !isValidColumnValue(this.sortColumn)) {
      this.sortColumn = undefined
    }
    this.sortDirection = this.sortColumn && sortDirection ? decodeSortDirection[sortDirection] : undefined
    this.concerning =
      getQueryParam(params, 'concerning') !== undefined ? getQueryParam(params, 'concerning') === 't' : undefined
    this.offTrack = getQueryParam(params, 'offTrack') === 't'
    this.neglected = getQueryParam(params, 'neglected') === 't'
    this.noCommunication = getQueryParam(params, 'noCommunication') === 't'
    this.columns = (getQueryParam(params, 'columns')?.split('.') ?? defaultColumns).filter(
      isValidColumnValue,
    ) as ColumnValueType[]
    this.sexes = getAllQueryParams(params, 'sexes').map((key) => decodeSex[key])
    this.searchEmail = getQueryParam(params, 'searchEmail') ?? ''
    this.subscribers = getAllQueryParams(params, 'subscribers')
    this.openTasks = getAllQueryParams(params, 'openTasks')
    this.states = getAllQueryParams(params, 'states') ?? ''
    this.notificationStatuses = getAllQueryParams(params, 'notificationStatuses').map(
      (key) => decodeNotificationStatus[key],
    )
    this.diagnosisCodes = getAllQueryParams(params, 'diagnosisCodes')
    this.providers = getAllQueryParams(params, 'providers')
  }

  isFiltered(): boolean {
    return !!(
      this.searchFirstName ||
      this.searchLastName ||
      this.searchEmail ||
      this.tags.length ||
      this.sites.length ||
      this.careStates.length ||
      this.accountStatuses.length ||
      this.conditions.length ||
      this.programs.length ||
      this.concerning !== undefined ||
      this.offTrack ||
      this.neglected ||
      this.noCommunication ||
      this.sexes.length ||
      this.subscribers.length ||
      this.openTasks.length ||
      this.states ||
      this.notificationStatuses.length ||
      this.diagnosisCodes.length ||
      this.providers.length
    )
  }

  isSorted(): boolean {
    return !!this.sortColumn
  }

  matches(patient: PatientOverviewItem): boolean {
    // general filters

    // first name search input
    if (this.searchFirstName && !patient.firstName.toLowerCase().includes(this.searchFirstName.toLowerCase()))
      return false

    // last name search input
    if (this.searchLastName && !patient.lastName.toLowerCase().includes(this.searchLastName.toLowerCase())) return false

    // email search input
    if (this.searchEmail && !patient.email?.toLowerCase().includes(this.searchEmail.toLowerCase())) return false

    // tag filter
    if (this.tags.length && !patient.tags.some((tag) => this.tags.includes(tag))) return false

    // site filter
    if (this.sites.length && !this.sites.includes(patient.site)) return false

    // status filter
    if (this.accountStatuses.length && !this.accountStatuses.includes(patient.status)) return false

    // program filter
    if (this.programs.length && !(patient.programName && this.programs.includes(patient.programName))) return false

    // condition filter
    if (this.conditions.length && !patient.conditions.some((key: ConditionEnum) => this.conditions.includes(key)))
      return false

    // out of range filter (has a concerning condition)
    if (
      (this.concerning === true && patient.lastAlerts.length === 0) ||
      (this.concerning === false && patient.lastAlerts.length > 0)
    )
      return false

    // off track filter (alert for predicted insufficient measurements in current billing period)
    if (this.offTrack && !patient.warnings.includes(PatientOverviewItemWarningsEnum.LowPredictedMeasurementCount))
      return false

    // neglected filter (total patient interaction is below 20 minutes in current month)
    if (this.neglected && (!patient.rpm || patient.rpm.totalActivityTime >= 20)) return false

    // no communication filter (no communication with patient in current month)
    if (this.noCommunication && (!patient.rpm || patient.rpm.interactionRatio > 0)) return false

    // onboarding state filter
    if (this.careStates.length && !(patient.careState && this.careStates.includes(patient.careState))) return false

    // sex filter
    if (this.sexes.length && patient.sex && !this.sexes.includes(patient.sex)) return false

    // subscribers filter
    if (this.subscribers.length && !this.subscribers.some((id) => patient.subscribers.includes(id))) {
      return false
    }

    // open tasks filter
    if (this.openTasks.length && !this.openTasks.some((task) => patient.openTasks.includes(task))) {
      return false
    }

    // states filter
    if (this.states.length && (!patient.state || !this.states.includes(patient.state))) {
      return false
    }

    if (this.notificationStatuses.length && !this.notificationStatuses.includes(patient.notificationStatus)) {
      return false
    }

    // diagnosis codes filter
    if (this.diagnosisCodes.length && !patient.diagnosisCodes.some((code) => this.diagnosisCodes.includes(code)))
      return false

    // provider filter
    if (this.providers.length && !(patient.provider && this.providers.includes(patient.provider))) return false

    // all filters are matching
    return true
  }

  toQueryString(excludePaging = false): string {
    const entries: string[][] = [
      this.page !== 1 && !excludePaging ? [parameters.page, this.page.toString()] : undefined,
      this.pageSize !== 10 && !excludePaging ? [parameters.pageSize, this.pageSize.toString()] : undefined,
      this.searchFirstName ? [parameters.searchFirstName, this.searchFirstName] : undefined,
      this.searchLastName ? [parameters.searchLastName, this.searchLastName] : undefined,
      this.sortColumn ? [parameters.sortColumn, this.sortColumn] : undefined,
      this.sortDirection ? [parameters.sortDirection, encodeSortDirection[this.sortDirection]] : undefined,
      ...this.tags.map((tag) => [parameters.tags, tag]),
      ...this.sites.map((site) => [parameters.sites, site]),
      ...this.careStates.map((state: CareStateEnum) => [parameters.careStates, encodeCareState[state]]),
      ...this.accountStatuses.map((status: StatusEnum) => [parameters.accountStatuses, encodeAccountStatus[status]]),
      ...this.conditions.map((condition: ConditionEnum) => [
        parameters.conditions,
        encodeCondition[condition] ?? condition,
      ]),
      ...this.programs.map((program) => [parameters.programs, program]),
      this.concerning !== undefined ? [parameters.concerning, this.concerning ? 't' : 'f'] : undefined,
      this.offTrack ? [parameters.offTrack, 't'] : undefined,
      this.neglected ? [parameters.neglected, 't'] : undefined,
      this.noCommunication ? [parameters.noCommunication, 't'] : undefined,
      [parameters.columns, this.columns.join('.')],
      ...this.sexes.map((sex) => [parameters.sexes, encodeSex[sex]]),
      this.searchEmail ? [parameters.searchEmail, this.searchEmail] : undefined,
      ...this.subscribers.map((subscriber) => [parameters.subscribers, subscriber]),
      ...this.openTasks.map((task) => [parameters.openTasks, task]),
      ...this.states.map((state) => [parameters.states, state]),
      ...this.notificationStatuses.map((status) => [parameters.notificationStatuses, encodeNotificationStatus[status]]),
      ...this.diagnosisCodes.map((code) => [parameters.diagnosisCodes, code]),
      ...this.providers.map((provider) => [parameters.providers, provider]),
    ].filter(isDefined)
    const params = new URLSearchParams(entries)
    return params.toString() ? `?${params.toString()}` : ''
  }
}
