import React from 'react'

import { Roles as RoleManagementComponent } from '../../components/Widgets/RoleManagement'
import { GenericWrapper } from './styles'

export function RoleManagement(): JSX.Element {
  return (
    <GenericWrapper>
      <RoleManagementComponent />
    </GenericWrapper>
  )
}
