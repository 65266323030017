// @TODO move and implement legacy support
export enum LocalStorageKey {
  tokens = 'tokens',
  locale = 'locale',
  appearance = 'appearance',
  summarySettings = 'summary-settings',
  graph = 'graph',
  chart = 'chart',
}

export interface Tokens {
  accessToken: string
  refreshToken: string
}
