import { ConditionEnum, PatientDetails, UpdatePatientBody } from '@vetahealth/tuna-can-api'
import { Form } from 'antd'
import { Mutex } from 'async-mutex'
import dayjs from 'dayjs'
import { produce } from 'immer'
import debounce from 'lodash/debounce'
import invert from 'lodash/invert'
import unset from 'lodash/unset'
import { FieldData } from 'rc-field-form/lib/interface'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { omitEqualValues } from '../../../lib/normalizers'
import { normalizePatientValues, usePatientStore } from '../../../stores/patient'
import { Address, Billing, Contact, EditFormValues, FormKeys, Insurance, Personal } from '../../Forms'
import { getConditionNames } from '../../Forms/helpers'
import { SubTitle } from '../styles'

const createPartialUpdate = (patient: PatientDetails, formValues: EditFormValues): Partial<UpdatePatientBody> => {
  const conditionNameToKey = invert(getConditionNames())

  const updatedValues = {
    ...formValues,
    ...(formValues?.dateOfBirth && { [FormKeys.DATE_OF_BIRTH]: formValues.dateOfBirth.format('YYYY-MM-DD') }),
    conditions: formValues.conditions.map((conditionName) => conditionNameToKey[conditionName] ?? conditionName),
    billingInfo: {
      ...formValues.billingInfo,
      [FormKeys.ORDER_DATE]: formValues.billingInfo?.orderDate?.format('YYYY-MM-DD'),
      [FormKeys.ONBOARDING_DATE]: formValues.billingInfo?.onboardingDate?.format('YYYY-MM-DD'),
      [FormKeys.DELIVERY_DATE]: formValues.billingInfo?.deliveryDate?.format('YYYY-MM-DD'),
    },
    ...(formValues.preferences && {
      preferences: produce(patient.preferences, ({ notifications }) => {
        notifications.channel = formValues.preferences.notifications.channel
      }),
    }),
  }

  return omitEqualValues(normalizePatientValues(patient), normalizePatientValues(updatedValues))
}

const removePathsWithErrors = (update: Partial<UpdatePatientBody>, pathsWithErrors: (string | number)[][]): void => {
  for (const path of pathsWithErrors) {
    unset(update, path)
  }
}

const updateMutex = new Mutex()

export function Demographics(): JSX.Element {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const [patient, updatePatient] = usePatientStore((state) => [state.patient, state.updatePatient])
  const [form] = Form.useForm()
  const conditionNames = getConditionNames()

  useEffect(() => {
    form.resetFields()
  }, [patient?.id])

  const debouncedUpdate = useCallback(
    debounce(async (patientToUpdate, update) => {
      if (!Object.values(update).length) return
      const releaseMutex = await updateMutex.acquire()

      await updatePatient(patientToUpdate, update)

      releaseMutex()
    }, 750),
    [patient],
  )

  const handleChange = (changes: FieldData[]) => {
    const { validating } = changes[0] ?? {}
    if (!patient || !form.isFieldsTouched() || validating) {
      debouncedUpdate(patient, {})
      return
    }
    const errorPaths = form
      .getFieldsError()
      .filter((fieldError) => fieldError.errors.length)
      .map((fieldError) => fieldError.name)

    const update = createPartialUpdate(patient, form.getFieldsValue())
    removePathsWithErrors(update, errorPaths)
    debouncedUpdate(patient, update)
  }

  if (!patient || patient.id !== id) return <div />

  const billingInfo = Object.assign({}, patient.billingInfo, {
    [FormKeys.ORDER_DATE]: patient.billingInfo?.orderDate && dayjs(patient.billingInfo.orderDate),
    [FormKeys.ONBOARDING_DATE]: patient.billingInfo?.onboardingDate && dayjs(patient.billingInfo.onboardingDate),
    [FormKeys.DELIVERY_DATE]: patient.billingInfo?.deliveryDate && dayjs(patient.billingInfo.deliveryDate),
    [FormKeys.PROVIDER]: patient.billingInfo?.provider,
  })

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          ...patient,
          conditions: patient.conditions.map(
            (conditionKey: ConditionEnum) => conditionNames[conditionKey] ?? conditionKey,
          ),
          dateOfBirth: patient.dateOfBirth !== '0000-00-00' ? dayjs(patient.dateOfBirth) : null,
          billingInfo,
          channel: patient.preferences.notifications.channel,
        }}
        validateTrigger="onChange"
        onFieldsChange={handleChange}
        scrollToFirstError
      >
        <SubTitle>{t('widgets.patientEdit.personal')}</SubTitle>
        <Personal form={form} />
        <SubTitle>{t('widgets.patientEdit.address')}</SubTitle>
        <Address />
        <SubTitle>{t('widgets.patientEdit.contact')}</SubTitle>
        <Contact />
        <SubTitle>{t('widgets.patientEdit.billing')}</SubTitle>
        <Billing />
        <SubTitle>{t('widgets.patientEdit.insurance')}</SubTitle>
        <Insurance />
      </Form>
    </>
  )
}
