import dayjs from 'dayjs'
import i18next from 'i18next'
import { DefaultTheme } from 'styled-components'

import { Colors } from '@vetahealth/fishing-gear/colors'
import {
  NotificationChannelEnum,
  NotificationEventTypeEnum,
  PatientActivityCustomerIo,
  PatientActivityCustomerIoActivityEnum,
  PatientActivityCustomerIoChannelEnum,
  PatientActivityNotification,
  PatientActivityTask,
  PatientActivityTaskResponseStatusEnum,
  PatientActivityTracking,
  PatientActivityTypeEnum,
  TaskTypeEnum,
} from '@vetahealth/tuna-can-api'

import { getTrackingTitle } from '../../helpers'
import { PatientActivity } from './types'

export function isTaskItem(item: PatientActivity): item is PatientActivityTask {
  return item.type === PatientActivityTypeEnum.Task
}

export function isTrackingItem(item: PatientActivity): item is PatientActivityTracking {
  return item.type === PatientActivityTypeEnum.Tracking
}

export function isNotificationItem(item: PatientActivity): item is PatientActivityNotification {
  return item.type === PatientActivityTypeEnum.Notification
}

export function isOnboardingItem(item: PatientActivity): item is PatientActivityCustomerIo {
  return item.type === PatientActivityTypeEnum.CustomerIo
}

function getTaskTitle(type: TaskTypeEnum): string {
  const translations: Record<TaskTypeEnum, string> = {
    information: i18next.t('activity.information'),
    survey: i18next.t('activity.survey'),
  }
  return translations[type]
}

function getNotificationType(type: NotificationEventTypeEnum): string {
  const translations: Record<NotificationEventTypeEnum, string> = {
    measurementReminder: i18next.t('activity.measurementReminder'),
    measurementResponse: i18next.t('activity.measurementResponse'),
    taskReminder: i18next.t('activity.taskReminder'),
    general: i18next.t('activity.generalReminder'),
  }
  return translations[type]
}

function getOnboardingActvivity(activity: PatientActivityCustomerIoActivityEnum): string {
  const translations: Record<PatientActivityCustomerIoActivityEnum, string> = {
    clicked: i18next.t('activity.clicked'),
    delivered: i18next.t('activity.delivered'),
    opened: i18next.t('activity.opened'),
    sent: i18next.t('activity.sent'),
  }
  return translations[activity]
}

function getNotificationTitle(type: NotificationChannelEnum): string {
  const translations: Record<NotificationChannelEnum, string> = {
    email: i18next.t('activity.email', { activity: undefined }),
    push: i18next.t('activity.push', { activity: undefined }),
    sms: i18next.t('activity.sms', { activity: undefined }),
    voice: i18next.t('activity.call', { activity: undefined }),
  }
  return translations[type]
}

function getOnboardingTitle(item: PatientActivityCustomerIo): string {
  const translations: Record<PatientActivityCustomerIoChannelEnum, string> = {
    call: i18next.t('activity.call', {
      activity: getOnboardingActvivity(item.activity),
    }),
    email: i18next.t('activity.email', {
      activity: getOnboardingActvivity(item.activity),
    }),
    sms: i18next.t('activity.sms', {
      activity: getOnboardingActvivity(item.activity),
    }),
  }
  return translations[item.channel]
}

export function getCardColor(item: PatientActivity, theme: DefaultTheme): Record<string, Colors> {
  const mapPatientActivityTypeToCardColor = {
    [PatientActivityTypeEnum.Task]: {
      background: theme.taskBackground,
      active: theme.taskActive,
    },
    [PatientActivityTypeEnum.Tracking]: {
      background: theme.trackingBackground,
      active: theme.trackingActive,
    },
    [PatientActivityTypeEnum.Notification]: {
      background: theme.reminderBackground,
      active: theme.reminderActive,
    },
    [PatientActivityTypeEnum.CustomerIo]: {
      background: theme.reminderBackground,
      active: theme.reminderActive,
    },
  }
  return mapPatientActivityTypeToCardColor[item.type]
}

export function getActivityType(item: PatientActivity): string | undefined {
  if (isTaskItem(item)) return i18next.t('activity.content')
  if (isTrackingItem(item)) return i18next.t('activity.measurement')
  if (isNotificationItem(item)) return getNotificationType(item.notificationType)
  if (isOnboardingItem(item)) return i18next.t('activity.onboarding')
}

export function getTitle(item: PatientActivity): string | undefined {
  if (isTaskItem(item)) return getTaskTitle(item.taskType)
  if (isTrackingItem(item)) return getTrackingTitle(item.trackingType)
  if (isNotificationItem(item)) return getNotificationTitle(item.channel)
  if (isOnboardingItem(item)) return getOnboardingTitle(item)
}

export function getTaskStatus(item: PatientActivityTask): string {
  return {
    [PatientActivityTaskResponseStatusEnum.Answered]: i18next.t('tracking.task.completed', {
      time: dayjs(item.timestamp).format('ll'),
    }),
    [PatientActivityTaskResponseStatusEnum.Skipped]: i18next.t('tracking.task.skipped'),
  }[item.responseStatus]
}
