import { faWarning } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Button, Form, Input, Typography } from 'antd'

import { FormKeys } from './types'

const TextArea = styled(Input.TextArea)`
  padding-right: 20px;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`

export function SupportMessage({ loading }: { loading: boolean }): JSX.Element {
  const { t } = useTranslation()
  return (
    <>
      <Form.Item
        style={{ width: '100%' }}
        name={FormKeys.MESSAGE}
        validateFirst
        required
        rules={[{ required: true, message: t('validations.enterMessage') }]}
      >
        <TextArea placeholder={t('placeholders.message')} allowClear autoSize={{ minRows: 5, maxRows: 25 }} />
      </Form.Item>
      <Typography.Paragraph type="danger">
        <FontAwesomeIcon icon={faWarning} /> {t('menus.main.helpModal.supportFormWarning')}
      </Typography.Paragraph>
      <ButtonWrapper>
        <Button loading={loading} disabled={loading} htmlType="submit" type="primary">
          {t('actions.send')}
        </Button>
      </ButtonWrapper>
    </>
  )
}
