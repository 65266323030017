import styled from 'styled-components'

import { Typography } from 'antd'

export const Card = styled.div<{ $colors: Record<string, string> }>`
  background-color: ${({ $colors }) => $colors.background};
  border-radius: 6px;
  padding: 4px 8px 6px 8px;
  display: inline-flex;
  flex-direction: column;
  text-align: left;
`
export const ActivityType = styled.div<{ $colors: Record<string, string> }>`
  font-size: 11px;
  padding-bottom: 2px;
  color: ${({ $colors }) => $colors.active};
`
export const Title = styled(Typography.Text)`
  display: block;
  font-weight: 600;
  font-size: 13px;
  line-height: 1.35;
`
export const TaskStatus = styled.div`
  padding-top: 2px;
  font-size: 11px;
  opacity: 0.6;
`
export const LabelWrap = styled.div`
  margin-top: 3px;
`
export const DayAndYear = styled.div`
  font-size: 12px;
  font-weight: 500;
`
export const Time = styled.div`
  font-size: 12px;
`
export const Value = styled.span`
  font-size: 14px;
`

export const Dot = styled.div<{ $color?: string }>`
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 100px;
  background-color: ${({ $color }) => $color};
  left: 50%;
  margin-left: -5px;
  margin-top: -5px;
`
