import React from 'react'

import { Users as UserManagementComponent } from '../../components/Widgets/UserManagement'
import { GenericWrapper } from './styles'

export function UserManagement(): JSX.Element {
  return (
    <GenericWrapper>
      <UserManagementComponent />
    </GenericWrapper>
  )
}
