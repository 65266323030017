import React, { useEffect } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { Auth } from '../components/Auth'
import { Modals } from '../components/Modals'
import { NotFound } from '../components/NotFound'
import { Websocket, getCurrentPatientId } from '../lib/api'
import { TrackingEvent, trackEvent } from '../lib/tracking'
import { useAuthStore } from '../stores/auth'
import { useUserStore } from '../stores/user'
import { TopBar } from './TopBar'
import { routes } from './routes'
import { Archive } from './routes/Archive'
import { CsvImport } from './routes/CsvImport'
import { FileDownload } from './routes/FileDownload'
import { ManualOrders } from './routes/ManualOrders'
import { PatientDetails } from './routes/PatientDetails'
import { PatientList } from './routes/PatientList'
import { ReportDownload } from './routes/ReportDownload'
import { RoleManagement } from './routes/RoleManagement'
import { Settings } from './routes/Settings'
import { SiteManagement } from './routes/SiteManagement'
import { UserManagement } from './routes/UserManagement'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  background-color: ${({ theme }) => theme.primaryBackground};
`
const RouteWrapper = styled.div`
  overflow: scroll;
  flex: 1;
`

export function AppRoutes(): JSX.Element {
  const location = useLocation()
  const [isAuthorized, isPendingEpicLaunch] = useAuthStore((state) => [state.isAuthorized, state.isPendingEpicLaunch])
  const [userId] = useUserStore((state) => [state.userId])
  const [views] = useUserStore((state) => [state.views])
  const firstViewQueryString = views[0]?.queryString ?? ''

  const query = new URLSearchParams(window.location.search)
  const headless = query.get('headless') === 'true'

  function handleLocationChange(): void {
    const currentPatientId = getCurrentPatientId()
    if (currentPatientId) {
      Websocket.sendPatientEditStart(currentPatientId)
    } else {
      Websocket.sendPatientEditEnd()
    }
  }

  useEffect(() => {
    if (isAuthorized) {
      trackEvent(TrackingEvent.pageView)
      handleLocationChange()
    }
  }, [isAuthorized, location.pathname])

  if (!isAuthorized || !userId || isPendingEpicLaunch) return <Auth />

  return (
    <Wrapper>
      {!headless && <TopBar />}
      {window.location.pathname === routes.root.path && <Navigate to={routes.patientList() + firstViewQueryString} />}
      <RouteWrapper>
        <Routes>
          <Route path={routes.root.path} />
          <Route path={routes.patientList.path} element={<PatientList />} />
          <Route path={routes.patientDetails.path} element={<PatientDetails />} />
          <Route path={routes.settings.path} element={<Settings />} />
          <Route path={routes.csvImport.path} element={<CsvImport />} />
          <Route path={routes.siteManagement.path} element={<SiteManagement />} />
          <Route path={routes.userManagement.path} element={<UserManagement />} />
          <Route path={routes.roleManagement.path} element={<RoleManagement />} />
          <Route path={routes.reportDownload.path} element={<ReportDownload />} />
          <Route path={routes.fileDownload.path} element={<FileDownload />} />
          <Route path={routes.manualOrders.path} element={<ManualOrders />} />
          <Route path={routes.archive.path} element={<Archive />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </RouteWrapper>
      <Modals />
    </Wrapper>
  )
}
