import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { NoteTypeEnum } from '@vetahealth/tuna-can-api'

import { Form, Modal } from 'antd'

import { useLoading } from '../../../lib/hooks/useLoading'
import { useNotesStore } from '../../../stores/notes'
import { usePatientStore } from '../../../stores/patient'
import { useUserStore } from '../../../stores/user'
import { CreateNote, NoteFormValues } from '../../Forms'
import { replaceNameAndCredentials, templates } from './templates'
import { Template } from './types'

const escalationTemplate = templates.find(({ type }) => type === NoteTypeEnum.DataReviewWithEscalation) as Template

export function EscalateNote({
  isVisible,
  onSuccess,
  onCancel,
}: {
  isVisible: boolean
  onSuccess: () => void
  onCancel: () => void
}): JSX.Element {
  const [form] = Form.useForm<NoteFormValues>()
  const { t } = useTranslation()
  const [loading, withLoading] = useLoading()
  const [createNote] = useNotesStore((state) => [state.createNote])
  const [patient] = usePatientStore((state) => [state.patient])
  const [firstName, lastName, credentials] = useUserStore((state) => [
    state.firstName,
    state.lastName,
    state.credentials,
  ])

  async function handleFinish(values: NoteFormValues): Promise<void> {
    if (!patient?.id) return

    const success = await withLoading(createNote({ id: patient.id, values, isEscalation: true }))

    if (success) {
      form.resetFields()
      onSuccess()
    }
  }

  return (
    <Modal
      title={t('widgets.notes.escalation')}
      open={isVisible}
      footer={null}
      style={{ maxWidth: '800px' }}
      width="66%"
      onCancel={onCancel}
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
        initialValues={{
          ...replaceNameAndCredentials(escalationTemplate, { firstName, lastName, credentials }),
          timestamp: dayjs(),
        }}
      >
        <div>
          <CreateNote form={form} loading={loading} />
        </div>
      </Form>
    </Modal>
  )
}
