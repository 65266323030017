import React from 'react'

import { Components, renderDatoAssets } from '@vetahealth/fishing-gear/react/datoAssets'
import { Asset } from '@vetahealth/tuna-can-api'

import { Image } from './Image'
import { Markdown } from './Markdown'
import { VideoPlayer } from './VideoPlayer'
import { YouTubePlayer } from './YouTubePlayer'

function getComponents(): Components {
  const components: Components = {
    Markdown,
    Question: ({ text }) => <Markdown text={text} />,
    Answer: ({ text }) => <Markdown text={text} />,
    Image: ({ src, caption }) => <Image src={src} caption={caption} />,
    Video: ({ src }) => <VideoPlayer uri={src} />,
    Youtube: ({ id }) => <YouTubePlayer id={id} />,
  }

  return components
}

export function DatoAssets({ assets }: { assets: Asset[] }): JSX.Element {
  return <div>{renderDatoAssets(assets, getComponents())}</div>
}
