import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import identity from 'lodash/identity'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { isDefined } from '@vetahealth/fishing-gear/lib/typeguards'
import { CareStateEnum, PatientOverviewItem } from '@vetahealth/tuna-can-api'

import { Button, Dropdown, Select, Table, Tag, Tooltip } from 'antd'
import { ColumnGroupType, ColumnType, SortOrder } from 'antd/lib/table/interface'

import { PermissionName } from '@vetahealth/fishing-gear/permissions'
import { ItemType } from 'antd/lib/menu/interface'
import { routes } from '../../../Router/routes'
import { PatientDetailsTab } from '../../../Router/routes/PatientDetails'
import { ColumnValueType, PopulationViewState } from '../../../lib/PopulationViewState'
import { usePatients } from '../../../lib/hooks/usePatients'
import { TrackingEvent, trackEvent } from '../../../lib/tracking'
import { usePatientsStore } from '../../../stores/patients'
import { useUserStore } from '../../../stores/user'
import { Viewers } from '../../Viewers'
import { Field, getTableData } from '../../helpers'
import { Card } from '../styles'
import { ConfirmDeIdentifyModal } from './ConfirmDeIdentifyModal'
import { ViewBar } from './ViewBar'
import { AlertFilterValue, getFields } from './helpers'

const TableWrapper = styled.div`
  padding-top: 10px;
  .ant-table-row {
    cursor: pointer;
  }
`

const HeadWrap = styled.div`
  display: flex;
  margin-bottom: 8px;
  > *:first-child {
    flex-grow: 1;
  }
`

const Filters = styled.div`
  display: flex;
  align-items: center;
  .ant-tag {
    cursor: default;
    margin: 2px;
    padding: 2px 6px;
    color: ${({ theme }) => theme.badgeText};
    background-color: ${({ theme }) => theme.badgeBackground};
    border: none;
    font-weight: 500;
  }
`

const FilteredBy = styled.div`
  margin-right: 6px;
  font-weight: 500;
  font-size: 13px;
`

const StyledSelect = styled(Select)`
  .ant-select-selection-item {
    display: none !important;
  }
`

const columnFilterMapping: Partial<
  Record<
    keyof PatientOverviewItem,
    {
      name: keyof typeof PopulationViewState.prototype
      multi: boolean
      transform?: (value: string) => string | number | boolean
    }
  >
> = {
  firstName: { name: 'searchFirstName', multi: false },
  lastName: { name: 'searchLastName', multi: false },
  programName: { name: 'programs', multi: true },
  tags: { name: 'tags', multi: true },
  site: { name: 'sites', multi: true },
  careState: { name: 'careStates', multi: true },
  conditions: { name: 'conditions', multi: true },
  diagnosisCodes: { name: 'diagnosisCodes', multi: true },
  provider: { name: 'providers', multi: true },
  status: { name: 'accountStatuses', multi: true },
  sex: { name: 'sexes', multi: true },
  email: { name: 'searchEmail', multi: false },
  lastAlerts: { name: 'concerning', multi: false, transform: (value) => value === AlertFilterValue.hasAlerts },
  subscribers: { name: 'subscribers', multi: true },
  openTasks: { name: 'openTasks', multi: true },
  state: { name: 'states', multi: true },
  notificationStatus: { name: 'notificationStatuses', multi: true },
}

function nextSortOrder(
  currentOrder: SortOrder | undefined,
  sortDirections: SortOrder[] = ['ascend', 'descend', null],
): SortOrder {
  if (currentOrder === undefined) return sortDirections[0]
  return sortDirections[(sortDirections.indexOf(currentOrder) + 1) % sortDirections.length]
}

export function PatientList(): JSX.Element {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const viewState = new PopulationViewState(location.search)

  const [patients, filterData] = usePatients(viewState)
  const [currentlyViewedPatients] = usePatientsStore((state) => [state.currentlyViewedPatients])

  const hasPermission = useUserStore((state) => state.hasPermission)
  const [showColumnSelection, setShowColumnSelection] = useState(false)
  const [showConfirmDeIdentify, setShowConfirmDeIdentify] = useState(false)

  function handlePartialViewStateUpdate(update: Partial<PopulationViewState>): void {
    const updatedViewState = Object.assign(new PopulationViewState(location.search), update)
    const queryString = updatedViewState.toQueryString()
    if (queryString === location.search) return
    navigate({ pathname: location.pathname, search: queryString }, { replace: true })
  }

  function handleColumnFilterChange(
    _: unknown,
    filters: Partial<Record<keyof PatientOverviewItem, string[] | null>>,
  ): void {
    const update = Object.fromEntries(
      Object.entries(filters).map(([key, values]: [keyof PatientOverviewItem, string[] | null]) => {
        if (!columnFilterMapping[key]) return [key, values]
        const transform = columnFilterMapping[key]?.transform ?? identity
        return [
          columnFilterMapping[key]?.name,
          columnFilterMapping[key]?.multi ? values?.map(transform) ?? [] : values?.map(transform)[0],
        ]
      }),
    )
    handlePartialViewStateUpdate(update)
  }

  function handleColumnChange(columns: ColumnValueType[]): void {
    handlePartialViewStateUpdate({ columns })
  }

  const fieldMap = getFields({ filterData, viewState })
  const fields = viewState.columns.map((columnKey) => fieldMap[columnKey]).filter(isDefined)
  const columnOptions = Object.entries(fieldMap).map(([value, { title: label }]) => ({ label, value }))
  const filters = Object.values(fieldMap)
    .map((field) => {
      if (!field.filteredValue || field.filteredValue.length === 0) return undefined
      return {
        key: field.key,
        title: field.title,
        values: field.filteredValue.map((value) => {
          return field.filters?.find(({ value: filterValue }) => filterValue === value)?.text ?? value
        }),
      }
    })
    .filter(isDefined)

  const viewersIndicatorField: Field<PatientOverviewItem> = {
    key: 'id',
    title: '',
    format: (id: string) => (
      <div style={{ width: 16 }}>
        <Viewers patientId={id} viewers={currentlyViewedPatients} type="patient" />
      </div>
    ),
    noSorting: true,
  }

  const { dataSource, columns } = getTableData({
    data: patients,
    fields: [viewersIndicatorField, ...fields],
    onHeaderCell: (column: ColumnType<PatientOverviewItem> | ColumnGroupType<PatientOverviewItem>) => ({
      onClick: () => {
        if (!column.key || 'children' in column) return
        const columnId = Object.entries(fieldMap).find(([, { key }]) => key === column.key)?.[0] as
          | ColumnValueType
          | undefined
        if (!columnId) return
        const sortDirection = nextSortOrder(
          columnId === viewState.sortColumn ? viewState.sortDirection : undefined,
          column.sortDirections,
        )
        handlePartialViewStateUpdate({
          sortColumn: sortDirection ? columnId : undefined,
          sortDirection: sortDirection ?? undefined,
        })
      },
    }),
  })

  const menuItems: ItemType[] = [
    {
      key: 'selectColumns',
      label: t('menus.patientList.selectColumns'),
    },
    hasPermission(PermissionName.deIdentify)
      ? {
          key: 'deIdentify',
          label: t('menus.patientList.deIdentify'),
          disabled: !patients.length,
        }
      : undefined,
  ].filter(isDefined)

  async function handleMenuSelection({ key }: { key: string }): Promise<void> {
    if (key === 'selectColumns') {
      trackEvent(TrackingEvent.patientListSettingsSelectColumnsClicked)
      setShowColumnSelection(true)
    } else if (key === 'deIdentify') {
      setShowConfirmDeIdentify(true)
    }
  }

  function handlePatientSelection({ id, careState }: PatientOverviewItem): void {
    trackEvent(TrackingEvent.patientListPatientClicked)

    const isOnboarding =
      careState !== CareStateEnum.None && careState !== CareStateEnum.Onboarded && !careState.startsWith('monitoring')
    navigate({
      pathname: routes.patientDetails(id, isOnboarding ? PatientDetailsTab.demographics : PatientDetailsTab.results),
      search: location.search,
    })
  }

  return (
    <Card>
      <HeadWrap>
        <ViewBar currentSelectionCount={patients.length} />
        {showColumnSelection ? (
          <StyledSelect
            options={columnOptions}
            value={viewState.columns}
            mode="multiple"
            showSearch={false}
            style={{ width: 280 }}
            bordered={false}
            maxTagCount={0}
            defaultOpen={true}
            onDropdownVisibleChange={setShowColumnSelection}
            onChange={handleColumnChange}
            onClick={() => trackEvent(TrackingEvent.patientListSettingsClicked)}
          />
        ) : (
          <Dropdown
            menu={{ items: menuItems, onClick: handleMenuSelection }}
            trigger={['click']}
            placement="bottomLeft"
          >
            <Button shape="circle" type="text">
              <FontAwesomeIcon style={{ fontSize: 22 }} icon={faEllipsisVertical} />
            </Button>
          </Dropdown>
        )}
      </HeadWrap>
      {filters.length > 0 && (
        <Filters>
          <FilteredBy>{t('widgets.population.filteredBy')}:</FilteredBy>
          {filters.map(({ key, title, values }) => (
            <Tooltip
              overlayStyle={{ whiteSpace: 'pre-line', maxWidth: '500px' }}
              title={values.join('\n')}
              key={key}
              placement="bottom"
            >
              <Tag key={key} closable onClose={() => handleColumnFilterChange(undefined, { [key]: null })}>
                {title}
              </Tag>
            </Tooltip>
          ))}
        </Filters>
      )}
      <TableWrapper>
        {/* Using data.length as a key here is a workaround to enforce re-rendering when data has been loaded.
              Without it, default sorting doesn't work. */}
        <Table
          key={dataSource.length}
          onRow={(record: PatientOverviewItem) => ({ onClick: () => handlePatientSelection(record) })}
          dataSource={dataSource}
          columns={columns}
          onChange={handleColumnFilterChange}
          scroll={{ x: true }}
          pagination={{
            showSizeChanger: true,
            current: viewState.page,
            pageSize: viewState.pageSize,
            onChange: (page, pageSize) => handlePartialViewStateUpdate({ page, pageSize }),
            showQuickJumper: true,
            showTotal: (total, [start, end]) => t('widgets.population.pagination', { start, end, total }),
          }}
        />
      </TableWrapper>
      <ConfirmDeIdentifyModal
        patientIds={patients.map((patient) => patient.id)}
        hasSelection={viewState.isFiltered()}
        isVisible={showConfirmDeIdentify}
        onCancel={() => setShowConfirmDeIdentify(false)}
      />
    </Card>
  )
}
