import dayjs from 'dayjs'
import orderBy from 'lodash/orderBy'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Modal, Timeline, Typography } from 'antd'

import { usePatientStore } from '../../../../stores/patient'
import { useSitesStore } from '../../../../stores/sites'
import { CareTask } from '../../../CareTasks/CareTask'
import { Spinner } from '../../../Spinner'
import { getCareStateNames } from '../../helpers'

type CareStateHistory = {
  id: number
  title: string
  type: 'careState' | 'careTask'
  date: string
  careManager?: string
}

const Wrap = styled.div`
  padding-top: 16px;
`

const LabelWrap = styled.div`
  margin-top: 2px;
`

const ChildWrap = styled.div`
  margin-bottom: 6px;
`

const CareState = styled(Typography.Text)`
  font-weight: 500;
  font-size: 14px;
  margin-top: 2px;
  padding: 2px 8px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.tagNeutral};
`

const CareManager = styled.div`
  font-size: 12px;
  opacity: 0.6;
  margin-top: 2px;
`

const DayAndYear = styled.div`
  font-size: 12px;
  font-weight: 500;
`

const Time = styled.div`
  font-size: 12px;
`

export function HistoryModal({ isOpen, onCancel }: { isOpen: boolean; onCancel: () => void }): JSX.Element {
  const { t } = useTranslation()
  const [careStateHistory, getCareStateHistory] = usePatientStore((state) => [
    state.careStateHistory,
    state.getCareStateHistory,
  ])
  const [users] = useSitesStore((state) => [state.users])

  const timeFormat = t('dateFormats.time')
  const careStateNames = getCareStateNames()

  const careTasks: CareStateHistory[] =
    careStateHistory
      ?.flatMap((item) => item.careTasks)
      .filter((task) => !!task.completedAt)
      .map((task) => ({
        id: task.id,
        title: task.title,
        type: 'careTask',
        date: task.completedAt ?? '',
        careManager: t('careTask.completedBy', {
          careManager: users.find((user) => user.id === task.completedBy)?.name ?? t('chat.inactiveCaremanager'),
        }),
      })) ?? []

  const careStates: CareStateHistory[] =
    careStateHistory?.map((item) => ({
      id: item.id,
      title: careStateNames[item.name],
      type: 'careState',
      date: item.enteredAt,
      careManager: item.enteredBy
        ? t('careState.changedBy', {
            careManager: users.find((user) => user.id === item.enteredBy)?.name ?? t('chat.inactiveCaremanager'),
          })
        : t('careState.initialState'),
    })) ?? []

  const history = orderBy(careStates.concat(careTasks), ['date'], ['desc'])

  useEffect(() => {
    if (isOpen) getCareStateHistory()
  }, [isOpen])

  return (
    <Modal width={1000} title={t('careState.history')} open={isOpen} footer={null} onCancel={onCancel}>
      <Wrap>
        {careStateHistory ? (
          <Timeline
            mode="left"
            items={history.map((item) => {
              return {
                label: (
                  <LabelWrap>
                    <DayAndYear>{dayjs(item.date).format('ll')}</DayAndYear>
                    <Time>{dayjs(item.date).format(timeFormat)}</Time>
                  </LabelWrap>
                ),
                children: (
                  <ChildWrap>
                    {item.type === 'careState' ? (
                      <CareState>{item.title}</CareState>
                    ) : (
                      <CareTask title={item.title} isCompleted />
                    )}
                    <CareManager>{item.careManager}</CareManager>
                  </ChildWrap>
                ),
              }
            })}
          />
        ) : (
          <Spinner />
        )}
      </Wrap>
    </Modal>
  )
}
