import React from 'react'

import { ManualOrdersList } from '../../components/Widgets/ManualOrders'
import { GenericWrapper } from './styles'

export function ManualOrders(): JSX.Element {
  return (
    <GenericWrapper>
      <ManualOrdersList />
    </GenericWrapper>
  )
}
