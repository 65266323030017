import React from 'react'
import { useTheme } from 'styled-components'

import { Tooltip } from 'antd'

import { useSitesStore } from '../stores/sites'
import { PulsatingDot } from './PulsatingDot'
import { PatientIdToViewers, ViewersType, getViewersTitle } from './helpers'

export function Viewers({
  patientId,
  viewers,
  type,
}: {
  patientId?: string
  viewers: PatientIdToViewers
  type: ViewersType
}): JSX.Element | null {
  const [users] = useSitesStore((state) => [state.users])
  const theme = useTheme()

  if (!patientId || !viewers[patientId]?.length) return null

  const title = getViewersTitle(viewers, patientId, users, type)

  return (
    <Tooltip placement="right" title={title}>
      <PulsatingDot color={theme.primary} size={16} />
    </Tooltip>
  )
}
