import { BaseClaims } from '@vetahealth/fishing-gear/express/requireAuthorization'
import {
  AddChatMessage,
  Alert,
  AlertDigestSetting,
  AlertReview,
  AppearanceSetting,
  ArchiveSearchResult,
  AssignDeviceBody,
  AssignProgramBody,
  BillingStat,
  BulkCreatePatientsBody,
  Card,
  CardDetails,
  CareManager,
  CareManagerRole,
  CareState,
  CareTaskName,
  ChatMessage,
  Configuration,
  ConfigurationParameters,
  Consent,
  CreateCareManager,
  CreateCareManagerRole,
  CreateNoteBody,
  CreateOrderBody,
  CreatePatientBody,
  CreateProgramTemplate,
  CreateSite,
  DevApi,
  Device,
  DeviceOrder,
  EmailSupportBody,
  EnableTwoFactorBody,
  EnableTwoFactorResponse,
  EnhanceNoteBody,
  EnhancedNote,
  EpicReconcileBody,
  EpicReconcileResponse,
  ExistingPatient,
  ExistingPatientBody,
  FileInfo,
  ForgotPasswordApi,
  FusionUserNameWithSites,
  ImportEpicPatientBody,
  Info,
  InfoApi,
  ManualOrder,
  ManualOrderUpdate,
  Note,
  PatientDetails,
  PatientOverviewItem,
  PatientTask,
  PopulationView,
  PreviewCareTask,
  Program,
  ProgramTemplate,
  ReleaseInfo,
  RoleAssignment,
  Settings,
  SharedApi,
  Signin242Response,
  SigninBody,
  SimpleTrackingEvent,
  Site,
  Snapshot,
  TunaApi,
  TunaAuthSuccessResponse,
  TwoFactorBody,
  TwoFactorResponse,
  TwoFactorSecretResponse,
  TwoFactorTrustTokenResponse,
  UnitSetting,
  UpdateCareManager,
  UpdateCareManagerRole,
  UpdateCareStateRequest,
  UpdateNoteBody,
  UpdatePatientBody,
  UpdatePatientValidationError,
  UpdatePatientValidationFieldEnum,
  UpdateProgramTemplate,
  UpdateSite,
  UpdateToDo,
  VitalsPDFBody,
} from '@vetahealth/tuna-can-api'

import { message } from 'antd'
import { AxiosError, AxiosResponse } from 'axios'
import dayjs from 'dayjs'
import i18next from 'i18next'
import { jwtDecode } from 'jwt-decode'

import packageJson from '../../../package.json'
import { FormKeys } from '../../components/Forms'
import { PatientActivity } from '../../components/Widgets/PatientDetails/Activity/types'
import {
  DeleteAlertConfigTemplate,
  DeleteToDoTemplate,
  UpdateAlertConfigTemplate,
  UpdateToDoTemplate,
} from '../../stores/sites/types'
import { captureException } from '../error'
import { SupportedLocale } from '../i18n'
import { storage } from '../storage'
import { takeLeading } from '../takeLeading'
import { isAxiosError, isFieldErrorData } from './helpers'
import {
  AddEventRequest,
  AlertConfig,
  ApiError,
  AssignIdConflictError,
  Callback,
  CallbackType,
  Conflict,
  DeleteEventRequest,
  GetBillingRequest,
  GetPatientActivityRequest,
  GetPatientResultDetailsRequest,
  GetPatientResultSummaryRequest,
  SummaryDetail,
  UpdateRequest,
  isSummaryDetail,
} from './types'

const isDev = process.env.NODE_ENV === 'development'
const apiVersion = packageJson.devDependencies['@vetahealth/tuna-can-api'].match(/^\^?(\d+\.\d+\.\d+)/)?.[1]

class ApiClient {
  private callbacks: Record<CallbackType, Callback[]> = {
    [CallbackType.onTokenExpired]: [],
    [CallbackType.onTokenRetrieved]: [],
    [CallbackType.onInfoRetrieved]: [],
  }

  private runCallbacks = <Payload>(callbackType: CallbackType, payload?: Payload): void => {
    this.callbacks[callbackType].forEach((callback) => {
      callback(payload)
    })
  }

  registerCallback = <Payload>(callbackType: CallbackType, callback: Callback<Payload>): void => {
    this.callbacks[callbackType].push(callback)
  }

  removeCallback = (callbackType: CallbackType, callback: Callback): void => {
    this.callbacks[callbackType] = this.callbacks[callbackType].filter(
      (registeredCallback) => registeredCallback !== callback,
    )
  }

  private storeTokensAndNotify = ({ refreshToken, accessToken, info, permissions }: TunaAuthSuccessResponse): void => {
    if (refreshToken && accessToken) {
      storage.updateTokens({ accessToken, refreshToken })
      this.runCallbacks(CallbackType.onTokenRetrieved, accessToken)
      this.runCallbacks(CallbackType.onInfoRetrieved, { info, permissions })
    }
  }

  private getToken = takeLeading(async (): Promise<string | undefined> => {
    const tokens = storage.getTokens()

    if (!tokens) return

    const { exp } = jwtDecode<BaseClaims>(tokens.accessToken)
    const accessTokenExpiration = exp * 1000
    const now30SecOffset = dayjs().add(30, 'seconds').valueOf()
    const isExpired = accessTokenExpiration < now30SecOffset

    if (isExpired) {
      const refreshedTokens = await this.refreshToken()
      return refreshedTokens?.accessToken
    }

    return tokens.accessToken
  })

  private onCallError = (error: AxiosError, ignoredStatusCodes: number[]): void => {
    const status = error?.response?.status
    if (!isAxiosError(error) || !status) {
      captureException(error, 'API')
      message.error({ content: i18next.t('error.generic'), key: 'errorGeneric' })
      return
    }

    if (status === 401 && !ignoredStatusCodes.includes(401)) {
      this.signout()
      return
    }

    if (status === 426) {
      message.error({ content: i18next.t('error.outdatedClient'), key: 'errorOutdatedClient', duration: 30 })
      return
    }

    captureException(error, 'API')
    message.error({ content: i18next.t('error.generic'), key: 'errorGeneric' })
  }

  private call = async <ReturnType>(method: () => Promise<ReturnType | undefined>): Promise<ReturnType | undefined> => {
    try {
      return await method()
    } catch (error) {
      this.onCallError(error, [])
    }
  }

  private callWithIgnoredStatus = async <ReturnType>(
    method: () => Promise<ReturnType | undefined>,
    ignoredStatusCodes: number[],
  ): Promise<ReturnType | AxiosError | undefined> => {
    try {
      return await method()
    } catch (error) {
      if (isAxiosError(error) && ignoredStatusCodes.includes(error.response?.status ?? 0)) {
        return error
      }
      this.onCallError(error, ignoredStatusCodes)
    }
  }

  initialize = async (): Promise<void> => {
    await this.refreshToken()
  }

  reInitialize = async (): Promise<void> => {
    await this.getToken()
  }

  signin = async (
    signinData: SigninBody,
  ): Promise<TunaAuthSuccessResponse | Signin242Response | AxiosError | undefined> => {
    const tunaApi = this.getPublicApi(TunaApi)

    return this.callWithIgnoredStatus(async () => {
      const response = await tunaApi.signin(signinData)
      this.storeTokensAndNotify(response.data)
      return response.data
    }, [401, 404, 423])
  }

  signout = (): void => {
    storage.deleteTokens()
    this.runCallbacks(CallbackType.onTokenExpired)
  }

  refreshToken = takeLeading(async (): Promise<TunaAuthSuccessResponse | undefined> => {
    const tokens = storage.getTokens()

    if (!tokens) return

    const tunaApi = this.getPublicApi(TunaApi)

    return this.call(async () => {
      const response = await tunaApi.refresh({
        refreshToken: tokens.refreshToken,
        accessToken: tokens.accessToken,
      })

      this.storeTokensAndNotify(response.data)
      return response.data
    })
  })

  twoFactor = async ({ code, twoFactorId }: TwoFactorBody): Promise<TwoFactorResponse | AxiosError | undefined> => {
    const tunaApi = this.getPublicApi(TunaApi)

    return this.callWithIgnoredStatus(async () => {
      const response = await tunaApi.twoFactor({
        code,
        twoFactorId,
      })

      this.storeTokensAndNotify(response.data)
      return response.data
    }, [404, 421])
  }

  resetPassword = async ({
    resetCode,
    newPassword,
    trustToken,
    trustChallenge,
  }: {
    resetCode: string
    newPassword: string
    trustToken?: string
    trustChallenge?: string
  }): Promise<{ oneTimePassword?: string } | AxiosError | undefined> => {
    const tunaApi = this.getPublicApi(TunaApi)

    return this.callWithIgnoredStatus(async () => {
      const response = await tunaApi.resetPassword({ resetCodeId: resetCode, newPassword, trustToken, trustChallenge })
      return response.data
    }, [404, 409])
  }

  changePassword = async ({
    password,
    newPassword,
    trustToken,
    trustChallenge,
  }: {
    password: string
    newPassword: string
    trustToken?: string
    trustChallenge?: string
  }): Promise<{ oneTimePassword?: string } | AxiosError | undefined> => {
    const tokens = storage.getTokens()
    if (!tokens) return
    const tunaApi = await this.getProtectedApi(TunaApi)

    return this.callWithIgnoredStatus(async () => {
      const response = await tunaApi?.changeCareManagerPassword({
        password,
        newPassword,
        trustToken,
        trustChallenge,
        refreshToken: tokens.refreshToken,
      })
      return response?.data
    }, [404])
  }

  getTrustToken = async ({
    code,
    email,
  }: {
    code: string
    email: string
  }): Promise<TwoFactorTrustTokenResponse | undefined> => {
    const tunaApi = this.getPublicApi(TunaApi)

    return this.call(async () => {
      const response = await tunaApi?.getTrustToken({ code, email })
      return response?.data
    })
  }

  getTwoFactorSecret = async (): Promise<TwoFactorSecretResponse | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      const response = await tunaApi?.getTwoFactorSecret()
      return response?.data
    })
  }

  enableTwoFactor = async ({
    code,
    secretBase32Encoded,
    twoFactorId,
  }: EnableTwoFactorBody): Promise<EnableTwoFactorResponse | AxiosError | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)

    return this.callWithIgnoredStatus(async () => {
      const response = await tunaApi?.enableTwoFactor({ code, secretBase32Encoded, twoFactorId })
      await this.refreshToken()
      return response?.data
    }, [406])
  }

  disableTwoFactor = async (code: string): Promise<AxiosResponse | AxiosError | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)

    return this.callWithIgnoredStatus(async () => {
      const response = await tunaApi?.disableTwoFactor({ code })
      await this.refreshToken()
      return response
    }, [406])
  }

  // TUNA AUTH API

  forgotPassword = async (userName: string): Promise<void> => {
    const forgotPasswordApi = this.getPublicApi(ForgotPasswordApi)

    return this.call(async () => {
      await forgotPasswordApi?.forgotPassword({ email: userName })
    })
  }

  updateUserSettings = async (userSettings: { locale: SupportedLocale }): Promise<boolean> => {
    const tunaApi = await this.getProtectedApi(TunaApi)

    const success = await this.call(async () => {
      await tunaApi?.updateOwnFusionSettings(userSettings)
      await this.refreshToken()
      return true
    })
    return !!success
  }

  // TUNA API

  private getProtectedApi = async <API>(
    Api: new (_configuration?: Configuration) => API,
    override: ConfigurationParameters = {},
  ): Promise<API | undefined> => {
    const token = await this.getToken()

    if (token) {
      const config = new Configuration({
        accessToken: token,
        baseOptions: {
          headers: {
            'X-Client-Name': 'tuna-app',
            'X-Client-Version': isDev ? 'dev' : packageJson.version,
            'X-Api-Version': apiVersion,
          },
        },
        basePath: window.location.origin,
        ...override,
      })

      return new Api(config)
    }
  }

  private getPublicApi = <API>(
    Api: new (_configuration?: Configuration) => API,
    override: ConfigurationParameters = {},
  ): API => {
    const config = new Configuration({
      baseOptions: {
        headers: {
          'X-Client-Name': 'tuna-app',
          'X-Client-Version': isDev ? 'dev' : packageJson.version,
          'X-Api-Version': apiVersion,
        },
      },
      basePath: window.location.origin,
      ...override,
    })
    return new Api(config)
  }

  getUploadSharedFileUrl = (site: string, filename: string, label?: string): string =>
    `${window.location.origin}/api/v1/sites/${encodeURIComponent(site)}/files/${encodeURIComponent(
      filename,
    )}?label=${encodeURIComponent(label ?? '')}`

  getProtectedHeaders = async () => {
    const token = await this.getToken()
    return {
      Authorization: `bearer ${token}`,
      'X-Client-Name': 'tuna-app',
      'X-Client-Version': isDev ? 'dev' : packageJson.version,
      'X-Api-Version': apiVersion,
    }
  }

  getPatients = async (): Promise<Array<PatientOverviewItem> | undefined> => {
    const patientsApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      const res = await patientsApi?.getPatientOverview()
      return res?.data
    })
  }

  getPatient = async (id: string): Promise<PatientDetails | AxiosError | undefined> => {
    const patientsApi = await this.getProtectedApi(TunaApi)

    return this.callWithIgnoredStatus(async () => {
      const res = await patientsApi?.getPatient(id)
      return res?.data
    }, [404, 403])
  }

  checkPatientExists = async (values: ExistingPatientBody): Promise<ExistingPatient[] | undefined> => {
    const patientsApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      const res = await patientsApi?.checkPatientExists(values)
      return res?.data
    })
  }

  createPatient = async (values: CreatePatientBody): Promise<PatientDetails | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      const res = await tunaApi?.createPatient(values)
      return res?.data
    })
  }

  bulkCreatePatients = async (patients: BulkCreatePatientsBody[]): Promise<boolean | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      await tunaApi?.bulkCreatePatients(patients)
      return true
    })
  }

  bulkDeIdentifyPatients = async (patientIds: string[]): Promise<boolean | undefined> => {
    const patientsApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      await patientsApi?.bulkDeIdentifyPatients(patientIds)
      return true
    })
  }

  updatePatient = async ({
    id,
    values,
  }: UpdateRequest<UpdatePatientBody>): Promise<PatientDetails | Conflict | undefined> => {
    const patientsApi = await this.getProtectedApi(TunaApi)

    const response = await this.callWithIgnoredStatus<
      PatientDetails | AxiosError<{ error: Conflict }> | undefined
    >(async () => {
      const res = await patientsApi?.updatePatient(id, values)
      return res?.data
    }, [400, 409])

    if (!isAxiosError(response)) return response

    // @TODO: Temporary fix to avoid "logout" when BadRequest happens => see call handler
    if (response.response?.status === 400) {
      message.error(i18next.t('error.updateFailed'))
    }

    const data = response.response?.data
    if (response.response?.status === 409 && isFieldErrorData(data) && data.error.field === 'phone_number') {
      return {
        field: FormKeys.MOBILE_PHONE,
        value: data.error.value,
      }
    }
  }

  validateUpdatePatientField = async (
    patientId: string,
    field: UpdatePatientValidationFieldEnum,
    value: string,
  ): Promise<UpdatePatientValidationError | undefined> => {
    const patientApi = await this.getProtectedApi(TunaApi)
    return this.call(async () => {
      const response = await patientApi?.validateDemographicsBeforeUpdate(patientId, { field, value })
      if (response?.status === 204) return
      return response?.data
    })
  }

  subscribePatient = async (patientId: string, subscriberIds: string[], unsubscriberIds: string[]): Promise<void> => {
    const patientApi = await this.getProtectedApi(TunaApi)

    await Promise.all([
      this.call(async () => subscriberIds.length && (await patientApi?.subscribe(patientId, subscriberIds))),
      this.call(async () => unsubscriberIds.length && (await patientApi?.unsubscribe(patientId, unsubscriberIds))),
    ])
  }

  getPatientConsent = async (id: string): Promise<Consent | undefined> => {
    const patientsApi = await this.getProtectedApi(TunaApi)

    return this.call<Consent | undefined>(async () => {
      const res = await patientsApi?.getConsent(id)
      if (res?.status === 204) return
      return res?.data
    })
  }

  setPatientConsent = async ({ id, consent }: { id: string; consent: Consent }): Promise<boolean | undefined> => {
    const patientsApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      await patientsApi?.upsertConsent(id, consent)
      return true
    })
  }

  getPatientConsentPDF = async (id: string): Promise<ArrayBuffer | undefined> => {
    const patientsApi = await this.getProtectedApi(TunaApi)

    return this.call<ArrayBuffer>(async () => {
      const res = await patientsApi?.getConsentPDF(id, { responseType: 'arraybuffer' })
      return res?.data as ArrayBuffer | undefined
    })
  }

  getPatientEscalationPDF = async (id: string, noteId: number, password: string): Promise<ArrayBuffer | undefined> => {
    const patientsApi = await this.getProtectedApi(TunaApi)

    return this.call<ArrayBuffer>(async () => {
      const res = await patientsApi?.getEscalationPDF(id, noteId, { password }, { responseType: 'arraybuffer' })
      return res?.data as ArrayBuffer | undefined
    })
  }

  getVitalsPDF = async (id: string, values: VitalsPDFBody): Promise<ArrayBuffer | undefined> => {
    const patientsApi = await this.getProtectedApi(TunaApi)

    return this.call<ArrayBuffer>(async () => {
      const res = await patientsApi?.getVitalsPDF(id, { ...values }, { responseType: 'arraybuffer' })
      return res?.data as ArrayBuffer | undefined
    })
  }

  getPatientResults = async (id: string): Promise<Card[] | undefined> => {
    const patientsApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      const res = await patientsApi?.getPatientResults(id)
      return res?.data
    })
  }

  getPatientResultDetails = async ({
    id,
    type,
  }: GetPatientResultDetailsRequest): Promise<CardDetails[] | undefined> => {
    const patientsApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      const res = await patientsApi?.getPatientResultDetails(id, type)
      return res?.data
    })
  }

  getPatientResultSummary = async ({
    id,
    summaryInterval,
  }: GetPatientResultSummaryRequest): Promise<SummaryDetail[] | undefined> => {
    const patientsApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      const res = await patientsApi?.getPatientResultSummary(id, summaryInterval)
      return res?.data.filter(isSummaryDetail)
    })
  }

  getPatientTasks = async (id: string): Promise<PatientTask[] | undefined> => {
    const patientsApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      const res = await patientsApi?.getPatientResultTasks(id)
      return res?.data
    })
  }

  getPatientAlertConfigs = async (id: string): Promise<AlertConfig[] | undefined> => {
    const patientsApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      const res = await patientsApi?.getPatientAlertConfiguration(id)
      return res?.data
    })
  }

  updatePatientAlertConfigs = async (id: string, alertConfigs: AlertConfig[]): Promise<boolean | undefined> => {
    const patientsApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      await patientsApi?.updateAlertConfig(id, alertConfigs)
      return true
    })
  }

  getPatientRiskScoreTasks = async ({
    id,
    taskId,
  }: {
    id: string
    taskId: number
  }): Promise<PatientTask[] | undefined> => {
    const patientsApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      const res = await patientsApi?.getPatientRiskScoreTasks(id, taskId.toString())
      return res?.data
    })
  }

  addTrackingEvent = async ({ id, genericValueEvent }: AddEventRequest): Promise<boolean | undefined> => {
    const patientApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      await patientApi?.addEvent(id, genericValueEvent)
      return true
    })
  }

  getPatientAlerts = async (id: string): Promise<Alert[] | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      const res = await tunaApi?.getAlerts(id)
      return res?.data
    })
  }

  reviewAlerts = async (patientId: string, review: AlertReview): Promise<boolean | undefined> => {
    const patientsApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      await patientsApi?.reviewAlerts(patientId, review)
      return true
    })
  }

  deleteTrackingEvent = async ({ id, eventId }: DeleteEventRequest): Promise<boolean | undefined> => {
    const patientApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      await patientApi?.deleteEvent(id, eventId)
      return true
    })
  }

  getManualOrders = async (): Promise<ManualOrder[] | undefined> => {
    const patientsApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      const res = await patientsApi?.getManualOrders()
      return res?.data
    })
  }

  updateManualOrder = async (
    id: string,
    updatedOrder: ManualOrderUpdate,
  ): Promise<ManualOrder | AssignIdConflictError | undefined> => {
    const patientsApi = await this.getProtectedApi(TunaApi)

    const response = await this.callWithIgnoredStatus(async () => {
      const res = await patientsApi?.updateManualOrder(id, updatedOrder)
      return res?.data
    }, [409])

    if (!isAxiosError(response)) return response

    if (response.response?.status === 409) {
      return response.response.data as AssignIdConflictError
    }
  }

  createOrder = async ({ id, values }: UpdateRequest<CreateOrderBody>): Promise<DeviceOrder | undefined> => {
    const patientsApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      const res = await patientsApi?.createOrder(id, values)
      return res?.data
    })
  }

  deleteOrder = async ({ id, orderId }: { id: string; orderId: string }): Promise<boolean | undefined> => {
    const patientsApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      await patientsApi?.deleteOrder(id, orderId)
      return true
    })
  }

  getDevices = async (id: string): Promise<Device[] | undefined> => {
    const patientsApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      const res = await patientsApi?.getDevices(id)
      return res?.data
    })
  }

  assignDevice = async (
    patientId: string,
    assignDeviceBody: AssignDeviceBody,
  ): Promise<boolean | AssignIdConflictError | ApiError | undefined> => {
    const patientsApi = await this.getProtectedApi(TunaApi)

    const response = await this.callWithIgnoredStatus(async () => {
      return await patientsApi?.assignDevice(patientId, assignDeviceBody)
    }, [400, 404, 409])

    if (!isAxiosError(response)) return true

    if (response.response?.status === 400) {
      return response.response.data as ApiError
    }
    if (response.response?.status === 404) {
      return response.response.data as ApiError
    }
    if (response.response?.status === 409) {
      return response.response.data as AssignIdConflictError
    }
  }

  assignProgram = async (patientId: string, assignProgramBody: AssignProgramBody): Promise<Program | undefined> => {
    const patientApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      const res = await patientApi?.assignProgram(patientId, assignProgramBody)
      return res?.data
    })
  }

  deleteProgram = async (patientId: string, programId: number): Promise<boolean | undefined> => {
    const patientApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      await patientApi?.deleteProgram(patientId, programId)

      return true
    })
  }

  updateCareState = async (
    patientId: string,
    careStateBody: UpdateCareStateRequest,
  ): Promise<CareState | undefined> => {
    const patientApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      const res = await patientApi?.updateCareState(patientId, careStateBody)
      return res?.data
    })
  }

  getCareStateHistory = async (patientId: string): Promise<CareState[] | undefined> => {
    const patientApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      const res = await patientApi?.getCareStateHistory(patientId)
      return res?.data
    })
  }

  completeCareTask = async (patientId: string, careTaskId: number): Promise<boolean | undefined> => {
    const patientApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      await patientApi?.completeCareTask(patientId, careTaskId)
      return true
    })
  }

  removeDevice = async (patientId: string, deviceId: string): Promise<boolean | undefined> => {
    const patientsApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      await patientsApi?.removeDevice(patientId, deviceId)
      return true
    })
  }

  getNotes = async (id: string): Promise<Note[] | undefined> => {
    const patientsApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      const res = await patientsApi?.getNotes(id)
      return res?.data
    })
  }

  createNote = async ({
    id,
    values,
    escalation = false,
  }: UpdateRequest<CreateNoteBody> & { escalation?: boolean }): Promise<Note | undefined> => {
    const patientsApi = await this.getProtectedApi(TunaApi)
    return this.call(async () => {
      const res = await patientsApi?.createNote(id, values, escalation)
      return res?.data
    })
  }

  updateNote = async ({
    id,
    noteId,
    values,
  }: UpdateRequest<UpdateNoteBody> & { noteId: number }): Promise<Note | undefined> => {
    const patientsApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      const res = await patientsApi?.updateNote(id, noteId, values)
      return res?.data
    })
  }

  deleteNote = async ({ id, noteId }: { id: string; noteId: number }): Promise<boolean | undefined> => {
    const patientsApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      await patientsApi?.deleteNote(id, noteId)
      return true
    })
  }

  enhanceNote = async ({ id, values }: UpdateRequest<EnhanceNoteBody>): Promise<EnhancedNote | undefined> => {
    const patientsApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      const res = await patientsApi?.enhanceNote(id, values)
      return res?.data
    })
  }

  getTrackingEventsForEnhancing = async ({
    id,
    since,
  }: { id: string; since: string }): Promise<SimpleTrackingEvent[] | undefined> => {
    const patientsApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      const res = await patientsApi?.getTrackingEventsForEnhancing(id, since)
      return res?.data
    })
  }

  getSites = async (): Promise<Site[] | undefined> => {
    const sitesApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      const res = await sitesApi?.getSites()
      return res?.data
    })
  }

  createSite = async (createSite: CreateSite): Promise<Site | AxiosError | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)

    return this.callWithIgnoredStatus(async () => {
      const res = await tunaApi?.createSite(createSite)
      await this.refreshToken()
      return res?.data
    }, [409])
  }

  updateSite = async ({ id, values }: UpdateRequest<UpdateSite>): Promise<Site | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      const res = await tunaApi?.updateSite(id, values)
      return res?.data
    })
  }

  getSiteKeys = async (): Promise<string[] | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      const res = await tunaApi?.getSiteKeys()
      return res?.data
    })
  }

  getCareTaskNames = async (): Promise<CareTaskName[] | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      const res = await tunaApi?.getCareTaskNames()
      return res?.data
    })
  }

  getUsers = async (): Promise<CareManager[] | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      const res = await tunaApi?.getCareManagers()
      return res?.data
    })
  }

  createUser = async (user: CreateCareManager): Promise<CareManager | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      const res = await tunaApi?.createCareManager(user)
      return res?.data
    })
  }

  updateUser = async (id: string, user: UpdateCareManager): Promise<CareManager | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      const res = await tunaApi?.updateCareManager(id, user)
      return res?.data
    })
  }

  updateUserRoles = async (id: string, roles: RoleAssignment[]): Promise<boolean | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      await tunaApi?.assignRolesToCareManager(id, roles)
      return true
    })
  }

  freezeSite = async (siteKey: string, name?: string, overwriteId?: string): Promise<Snapshot | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      const res = overwriteId
        ? await tunaApi?.updateSnapshot(siteKey, overwriteId, { name })
        : await tunaApi?.freezeSite(siteKey, { name })
      return res?.data
    })
  }

  unfreezeSite = async (siteKey: string, snapshotId: string): Promise<boolean | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      await tunaApi?.unfreezeSite(siteKey, snapshotId)
      return true
    })
  }

  deleteSnapshot = async (siteKey: string, snapshotId: string): Promise<boolean | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      await tunaApi?.deleteSnapshot(siteKey, snapshotId)
      return true
    })
  }

  getSharedFilesList = async (siteKey: string): Promise<FileInfo[] | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)
    return this.call(async () => {
      const res = await tunaApi?.listFiles(siteKey)
      return res?.data
    })
  }

  downloadSharedFile = async (siteKey: string, id: number): Promise<ArrayBuffer | 'forbidden' | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)
    const result = await this.callWithIgnoredStatus(async () => {
      const res = await tunaApi?.downloadFile(siteKey, id, { responseType: 'arraybuffer' })
      return res?.data as unknown as ArrayBuffer
    }, [403])
    if (isAxiosError(result)) return result.response?.status === 403 ? 'forbidden' : undefined
    return result
  }

  updateSharedFile = async (siteKey: string, id: number, label: string): Promise<FileInfo | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)
    return this.call(async () => {
      const res = await tunaApi?.updateFile(siteKey, id, { label })
      return res?.data
    })
  }

  deleteSharedFile = async (siteKey: string, id: number): Promise<boolean | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)
    return this.call(async () => {
      await tunaApi?.deleteFile(siteKey, id)
      return true
    })
  }

  createProgramTemplate = async (
    siteKey: string,
    programTemplate: CreateProgramTemplate,
  ): Promise<ProgramTemplate | AxiosError | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)

    return this.callWithIgnoredStatus(async () => {
      const res = await tunaApi?.createProgramTemplate(siteKey, programTemplate)
      return res?.data
    }, [409])
  }

  updateProgramTemplate = async (
    siteKey: string,
    programTemplateId: number,
    update: UpdateProgramTemplate,
  ): Promise<ProgramTemplate | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      const res = await tunaApi?.updateProgramTemplate(siteKey, programTemplateId, update)
      return res?.data
    })
  }

  deleteProgramTemplate = async (siteKey: string, programTemplateId: number): Promise<boolean | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)

    const response = await this.callWithIgnoredStatus(async () => {
      const res = await tunaApi?.deleteProgramTemplate(siteKey, programTemplateId)
      return res?.data
    }, [409])

    if (!response || !isAxiosError(response)) return true

    if (response?.response?.status === 409) {
      message.error({ content: i18next.t('error.programTemplateAssigned'), key: 'programTemplateAssigned' })
      return false
    }
  }

  updateAlertConfigTemplate = async ({
    siteKey,
    programTemplateId,
    updateIndex,
    update,
    applyToEnrolled,
  }: UpdateAlertConfigTemplate): Promise<ProgramTemplate | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      const res = await tunaApi?.updateProgramTemplateAlertConfig(
        siteKey,
        programTemplateId,
        updateIndex,
        update,
        applyToEnrolled,
      )
      return res?.data
    })
  }

  deleteAlertConfigTemplate = async ({
    siteKey,
    programTemplateId,
    deleteIndex,
    applyToEnrolled,
  }: DeleteAlertConfigTemplate): Promise<ProgramTemplate | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      const res = await tunaApi?.deleteProgramTemplateAlertConfig(
        siteKey,
        programTemplateId,
        deleteIndex,
        applyToEnrolled,
      )
      return res?.data
    })
  }

  updateToDoTemplate = async ({
    siteKey,
    programTemplateId,
    updateIndex,
    update,
  }: UpdateToDoTemplate): Promise<ProgramTemplate | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)
    return this.call(async () => {
      const res = await tunaApi?.updateProgramTemplateToDo(siteKey, programTemplateId, updateIndex, update)
      return res?.data
    })
  }

  deleteToDoTemplate = async ({
    siteKey,
    programTemplateId,
    deleteIndex,
  }: DeleteToDoTemplate): Promise<ProgramTemplate | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      const res = await tunaApi?.deleteProgramTemplateToDo(siteKey, programTemplateId, deleteIndex)
      return res?.data
    })
  }

  updateToDo = async ({
    patientId,
    taskId,
    toDo,
  }: { patientId: string; taskId: number; toDo: UpdateToDo }): Promise<boolean | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      await tunaApi?.updatePatientTask(patientId, taskId, toDo)
      return true
    })
  }

  getUserNamesWithSites = async (): Promise<FusionUserNameWithSites[] | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      const res = await tunaApi?.getUserNamesWithSites()
      return res?.data
    })
  }

  getBillingStats = async (): Promise<BillingStat[] | undefined> => {
    const sitesApi = await this.getProtectedApi(TunaApi)
    return this.call(async () => {
      const res = await sitesApi?.getBillingStats()
      return res?.data
    })
  }

  getBilling = async ({
    site,
    month,
    year,
    publish,
    outputFormat,
  }: GetBillingRequest): Promise<ArrayBuffer | ApiError | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)

    const response = await this.callWithIgnoredStatus<ArrayBuffer>(async () => {
      const res = await tunaApi?.getBilling(site, month, year, publish, outputFormat, {
        responseType: 'arraybuffer',
      })
      return res?.data as ArrayBuffer | undefined
    }, [404, 412])

    if (!isAxiosError(response)) return response

    if (response.response?.data instanceof ArrayBuffer) {
      return JSON.parse(String.fromCharCode.apply(null, new Uint8Array(response.response.data)))
    }
  }

  devResetDemoData = async (): Promise<boolean | undefined> => {
    const devApi = await this.getProtectedApi(DevApi)

    return this.call(async () => {
      await devApi?.resetDemoData()
      return true
    })
  }

  devResetUserSettings = async (): Promise<boolean | undefined> => {
    const devApi = await this.getProtectedApi(DevApi)

    return this.call(async () => {
      await devApi?.resetCareManagerSettings()
      return true
    })
  }

  devRefreshMetaData = async (params: URLSearchParams): Promise<boolean | undefined> => {
    const devApi = await this.getProtectedApi(DevApi)

    return this.call(async () => {
      await devApi?.refreshMetaData({ params })
      return true
    })
  }

  getInfo = async (): Promise<Info | undefined> => {
    const infoApi = this.getPublicApi(InfoApi)

    return this.call(async () => {
      const res = await infoApi.getInfo()
      return res?.data
    })
  }

  getReleaseInfo = async (locale: SupportedLocale, offset: number): Promise<ReleaseInfo[] | AxiosError | undefined> => {
    const infoApi = await this.getProtectedApi(InfoApi)
    const RELEASE_INFO_FETCH_LIMIT = 1
    const platform = 'tuna'

    return await this.callWithIgnoredStatus(async () => {
      const response = await infoApi?.getReleaseInfo(locale, RELEASE_INFO_FETCH_LIMIT, offset, platform)
      return response?.data
    }, [400])
  }

  getTags = async (): Promise<string[] | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      const res = await tunaApi?.getTags()
      return res?.data
    })
  }

  submitSupportMessage = async (supportRequest: EmailSupportBody): Promise<boolean | undefined> => {
    const sharedApi = await this.getProtectedApi(SharedApi)
    return this.call(async () => {
      await sharedApi?.sendEmailToSupport(supportRequest)
      return true
    })
  }

  requestEpicReconcile = async (
    epicData: EpicReconcileBody,
  ): Promise<EpicReconcileResponse | { error: 'unknownAccount' | 'reconcileFailed' } | undefined> => {
    const tunaApi = this.getPublicApi(TunaApi)
    const reconcileResult = await this.callWithIgnoredStatus(async () => {
      const response = await tunaApi.requestEpicReconcile(epicData)

      this.storeTokensAndNotify(response.data)
      return response.data
    }, [401, 404])
    if (isAxiosError(reconcileResult)) {
      if (reconcileResult.response?.status === 404) return { error: 'unknownAccount' }
      if (reconcileResult.response?.status === 401) return { error: 'reconcileFailed' }
    } else {
      return reconcileResult
    }
  }

  getPatientActivity = async ({
    id,
    limit,
    offset,
  }: GetPatientActivityRequest): Promise<PatientActivity[] | undefined> => {
    const patientsApi = await this.getProtectedApi(TunaApi)

    return this.call(async () => {
      const res = await patientsApi?.getPatientActivity(id, limit, offset)
      return res?.data
    })
  }

  getSettings = async (): Promise<Settings | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)

    return await this.call(async () => {
      const response = await tunaApi?.getAllSettings()
      return response?.data
    })
  }

  updateUnitSettings = async (units: UnitSetting): Promise<boolean | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)

    return await this.call(async () => {
      await tunaApi?.updateSetting('units', units)
      return true
    })
  }

  updatePopulationViewSettings = async (views: PopulationView[]): Promise<boolean | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)

    return await this.call(async () => {
      await tunaApi?.updateSetting('populationViews', views)
      return true
    })
  }

  updateAlertDigestSettings = async (digest: AlertDigestSetting): Promise<boolean | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)

    return await this.call(async () => {
      await tunaApi?.updateSetting('alertDigest', digest)
      return true
    })
  }

  updateViewedReleaseInfo = async (id: string): Promise<boolean | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)

    return await this.call(async () => {
      await tunaApi?.updateSetting('viewedReleaseInfo', [id])
      return true
    })
  }

  updateAppearance = async (appearance: AppearanceSetting): Promise<boolean | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)

    return await this.call(async () => {
      await tunaApi?.updateSetting('appearance', appearance)
      return true
    })
  }

  epicImport = async (importBody: ImportEpicPatientBody): Promise<string | undefined> => {
    const patientsApi = await this.getProtectedApi(TunaApi)
    return await this.call(async () => {
      const res = await patientsApi?.importEpicPatient(importBody)
      return res?.data.patientId
    })
  }

  linkExternal = async (patientId: string, externalId: string): Promise<boolean | undefined> => {
    const patientsApi = await this.getProtectedApi(TunaApi)
    return await this.call(async () => {
      await patientsApi?.linkPatient(patientId, externalId)
      return true
    })
  }

  getChatMessages = async (patientId: string, limit: number, offset: number): Promise<ChatMessage[] | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)
    return await this.call(async () => {
      const res = await tunaApi?.getChatMessages(patientId, limit, offset)
      return res?.data
    })
  }

  addChatMessage = async (patientId: string, message: AddChatMessage): Promise<ChatMessage | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)
    return await this.call(async () => {
      const res = await tunaApi?.addChatMessage(patientId, message)
      return res?.data
    })
  }

  markChatMessagesAsRead = async (patientId: string, messageIds: number[]): Promise<ChatMessage[] | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)
    return await this.call(async () => {
      const res = await tunaApi?.markChatMessagesAsRead(patientId, messageIds)
      return res?.data
    })
  }

  getUnreadChatMessages = async (): Promise<ChatMessage[] | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)
    return await this.call(async () => {
      const res = await tunaApi?.getUnreadChatMessages()
      return res?.data
    })
  }

  quickSearchArchive = async (query: string): Promise<ArchiveSearchResult[] | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)
    return await this.call(async () => {
      const res = await tunaApi?.quickSearchArchive(query)
      return res?.data
    })
  }

  searchPreviewRefs = async (query: string): Promise<string[] | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)
    return await this.call(async () => {
      const res = await tunaApi?.searchCareTaskRefs(query)
      return res?.data
    })
  }

  getPreview = async ({
    ref,
    locale,
  }: {
    ref: string
    locale: `${SupportedLocale}`
  }): Promise<PreviewCareTask | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)
    return await this.call(async () => {
      const res = await tunaApi?.getCareTaskPreviewByRef(ref, locale)
      return res?.data
    })
  }

  getRoles = async (): Promise<CareManagerRole[] | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)
    return await this.call(async () => {
      const res = await tunaApi?.listRoles()
      return res?.data
    })
  }

  createRole = async (role: CreateCareManagerRole): Promise<CareManagerRole | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)
    return await this.call(async () => {
      const res = await tunaApi?.createRole(role)
      return res?.data
    })
  }

  updateRole = async (id: string, role: UpdateCareManagerRole): Promise<CareManagerRole | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)
    return await this.call(async () => {
      const res = await tunaApi?.updateRole(id, role)
      return res?.data
    })
  }

  deleteRole = async (id: string): Promise<boolean | undefined> => {
    const tunaApi = await this.getProtectedApi(TunaApi)
    return await this.call(async () => {
      await tunaApi?.deleteRole(id)
      return true
    })
  }
}

export const API = new ApiClient()
