import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Button, Input, Modal, Typography } from 'antd'

import { useLoading } from '../../../lib/hooks/useLoading'
import { useAuthStore } from '../../../stores/auth'

const EnterCodeWrapper = styled.div`
  padding-bottom: 20px;
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export function DisableTwoFactor({ isVisible, onCancel }: { isVisible: boolean; onCancel: () => void }): JSX.Element {
  const { t } = useTranslation()
  const [isLoading, withLoading] = useLoading()
  const [code, setCode] = useState<string>('')
  const [handleDisableTwoFactor] = useAuthStore((state) => [state.handleDisableTwoFactor])

  useEffect(() => {
    if (!isVisible) setCode('')
  }, [isVisible])

  async function handleDisable(): Promise<void> {
    const success = await withLoading(handleDisableTwoFactor(code))
    if (success) onCancel()
  }

  return (
    <Modal
      title={t('widgets.account.twoFactor.disable.title')}
      open={isVisible}
      footer={null}
      onCancel={onCancel}
      maskClosable={false}
      destroyOnClose
    >
      <EnterCodeWrapper>
        <Typography.Paragraph>{t('widgets.account.twoFactor.disable.enterCode')}</Typography.Paragraph>
        <Input
          size="large"
          value={code}
          onChange={(event) => setCode(event.target.value)}
          style={{ textAlign: 'center', fontWeight: '500' }}
          maxLength={6}
          placeholder={t('placeholders.twoFactor')}
        />
      </EnterCodeWrapper>
      <ButtonWrapper>
        <Button
          disabled={code.length !== 6 || isLoading}
          loading={isLoading}
          type="primary"
          onClick={handleDisable}
          size="large"
        >
          {t('actions.disable')}
        </Button>
      </ButtonWrapper>
    </Modal>
  )
}
