import dayjs from 'dayjs'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { getDisplayValue } from '@vetahealth/fishing-gear/conversions'
import { Alert as IAlert } from '@vetahealth/tuna-can-api'

import { Button, Checkbox, Modal, Typography } from 'antd'

import { useLoading } from '../../../../../lib/hooks/useLoading'
import { TrackingEvent, trackEvent } from '../../../../../lib/tracking'
import { usePatientStore } from '../../../../../stores/patient'
import {
  ResultsType,
  ResultsTypeWithSummary,
  isRiskScoreAlert,
  isTaskAlert,
  isTrackingAlert,
  useResultsStore,
} from '../../../../../stores/results'
import { Alert } from '../../../../Alerts'
import { getResultsConfig } from '../config'
import { ForwardActionProps } from './types'

const Section = styled.div`
  margin-top: 24px;
`

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
`

const List = styled.ul`
  padding: 0;
  margin-bottom: 20px;
  border-top: 1px solid ${({ theme }) => theme.tertiaryBackground};
`

const ListItem = styled.li`
  display: grid;
  grid-template-columns: 20px 30% 20% 1fr;
  padding: 8px 0;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.tertiaryBackground};
  grid-gap: 20px;
`

const Timestamp = styled.div`
  font-size: 13px;
`

const Value = styled.div`
  color: ${({ theme }) => theme.concerningIcon};
`

const ButtonWrapp = styled.div`
  display: flex;
  justify-content: flex-end;
`

type ReviewBulkProps = ForwardActionProps<ResultsTypeWithSummary, { onSubmit?: () => void }>

export type ReviewBulkActions = { open: () => void; close: () => void }

export const ReviewBulk = forwardRef<ReviewBulkActions, ReviewBulkProps>(function ReviewBulk(
  { hasButton = true, type, onSubmit },
  ref,
): JSX.Element {
  const { t } = useTranslation()
  const [isVisible, setVisibility] = useState(false)
  const [checkedAlertIds, setCheckedAlertIds] = useState<number[]>([])
  const [isLoading, withLoading] = useLoading()
  const [patient] = usePatientStore((state) => [state.patient])
  const [alerts, reviewAlerts, allResults, getAllResults] = useResultsStore((state) => [
    state.alerts,
    state.reviewAlerts,
    state.allResults,
    state.getAllResults,
  ])

  const alertsToReview =
    type === 'summary' ? alerts.unreviewed : alerts.unreviewed.filter((alert) => alert.type === type)

  useEffect(() => {
    if (patient && type === 'summary') {
      if (alerts.unreviewed.some((alert) => isTaskAlert(alert) || isRiskScoreAlert(alert)) && !allResults.task) {
        getAllResults(patient.id, 'task')
      }
    }
  }, [type, allResults, patient])

  async function handleSubmit(): Promise<void> {
    if (patient) {
      trackEvent(TrackingEvent.resultReviewAlertsClicked)
      withLoading(reviewAlerts(patient.id, { ids: checkedAlertIds, reviewType: 'self' }))
        .then(() => onSubmit?.())
        .finally(handleReset)
    }
  }

  const handleChange = (alertId: number): void => {
    setCheckedAlertIds((alertIds) => {
      if (alertIds.includes(alertId)) {
        return alertIds.filter((id) => id !== alertId)
      }

      return [...alertIds, alertId]
    })
  }

  const handleTypeChange = (ids: number[]): void => {
    setCheckedAlertIds((alertIds) => {
      if (ids.every((id) => alertIds.includes(id))) {
        return alertIds.filter((alertId) => !ids.includes(alertId))
      }

      return [...alertIds.filter((alertId) => !ids.includes(alertId)), ...ids]
    })
  }

  const handleAllChange = (): void => {
    setCheckedAlertIds((alertIds) => {
      if (alertIds.length === alertsToReview.length) return []
      return alertsToReview.map((alert) => alert.id)
    })
  }

  const handleReset = (): void => {
    setCheckedAlertIds([])
    setVisibility(false)
  }

  useImperativeHandle(ref, () => ({
    open: () => setVisibility(true),
    close: handleReset,
  }))

  const groupedAlerts = groupBy(alertsToReview, 'type')

  function getTaskLabel(alert: IAlert): string | undefined {
    if (isTaskAlert(alert)) {
      const question = allResults.task
        ?.find(({ responseId }) => responseId === alert.taskResponseId)
        ?.details.find((detail) => detail.answers.find((answer) => answer.id === alert.meta.questionId))
        ?.answers.find((answer) => answer.id === alert.meta.questionId)

      if (question) return `${question.label} (${question.answer})`
    }
  }

  return (
    <>
      {hasButton && !!alertsToReview.length && (
        <Button danger onClick={() => setVisibility(true)}>
          {t('actions.reviewAlerts', { count: alertsToReview.length })}
        </Button>
      )}
      <Modal
        title={t('widgets.results.reviewAlerts')}
        open={isVisible}
        footer={null}
        style={{ maxWidth: '800px' }}
        width="66%"
        onCancel={handleReset}
        destroyOnClose
      >
        {type === 'summary' && Object.keys(groupedAlerts).length > 1 && (
          <Checkbox onChange={handleAllChange} checked={checkedAlertIds.length === alertsToReview.length}>
            {t('actions.checkAll')}
          </Checkbox>
        )}
        {Object.entries(groupedAlerts).map(([alertType, alerts]: [alertType: ResultsType, alerts: IAlert[]]) => {
          const { title, unit } = getResultsConfig(alertType)

          return (
            <Section key={alertType}>
              <div style={{ display: 'flex', gap: 10 }}>
                <Checkbox
                  onChange={() => handleTypeChange(alerts.map(({ id }) => id))}
                  checked={alerts.every((alert) => checkedAlertIds.includes(alert.id))}
                />
                <Title>{title}</Title>
              </div>
              <List>
                {orderBy(alerts, ['timestamp'], ['desc']).map((alert) => {
                  return (
                    <ListItem key={alert.id}>
                      <Checkbox onChange={() => handleChange(alert.id)} checked={checkedAlertIds.includes(alert.id)} />
                      <Timestamp>{dayjs(alert.timestamp).format('lll')}</Timestamp>

                      {isRiskScoreAlert(alert) && (
                        <div style={{ display: 'flex', gap: '10px', alignItems: 'flex-end' }}>
                          <Typography.Text style={{ fontSize: 12 }}>
                            {allResults.task?.find(({ responseId }) => responseId === alert.taskResponseId)?.title}
                          </Typography.Text>
                          <Value>
                            {allResults.task?.find(({ responseId }) => responseId === alert.taskResponseId)?.riskScore}
                          </Value>
                        </div>
                      )}

                      {isTaskAlert(alert) && (
                        <>
                          <Typography.Text style={{ fontSize: 12 }}>
                            {allResults.task?.find(({ responseId }) => responseId === alert.taskResponseId)?.title}
                          </Typography.Text>
                          <Typography.Text style={{ fontSize: 12 }}>{getTaskLabel(alert)}</Typography.Text>
                        </>
                      )}

                      {isTrackingAlert(alert) && (
                        <Value>
                          {getDisplayValue(alert.meta.values, alert.meta.unit, alert.type, {
                            targetUnit: unit,
                          })}
                        </Value>
                      )}

                      {!!alert.alertConfig && <Alert alertConfig={alert.alertConfig} />}
                    </ListItem>
                  )
                })}
              </List>
            </Section>
          )
        })}
        <ButtonWrapp>
          <Button type="primary" disabled={!checkedAlertIds.length} onClick={handleSubmit} loading={isLoading}>
            {t('actions.markAsReviewed')}
          </Button>
        </ButtonWrapp>
      </Modal>
    </>
  )
})
